import store from '@/store'
export const can = {
  inserted(el, binding, vnode) {
    const action = binding.value
    if(action){
      const userPermissions = store.state.auth.permissions  
      const hasPermission = userPermissions.includes(action)
      if (!hasPermission) {       
        el.parentNode.removeChild(el)        
      }
    }
  },    
}