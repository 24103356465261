export function questionsByCategory( state ) {
    // const questions = [...state.questionsAnswers ]
    if( state.categorySelected ){

        const { id } = state.categorySelected
        if( state.query != '' ){
            return state.questionsAnswers.filter( q => q.question.toLowerCase().includes( state.query.toLowerCase() ) )             
        }            
       
        return state.questionsAnswers.filter( q => q.idCategory === id )            
    } 
}