export default {
    orders: [],
    ordersItems: [],
    usersChannel: [],
    activeUsers: [],
    inactiveUsers: [],
    orderDetail: null, 
    bookingSelected: null,   
    searchForm: {
        adults: 0,
        dingusCode:'',
        hotel:'',
        nights: 0,
        ninos: [],
        numberChildren: 0,
        promoCodeManual: '',
        rangeDate:'',
        roomCode: '',
        idRsv: '',                
    },
    selectedOrder: {
        files: ''
    },
    aBookingIsUpdating: false, 
    bookingToEdit: null,
    userAuthUpdateBooking: {
        isInvalid: true,
        idUser: null,
        username: '',
        code: '',
        urlAuthorization: ''
    },
    detailOrder: null,
    dataTransaction: null,
    dataPaymentLink: '',

    dataTransaction2: null,
    transactionPay: null,
    filterO: {
        query: '',
    },
    isloadingProcess: false,    
}