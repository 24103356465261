
export default [
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/*webpackChunkName: "FAQView"*/'@/modules/faq/views/Faq'),        
        meta: {
            requiresAuth: true,
            // permissions: ['faq_menu_show_faq']
        },
    }    
]