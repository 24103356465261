export function setUser(state, user) { //se ejecuta una vez el usaurio haga login
    state.user = user
    state.isLogged = true
    state.error = false
    state.errorMessage = ''
}

export function setPhrase(state, phrase) { //se ejecuta una vez el usaurio haga login
    state.quote = phrase
}

export function logout(state) {
    state.user = null
    state.permissions = []
    state.navigation = []
    state.quote = null
    state.isLogged = false
}



export function setPermissionsUser(state, permissions) {
    state.permissions = permissions
}

export function setNavigationUser(state, navigation) {
    state.navigation = navigation
}

export function setLoadingAuth(state, bool) {
    state.isLoadingAuth = bool
}

export function setChangeChannelUser(state, data) {
    const { canalventa, seccioncanalventa } = data
    state.user.canalventa = canalventa
    state.user.sectionCanal = seccioncanalventa    
}

export function setUserFP(state, fp) {
    state.user.fp = fp
    state.user.fpBackup = fp
}