export function setProfit(state, profit) {
    state.costs = profit
    state.costsAreBlocked = profit.some(pr => !!pr.blocked)
}

export function setFees(state, fees) {
    state.fees = fees
   
}
export function setBlockingDataProfit(state, blockingDataProfit) {
    state.blockingDataProfit= blockingDataProfit
}
export function setBlockingDataAnualProfit(state, blockingDataAnualProfit) {
    state.blockingDataAnualProfit = blockingDataAnualProfit
}
export function setExpenses(state, expenses) {
    state.expenses= expenses
    
}

export function setRentalPoolFilter(state, rentalPoolFilter) {
    state.rentalPoolFilter= rentalPoolFilter
    
}

export function setFeesType(state, feesType) {
    state.feesType= feesType
    
}

export function setSelectedFees(state, selectedFees) {
    state.selectedFees= selectedFees
    
}

export function setPerformanceAnual(state, performance) {
    state.performance = performance.map(item => ({ ...item, originalAdjustment: item.adjustment }))
    state.performanceIsBlocked = performance.some(pr => !!pr.blocked)

}

export function setCatalogPerformanceConcepts(state, concepts) {
    state.catalogPerformanceConcepts= concepts
    
}

export function setPerformanceConcepts(state, performanceConcept) {
    state.performanceConcept = performanceConcept

}

export function setBenefitsMonetizationConfig(state, benefitsMonetizationConfig) {
    state.benefitsMonetizationConfig = benefitsMonetizationConfig
}

export function setRoomsSubTypes(state, roomsSubTypes) {
    state.roomsSubTypes = roomsSubTypes
}

export function setShowBtns(state, item) {
    const index = state.performance.findIndex(dataItem => dataItem.id === item.id)
    state.performance[index].showBtns = true
    state.performance[index].adjustment = item.adjustment
}


export function setIsShow(state, itemId) {
    const index = state.performance.findIndex(dataItem => dataItem.id === itemId)
    state.performance[index].showBtns = false
    state.performance[index].adjustment = structuredClone(state.performance[index].valueOriginal)
  }


  export function setUpdateAdjustment(state, item) {
    const index = state.performance.findIndex(dataItem => dataItem.id === item.id)
    state.performance[index].adjustment = structuredClone(item.adjustment)
    state.performance[index].adjustmentId = structuredClone(item.adjustmentId)
    state.performance[index].valueOriginal = structuredClone(item.adjustment)
    state.performance[index].total = structuredClone(item.total)
    state.performance[index].showBtns = false
  }