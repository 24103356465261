//start module state Hotels
export function setHotels(state, hotels) {
    state.hotels = hotels
}
//start module state Categories
export function setCategories(state, categories) {
    let { hotelServices, onlyCategories, withSubCategories, categoryServices, hotelServicesWithSubCategories } = categories
 
    state.categories.hotelServices = hotelServices
    state.categories.hotelServicesWithSubCategories = hotelServicesWithSubCategories
    state.categories.onlyCategories = onlyCategories
    state.categories.withSubCategories = withSubCategories
    state.categories.categoryServices = categoryServices
}

export function setLoadingCategories(state, bool) {
    state.isLoadingCategories = bool
}

//start module state Destinationsdc
export function setDestinations(state, destinations) {
    state.destinations = destinations
}

//start module state Status
export function setStatus(state, status) {
    state.status = status
}

//start module state Currencies
export function setCurrencies(state, currencies) {
    state.currencies = currencies
}

export function setPromoCodes(state, promoCodes) {
    state.promoCodes = promoCodes
}

export function setLanguages(state, languages) {
    state.languages = languages
}

//start module state departamentos 
export function setDepartaments(state, departaments) {
    state.departaments = departaments
}

export function setCustomerTypes(state, customerTypes) {
    state.customerTypes = customerTypes
}

export function setAirlines(state, airlines) {
    state.airlines = airlines
}

//pongo en el estado la lista de metodo de pago del usuario logueado
export function setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods

    const validFormat = paymentMethods.filter(item => item.usecredit == true)
    if (validFormat.length == 1) {
        state.useCredit = true
    }

}

//Terminos y condiciones 
export function setTerms(state, terms) {
    state.terms = terms
}


//Terminos y condiciones 
export function setMarketpermissions(state, permissions) {
    state.marketpermissions = permissions
}

export function setUnavailableDates(state, unavailabledates) {
    state.unavailabledates = unavailabledates
}

export function setTypeService(state, TypeService) {
    state.typeService = TypeService
}

export function setItineraryRules(state, ItineraryRules) {
    state.itineraryRules = ItineraryRules
}

export function setParamsRates(state, paramsRates) {
    
    state.payloadParamsRates = paramsRates
}
export function setDataQueryParams(state, queryParams) {
    state.queryParams = queryParams
}

export function setNumberChildren(state, number) {
    state.queryParams.children = number
}

export function setNumberAdults(state, number) {
    state.queryParams.adults = number
}

export function setDateInQueryParams(state, date) {
    state.queryParams.dateRange = date
}
export function setTypeQueryParams(state, type) {
    state.queryParams.type = type
}
export function setDestinationInQueryParams(state, destination) {
    state.queryParams.destination = destination
}





