export default {
    products: [],
    filter: {
        query: '',
    },
    orderBy: 'alfabetico',
    isloadingProducts: false,
    productDetail: null,        
    selectedCategory: null,
    SelectedExperienceDate:null          
}