import Vue from 'vue'
import { handlerErrors, consoleMsgFinally, showAlertMessage, formatDateOnly, hoursOnly } from '../../../helpers/helpers'
import store from '../../../store' //para acceder a state para isLogged

export async function getInitialContentIncidents( context ){          
    const content = await Promise.all([        
         fetchUsers(),fetchTitle(), fetchType(),fetchDescRes()
    ])    
    const [ users,title,incType,descRes] = content  
    context.commit('incidents/setUsers', users, { root: true }) //muto state hotels
    context.commit('incidents/setTitle',title, { root: true })//muto state title
    context.commit('incidents/setType',incType, { root: true })//muto state type
    context.commit('incidents/setDescRes',descRes,{ root: true })//muto state resolvió    
     
    return content
}



export async function fetchStatusIncidents({ commit }) {
    try {
        const response = await Vue.axios({
            url: 'catalogs/statusincidents/'
        })
        const payload = response && response.data
        payload.forEach((statusincidents) => {
            statusincidents.value = statusincidents.id
            statusincidents.text = statusincidents.name
            delete statusincidents.id
            delete statusincidents.name
            delete statusincidents.deleted
            delete statusincidents.create
        })
        commit('setStatusIncident', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setErrorStatusIncident', errorMsg)
    } finally {
        consoleMsgFinally('setStatusIncident', 'La petición para obtener los estados se ha terminado')
    }
}


export async function createIncident({ commit }, datos) {
    const { file } = datos

    try {
        commit('setLoadingProess', true, { root: true }) //init loader 
        const formData = new FormData()

        formData.append('file', file)
        formData.append('data', JSON.stringify(datos))
        console.log(formData)


        const response = await Vue.axios({
            method: 'POST',
            url: `/incidents/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta

        if (status) {
            showAlertMessage(
                'Autorización realizada', 'InfoIcon',
                '🎉🎉La creacion de la incidencia a sido exitoso',
                'success', 4000, 'bottom-right'
            )


        }
    } catch (error) {
        if (error.response) {
            showAlertMessage('Código incorrecto', 'InfoIcon', `😪😪 El código para su usuario  s incorrecto`, 'danger', 4000, 'bottom-right')
        }
    } finally {
        commit('incidents/setLoadingProcess', false, { root: true }) //init loader         
        consoleMsgFinally('createIncident', 'La petición para autorizar con código ha finalizado')
    }
}


export async function fetchIncidents({ commit }, id) {
    const idOrder = id
    try {
        const response = await Vue.axios({
            url: `/incidents/?`,
            params: {
                id: idOrder
            }
        })
        const payload = response && response.data
        payload.datecreate = formatDateOnly(payload.datecreate, 'es')

        if (payload.files.name != '') {
            payload.files.nameShort = payload.files.name.split("/")
            payload.files.nameShort = payload.files.nameShort[3]

        }
        commit('setIncident', payload)
    } catch (error) {
        console.log( error )
    } finally {

        consoleMsgFinally('incidents/setIncident', 'La petición para obtener los detalles de transacciones de la orden se ha terminado')
    }
}


export async function incidentsdescription({ commit }, datos) {
    const { commentsFile } = datos
    try {
        commit('incidents/setLoadingProcess', true, { root: true }) //init loader 
        const formData = new FormData()

        formData.append('file', commentsFile)
        formData.append('data', JSON.stringify(datos))

        const response = await Vue.axios({
            method: 'POST',
            url: `/incidentsdescription/`,
            data: formData
        })
        const respuesta = response && response.data
        if (respuesta) {
            commit('unsetErrorIncidents', respuesta)
        }

    } catch (error) {
        if (error.response) {
            showAlertMessage('Código incorrecto', 'InfoIcon', `😪😪 Ocurrio un error`, 'danger', 4000, 'bottom-right')
        }
    } finally {
        commit('incidents/setLoadingProcess', false, { root: true }) //init loader         
        consoleMsgFinally('incidentsdescription', 'Se agreago comentario')
    }
}


export async function fetchInincIdentsDescription({ commit }, payload) {
    const idOrder = payload

    try {        
        const response = await Vue.axios({
            url: `/incidentsdescription/?`,
            params: {
                id: idOrder
            }
        })
        const payload = response && response.data

        payload.forEach((statusincidents) => {
            statusincidents.time = hoursOnly(statusincidents.createdate)
            statusincidents.createdate = formatDateOnly(statusincidents.createdate, 'en')
            statusincidents.username = statusincidents.user.name
            if (statusincidents.user.id != store.state.auth.user.idUser) {
                statusincidents.class = 'message-box message-partner'
            } else {
                statusincidents.class = 'message-box'
            }
            if (statusincidents.files.name != '') {
                statusincidents.files.type = statusincidents.files.name.split(".")
            }
        })
        commit('setIncidentsdescription', payload)

    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('incidents/fetchInincIdentsDescription', 'La petición parafetchInincIdentsDescription ha terminado')
    }
}


export async function incidentdesupdate({ commit }, datos) {
    const { file } = datos
    try {
        commit('incidents/setLoadingProcess', true, { root: true }) //init loader 
        const formData = new FormData()

        formData.append('file', file)
        formData.append('data', JSON.stringify(datos))


        const response = await Vue.axios({
            method: 'POST',
            url: `/incidentsupdate/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta

        if (status) {
            showAlertMessage(
                'Autorización realizada', 'InfoIcon',
                '🎉🎉Se actualizo la incidencia',
                'success', 4000, 'bottom-right'
            )


        }
    } catch (error) {
        if (error.response) {
            showAlertMessage('Código incorrecto', 'InfoIcon', `😪😪 Ocurrio un error`, 'danger', 4000, 'bottom-right')
        }
    } finally {
        commit('incidents/setLoadingProcess', false, { root: true }) //init loader         
        consoleMsgFinally('incidentsdescription', 'Se agreago comentario')
    }
}


export async function fetchUsers() {
    const idCanal =  store.state.auth.user?.canalventa?.id
    try {
        
        const response = await Vue.axios({
            url: `/catalogs/usuariosIncidentes/${idCanal}`
        })
        const payload = response && response.data
        payload.forEach((user) => {
            user.value = user.dataCanal.id,
            user.text = user.dataCanal.canalname+' - '+user.dataCanal.first_name + ' ' + user.dataCanal.last_name 
            
        })
        payload.sort(function(a, b){
            if(a.dataCanal.canalname < b.dataCanal.canalname) { return -1}
            if(a.dataCanal.canalname > b.dataCanal.canalname) { return 1}
            return 0
        })
        return payload 

    } catch (error) {
        console.log( error )
    } finally {        
        consoleMsgFinally('fetchUsers', 'La petición para obtener los usuarios se ha terminado')
    }
}

export async function fetchTitle() {
    try {
        
        const response = await Vue.axios({
            url: '/incidents/titleIncidents/'
        })
        const payload = response && response.data
        payload.forEach((title) => {
            title.value=title.id
            title.text = title.title            
        })
        /*payload.sort(function(a, b){
            if(a.dataCanal.canalname < b.dataCanal.canalname) { return -1}
            if(a.dataCanal.canalname > b.dataCanal.canalname) { return 1}
            return 0
        })*/
        return payload 

    } catch (error) {
        console.log( error )
    } finally {        
        consoleMsgFinally('fetchTitle', 'La petición para obtener los títulos se ha terminado')
    }
}

export async function fetchType() {
    try {
        const response = await Vue.axios({
            url: '/incidents/typeIncidents/'
        })
        const payload = response && response.data
        payload.forEach((incType) => {
            incType.value = incType.id
            incType.text = incType.title            
        })
        /*payload.sort(function(a, b){
            if(a.dataCanal.canalname < b.dataCanal.canalname) { return -1}
            if(a.dataCanal.canalname > b.dataCanal.canalname) { return 1}
            return 0
        })*/
       return payload 

    } catch (error) {
        console.log( error )
    } finally {        
        consoleMsgFinally('fetchType', 'La petición para obtener los tipos de queja se ha terminado')
    }
}

export async function fetchDescRes() {
    try {
        const response = await Vue.axios({
            url: '/incidents/closeIncidents/'
        })
        const payload = response && response.data
        payload.forEach((descRes) => {
            descRes.value = descRes.id
            descRes.text = descRes.title            
            console.log(descRes);
        })
        /*payload.sort(function(a, b){
            if(a.dataCanal.canalname < b.dataCanal.canalname) { return -1}
            if(a.dataCanal.canalname > b.dataCanal.canalname) { return 1}
            return 0
        })*/
       return payload 

    } catch (error) {
        console.log( error )
    } finally {        
        consoleMsgFinally('fetchDescRes', 'La petición para obtener la descripcion de resolución se ha terminado')
    }
}

