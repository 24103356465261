export function filteredTransactions( state ) {
    let transactions = state.transactions
    const query = state.filter.queryTransactions
    // con esto filtro los resultado por el name del contract, no es case sensitive
    if( query.length > 1 ){
        transactions = transactions.filter(
            transaction => transaction.benefit.toLowerCase().includes( query.toLowerCase() ) ||
            transaction.code.toString().toLowerCase().includes( query.toLowerCase() )
        )
    }
    return transactions
}


export function filteredDetailTransactions( state ) {
    let detailTransactions = state.detailTransactions
    const query = state.filter.queryDetailTransactions
    // con esto filtro los resultado por el name del contract, no es case sensitive userName ownerName
    if( query.length > 1 ){
        detailTransactions = detailTransactions.filter( detailTransaction => {
            detailTransaction?.benefit?.toLowerCase().includes( query.toLowerCase() ) ||
            detailTransaction?.userName?.toLowerCase().includes( query.toLowerCase() ) ||
            detailTransaction?.ownerName?.toLowerCase().includes( query.toLowerCase() )
        })
    }
    return detailTransactions
}
