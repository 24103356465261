import * as moment from 'moment'
import { filter } from 'lodash'
import { stringAleatorio, showAlertMessage, camposRequeridosDeBookingPorLLenar, removeDuplicated, consoleMsgFinally } from '@/helpers/helpers'
import { cardTypeFinder, formatGuestsInBooking } from '@/helpers/shopHelper'
import { totalBalance } from '@/modules/shop/store/shop/getters'


export function addProductInCart(state, product) {
    delete product.packageData
    product.keyRate= product.uuid
    state.cart.items = [...state.cart.items, product ]
    
    if( product.modelType == 'rates' ){
        addProductInCartRates(state,product)
    }

    validPromo(state)
    // calculateTotalBreakdown(state)
    showAlertMessage( 'Producto agregado', 'CheckCircleIcon', `🎉🎉 Se ha añadido el producto: ${product.detaildisplay}.`, 'success', 5000, 'bottom-right')
    setIfRequiredFieldsFilled(state)
}

export function addProductInCartRates(state,product) {

        if( product.typeRate === 'Adults' ){

            const listEperiences = structuredClone(state.cart.items.findLast( item => item.experienceContractRateId == product.experienceContractRateId ))
            listEperiences.show = true
            listEperiences.keyRate= listEperiences.uuid
            listEperiences.uuid=stringAleatorio()
            listEperiences.typeRate = ""
            state.cart.items = [...state.cart.items, listEperiences ]
        }

        if( product.typeRate === 'Children'){
            
            const listEperiences = structuredClone(state.cart.items.filter( item => item.experienceContractRateId == product.experienceContractRateId ))
            const itemFind = listEperiences.find( item => item.typeRate == 'Adults')
            product.show = false
            product.keyRate = itemFind.uuid
        }

        if( product.typeRate === 'Pax'){
            product.show = true
        }


}

export function addProductsFromPackage(state, products) {
    if(products.length > 0) {
        state.cart.items = [...state.cart.items, ...products ]
        state.cart.isPackage = true
        validPromo(state)
        // calculateTotalBreakdown(state)
        showAlertMessage('Productos del paquete', 'CheckCircleIcon', `🎉🎉 ${products.length } productos del paquete han sido agregados.`, 'success', 5000, 'bottom-right')
        setIfRequiredFieldsFilled(state)
    }
}


export function addBookingInCart(state, data) {

    setPropertiesBooking(data)
    removeKeysBooking(data)
    const itemClone = structuredClone(data)
    const itemWithGuests = formatGuestsInBooking(itemClone)
    state.cart.items.push(itemWithGuests)
    showAlertMessage('Reserva agregada', 'BellIcon', `🎉🎉 Reserva agregada, habitación ${itemWithGuests.detaildisplay}. `, 'success', 5000, 'bottom-right' )
    // calculateTotalBreakdown(state)
    setIfRequiredFieldsFilled(state)
}


export function addBookingInCartOwners(state, data) {
    state.cart.items = structuredClone(state.cart.items.filter(items => items.categoryName != "ReservasOwners"))
    setPropertiesBooking(data)
    removeKeysBooking(data)
    const itemClone = structuredClone(data)
    if (data.categoryroomName==null) state.cart.items.push(itemClone)
    else {
        state.cart.items=[]
        state.cart.items.push(itemClone)
    }

    state.cart.cliente.hotel = data.idHotel == 5 ? 3 : data.idHotel
    state.cart.cliente.isOwnerBooking = true

    state.cart.ownerbookingbenefits = {
        active: true,
        idconfigurationRoom: data.idconfigurationRoom,
        nightsContract: data.nightsContract,
        nightsXtra: data.nightsXtra,
        hasBothNights: data.hasBothNights
    }

    showAlertMessage(
        'Reserva agregada', 'BellIcon',
        `🎉🎉 Reserva agregada, habitación ${data.detaildisplay}. `,
        'success', 5000, 'bottom-right'
    )
    // calculateTotalBreakdown(state)
    setIfRequiredFieldsFilled(state)
}

export function removeKeysBooking(item) {
    delete item.AmountAfterTax
    delete item.AmountBeforeTax
    delete item.CurrencyCode
    delete item.MealPlanDescriptionCustom
    delete item.MinimumMarkup
    delete item.PromotionName
    delete item.RatePlanCode
    delete item.RatePlanID
    delete item.ReasonNotAvailable
    delete item.RoomRateDescription
    delete item.RoomTypeCode
    delete item.Taxes
    delete item.TotalOffersApplied
    delete item.newManualPrice
    delete item.newManualPriceInit
    delete item.priceMatchTax
    delete item.divisa
    delete item.allowPriceMatch
}

export function setPropertiesBooking(item) {

    // Amounts
    // Valores Unitarios
    // item.saleprice = saleprice
    item.originalSalePrice = parseFloat(item.AmountAfterTax)
    item.salepriceWithDiscount = parseFloat(item.AmountAfterTax)
    item.cost = 0.00
    item.unitPrice = parseFloat(item.AmountAfterTax)
    item.discountApplied = 0
    item.discount = 0
    item.typeDiscount = 1 // Sin descuento
    // Valores Totales
    item.total = parseFloat(item.AmountAfterTax)
    item.grossSale = parseFloat(item.AmountAfterTax)
    item.totaldiscount = 0
    item.netSale = parseFloat(item.AmountAfterTax)

    item.qty = 1
    item.isBenefit = false,
    item.blackout = item.blackout ? item.blackout : 0,
    item.vendor = item.vendorcode
    item.hotel = item.hotel
    item.ownerBooking = item.idOwnerBooking ? item.idOwnerBooking : null,
    item.idioma = item.language ? item.language : 'en'
    item.roomCode = item.RoomTypeCode
    item.dateIn = item.dateIn
    item.dateOut = item.dateOut
    item.adultos = item.adults
    item.ninosTotal = item.numberChildren
    item.nightsTotal = []
    item.nightsNum = 1
    item.firstPay = item.AmountAfterTax
    item.nightsTotalAmount = 0,
    item.partial = 0, // valor para saber si aplica pago parcial
    item.ninos = item.ninos
    item.promotionCode = item.PromotionCode
    item.inclusions = []
    item.note = '',
    item.show=false,
    item.isMain = false
    item.clientnote = '',
    item.term = item.term  ?  item.term : null,
    item.requiredFieldsFilled = false //campos requeridos llenos
    item.uuid = stringAleatorio()
    item.fechaDeCreacion = moment(new Date()).format('YYYY-MM-DD')
    item.NamePrefix = 'Mr'
    item.GivenName =  item.name  ?  item.name : ""
    item.Surname = item.lastname  ?  item.lastname : ""
    item.PhoneNumber = item.phone  ?  item.phone : ""
    item.Email = item.email  ?  item.email : ""
    item.inclusiones = [],

    item.inclusioneslist = [],
    item.orderdetailbeo = {
        titulo: '',
        backup: null,
        locacion: null,
        comentario: '',
        pax: 0,
        numreserva: '',
        detailbeo: []
    },
    item.rateplancode = item.RatePlanCode,
    item.rateplanid = item.RatePlanID,
    item.benefitSelected=item.benefitSelected

    // MealPlanCodes
    // RatePlanCode
}

export function setOperationDateProduct(state, payload) {
    const { uuid, date } =  payload
    const idx = state.cart.items.findIndex( item => item.uuid === uuid )
    state.cart.items[idx].operationdate = date
    setIfRequiredFieldsFilled(state)
}

export function changeQtyProductsPackage(state, payload){
    const { uuidPackage , qty} = payload
    state.cart.items.forEach( product => {
        const { uuidPackage:keyPackage } = product
        if( keyPackage === uuidPackage ){
            product.qty = qty
        }
    })
}

export function calculateTotalBreakdown(state) {
    let sum = 0
    let sumProduc = 0
    let sumNight = 0
    const decimalDiscount = parseFloat(state.cart.totalBreakdown.discountApplied) / 100;
    state.cart.items.forEach((product) => {

        if( product.show == false || product.typeRate === 'Pax' ){
            let precio = (product.discountApplied > 0) ? product.salepriceWithDiscount : product.saleprice

            const qtyFlag = product.typeRate === 'Pax' ?  1 : product.qty

            sum += (parseFloat(precio) * parseFloat(qtyFlag))

            if (product.modelType == 'booking' && product.partial == 1) {
                sumNight += (parseFloat(product.firstPay))
            } else {
                product.firstPay = 0;
                sumProduc += (parseFloat(precio) * parseFloat(qtyFlag))
            }
        }
    })
    const descuento = sum * decimalDiscount
    state.cart.firstPay = sumNight
    state.cart.totalBreakdown.total = ((Math.round(sum * 100) / 100).toFixed(2)) - descuento
    state.cart.firstPayTotal = ((Math.round(sumProduc * 100) / 100).toFixed(2)) - descuento
    state.cart.cliente.paymentList =[]
}

export function setTotalBreakdown(state, payload) {
    const {products, totals} = payload
    const { saleprice, subtotal, discount, firstPaymentGross, firstPaymentTotal } = totals

    products.forEach((productTotals) => {
        let product = state.cart.items.find(prod => prod.uuid === productTotals.uuid)
        if (!!product) {
            // product.discountApplied = productTotals.
            if (product.modelType == 'booking' && product.partial == 1) {
                product.firstpay = productTotals?.firstpay || 0.00
            }

            // Valores Unitarios
            product.saleprice = productTotals?.total || 0.00
            product.salepriceWithDiscount = productTotals?.total || 0.00
            product.typeDiscount = productTotals?.typediscount || 1
            product.discount = productTotals?.discount || 0.00
            product.unitPrice = productTotals?.unitPrice || 0.00
            product.extraAmount = productTotals?.extraAmount || 0.00

            // Valores Totales
            product.total = productTotals?.total || 0.00
            product.subtotal = productTotals?.subtotal || 0.00
            product.netSale = productTotals?.total || 0.00

            product.promoName = productTotals?.promoName || 0.00
            product.promoId = productTotals?.promoId || 0.00

        }
    })

    state.cart.firstPay = firstPaymentGross || 0.00 // suma de los primeros pagos de reservas
    state.cart.totalBreakdown.total = saleprice || 0.00 // Suma de los totales de loos productos
    state.cart.firstPayTotal = firstPaymentTotal || 0.00 // Suma de totales con primeros pagos de reserva en vez del total de la reserva
    state.cart.totalDiscount = discount || 0.00
    state.cart.subtotal = subtotal || 0.00
    // state.cart.cliente.paymentList = []
    // reasignPaymentList(state)

    // discount = 38
    // saleprice = 152
    // subtotal = 190
}

export function reasignPaymentList(state) {
    const hasAnOwnerBooking = state.cart.items.some(items => items.categoryName == "ReservasOwners")
    if (parseFloat(state.paymentFormat.total) < 0) {
      if (hasAnOwnerBooking) state.cart.cliente.paymentList = state.cart.cliente.paymentList.filter(pm => pm.id == 1055)
      else state.cart.cliente.paymentList = []
      state.paymentFormat.total = totalBalance(state)
    }

}

export function setBlackoutsDateProduct(state, payload) {
    const { uuid, blackoutDates , maxDate  } =  payload
    const idx = state.cart.items.findIndex( item => item.uuid === uuid )
    state.cart.items[idx].blackoutDates.disable = blackoutDates
    state.cart.items[idx].blackoutDates.maxDate = maxDate
}

export function removeProductInCart(state, product ) {
    if (state.cart.items.length === 1) {
        state.cart.totalBreakdown.discountApplied = 0
    }
    const { uuid, uuidPackage, isIncludenIn, categoryName,keyRate,modelType } = product
    if(uuidPackage){ //si es un item de paquete
        state.cart.items = state.cart.items.filter( item => item.uuidPackage !== uuidPackage ) //borro los productos hijos
        state.cart.items = state.cart.items.filter( item => item.uuid !== uuidPackage ) //borro el package padre
    }
    if(!uuidPackage){
        state.cart.items = state.cart.items.filter( item => item.uuidPackage !== uuid )
    }
    if(isIncludenIn){
        const item = state.cart.items.find(item => item.uuid === isIncludenIn)
        if (item) {
            item.inclusions = item.inclusions.find(inclusion => inclusion.productKey != product.uuid)
        }
    }

    if (categoryName == "ReservasOwners") deleteOwnerBookingCartData(state)

    deleteBenefit(state,uuid)
    deleteBenefitMember(state,uuid)

    if (modelType == "rates")  state.cart.items = state.cart.items.filter( item =>  item.keyRate !== keyRate ) 

    state.cart.items = state.cart.items.filter( item => item.uuid !== uuid )

    state.cart.items.forEach((product) => {

        if( product.ownerBooking != null ){
            state.cart.cliente.isOwnerBooking = false
        }

    })
    let infoList = []
    state.cart.items.forEach((product) => {
        infoList = filter(state.listBenefitUsed, ({ benefit }) => benefit.uuid !== product.uuid)
    })
    state.listBenefitUsed = infoList
    validPromo(state)
    // calculateTotalBreakdown(state)
    setIfRequiredFieldsFilled(state)
}

export function validPromo(state) {
    let promo = []
    let promoList = []
    let promoDetail = []
    state.cart.items.forEach((product) => { // Obtiene la lista de promociones por proveedor
        if (product?.supplierPromo?.length > 0) {
            product.supplierPromo.forEach((promoAplicate) => {
                if (!!promoAplicate.id && !promo.includes(promoAplicate.id, promoAplicate.cant, 0)) promo.push({
                    'id': promoAplicate.id,
                    'cant': promoAplicate.cant,
                    'desc': promoAplicate.desc,
                    'title': promoAplicate.title,
                    'uuid': product.uuid,
                    'idProd': product.id
                });
            })
        }
    })

    const uniqPromos = removeDuplicated(promo, 'id')

    if (uniqPromos?.length > 0) {
        const productsWithDiscounts = state.cart.items.filter((product) => product?.supplierPromo?.length > 0)
        uniqPromos.forEach((promoAplicate) => { // Asigno las promociones una por una

            productsWithDiscounts.forEach((product) => { // Busco por cada uno de los productos
                let idProduc = product.supplierPromo.find(item => item.id === promoAplicate.id)
                if (!!idProduc && idProduc?.id == promoAplicate?.id) {
                    let idvalid = promoList.find(item => item.id === product.id)
                    if (idvalid == undefined) promoList.push(product) // Si no existe el mismo producto, le asigno una nueva promocion
                    else promoDetail.push(product) // Si existe el mismo producto, le asigno la mísma promocion
                }
            })

            if (promoList?.length == promoAplicate?.cant) {
                promoList.forEach((promoNum) => { // Asigna descuento a los productos diferentes
                    aplicateDiscount(state, {uuid: promoNum.uuid, desc: promoAplicate.desc, name: promoAplicate.title, promoId: promoAplicate.id})
                })

                promoDetail.forEach((promoDet) => { // Asigna el mismo descuento a los productos repetidos
                    aplicateDiscount(state, {uuid: promoDet.uuid, desc: promoAplicate.desc, name: promoAplicate.title, promoId: promoAplicate.id})
                })
            }

            promoList = []
            promoDetail = []

        })

    }
}

export function handlerDiscount(state, productPromo) {
    let promo = []
    let promoList = []
    productPromo.promoName = ''
    state.cart.items.forEach((product) => {
        if (product?.supplierPromo?.length > 0) {
            product.supplierPromo.forEach((promoAplicate) => {
                if (!!promoAplicate.id && !promo.includes(promoAplicate.id, promoAplicate.cant, 0)) promo.push({
                    'id': promoAplicate.id,
                    'cant': promoAplicate.cant,
                    'desc': promoAplicate.desc,
                    'title': promoAplicate.title,
                    'idProd': product.id
                })
            })
        }
    })

    const uniqPromos = removeDuplicated(promo, 'id')

    if (uniqPromos.length > 0) {
        let promoInfo = state.cart.items.filter(item => item.uuid != productPromo.uuid)

        uniqPromos.forEach((promoAplicate, index) => {
            if ( promoInfo.length > 0 ) promoInfo.forEach((product) => {
                if (product.supplierPromo && product.supplierPromo?.length != 0) if (product.supplierPromo[index]?.id == promoAplicate?.id && product.id != promoAplicate?.idProd) promoList.push(product)
            })

            if (promoList?.length == promoAplicate?.cant) promoList.forEach((promoNum) => {
                aplicateDiscount(state, {uuid: promoNum.uuid, desc: promoAplicate.desc, name: promoAplicate.title, promoId: promoAplicate.id})
            })

            promoList = []
        })
    }
}

export function aplicateDiscount(state, data) {
    const {uuid, desc, name, promoId} = data

    let item = state.cart.items.find(item => item.uuid === uuid)

    item.discountApplied = desc
    const decimalDiscount = parseFloat(desc) / 100
    const descuento = item.originalSalePrice * decimalDiscount
    item.salepriceWithDiscount = (Math.round(item.originalSalePrice * 100) / 100).toFixed(2) - descuento
    item.typeDiscount = 6 // "Promoción"
    item.promoName = name
    item.promoId = promoId

}

export function aplicateDescountReset(state, id) { // Borra descuentos por id
    let productPromo = state.cart.items.filter(item => item.id == id)

    productPromo.forEach((promoAplicate) => {
        promoAplicate.discountApplied = 0
        promoAplicate.typeDiscount = 1 // "Sin descuento"
        promoAplicate.promoName = ''
        productPromo.promoId = null
        delete productPromo.promoId
    })
    // calculateTotalBreakdown(state)
}

export function aplicateDescountResetUuid(state, uuid) { // Borra descuentos por uuid
    let productPromo = state.cart.items.filter(item => item.uuid === uuid)

    productPromo.forEach((promoAplicate) => {
        promoAplicate.discountApplied = 0
        promoAplicate.typeDiscount = 1 // "Sin descuento"
        promoAplicate.promoName = ''
        productPromo.promoId = null
        delete productPromo.promoId
    })
}

export function aplicateDescountHardReset(state, id = null, uuid = null) {
    let productPromo
    if (id) productPromo = state.cart.items.filter(item => item.id == id)
    if (uuid) productPromo = state.cart.items.filter(item => item.id == id)

    productPromo.forEach((promoAplicate) => {
        const descuentoAplicar = 0
        promoAplicate.discountApplied = descuentoAplicar
        promoAplicate.salepriceWithDiscount = promoAplicate.originalSalePrice
        promoAplicate.saleprice= promoAplicate.total
        if (promoAplicate?.itinerario?.extraPax && promoAplicate?.itinerario?.amountExtraPax) {
            const extrapaxprice = promoAplicate.itinerario.amountExtraPax * promoAplicate.itinerario.extraPax
            promoAplicate.salepriceWithDiscount = promoAplicate.originalSalePrice + extrapaxprice
            promoAplicate.saleprice= promoAplicate.total = promoAplicate.originalSalePrice + extrapaxprice
        }
        promoAplicate.typeDiscount = 1 // "Sin descuento"
        promoAplicate.promoName = ''
        productPromo.promoId = null
        delete productPromo.promoId
    })
}

export function calculateTotalNights(state, nights) {

    var numNigths = []
    for (let i = 0, len = nights.nights; i < len; i++) {
        numNigths.push({ num: i + 1 })
    }

    nights.nightsTotal = numNigths
}

export function setIfRequiredFieldsFilled(state) {
    state.cart.requiredFields = []
    state.cart.items.forEach((item) => {
        item.requiredFieldsFilled = false
        if (item.categoryName == "Transfer") {
            const formItinerary = (
                item.operationdate && item.operationdate != '' &&
                item.typeDiscount != 5 &&
                item.itinerario.ifrom && item.itinerario.ifrom != '' &&
                item.itinerario.ito && item.itinerario.ito != '' &&
                item.itinerario.pax && item.itinerario.pax != '' &&
                item.itinerario.trip && item.itinerario.trip != ''
            )

            item.requiredFieldsFilled = formItinerary

            if (item.allowExtraPax) item.requiredFieldsFilled = true
        }

        if (item.categoryName == "Flights") item.requiredFieldsFilled = Boolean(item.operationdate)

        if (item.categoryName == "Reservas" ||  item.categoryName == "ReservasOwners") {
            const guests = camposRequeridosDeBookingPorLLenar(item)

            const formBooking = (
                Boolean(item.term) && Boolean(item.NamePrefix) &&
                Boolean(item.GivenName) && Boolean(item.Surname) &&
                Boolean(item.PhoneNumber) && Boolean(item.Email) &&
                !item.requiresAuthorization &&
                (!guests || guests?.camposFaltantes?.length == 0)
            )

            if (item.categoryName == "ReservasOwners") item.requiredFieldsFilled = Boolean(item.occupanttype) && formBooking
            else item.requiredFieldsFilled = formBooking

        }
        if (item.categoryName != "Transfer" &&
            item.categoryName != "Flights" &&
            item.categoryName != "Reservas" &&
            item.categoryName != "ReservasOwners"
        ) item.requiredFieldsFilled = Boolean(item.operationdate) && item.typeDiscount != 5 // 'Incluido en el paquete'

        if (item.typeDiscount == 5 && item.isIncludenIn && item.operationdate) item.requiredFieldsFilled = true

     
        if (item.subcategoryName == "Room Upgrades") {
    
            const upgradesDetail = (
                Boolean(item.upgradesDetail.mealPlanNameOriginName && item.upgradesDetail.mealPlanNameOriginName != null)  &&
                Boolean(item.upgradesDetail.mealPlanNameDestinationName && item.upgradesDetail.mealPlanNameDestinationName != null ) &&
               
                Boolean(item.upgradesDetail.rangeDate && item.upgradesDetail.rangeDate != null) 
            )
            item.requiredFieldsFilled = upgradesDetail
            item.requiredFieldsFilled = Boolean(item.operationdate)
        }
        if (item.subcategoryName == "Plan Upgrades") {
    
            const upgradesDetail = (
              
                Boolean(item.upgradesDetail.roomsTypesOriginName && item.upgradesDetail.roomsTypesOriginName != null)  &&
                Boolean(item.upgradesDetail.roomsTypesDestinationName && item.upgradesDetail.roomsTypesDestinationName != null) &&
                Boolean(item.upgradesDetail.rangeDate && item.upgradesDetail.rangeDate != null) 
            )
            item.requiredFieldsFilled = upgradesDetail
            item.requiredFieldsFilled = Boolean(item.operationdate)
        }
        if (!item.requiredFieldsFilled) state.cart.requiredFields.push((item.categoryName == "Reservas" || item.categoryName == "ReservasOwners") ? item?.detaildisplay : item?.name)
    })
}


export function setLoadingProcess(state, bool) {
    state.isLoadingProcess = bool
}

//seteo del canal de venta
export function setCanalVenta(state, canalVenta) {
    state.canalventa = canalVenta
}
export function setSellerCanalVenta(state, canalVenta) {
    state.cart.cliente.sellercanalventa = canalVenta
}
//limpio el canal de venta
export function unsetCanalVenta(state) {
    state.canalventa = null
}
//seteo del punto de venta
export function setPuntoDeVenta(state, puntoDeVenta) {
    state.puntoventa = puntoDeVenta
}
//limpio el punto de venta
export function unsetPuntoDeVenta(state) {
    state.puntoventa = null
}
//pongo el currency default para el client
export function setCurrency(state, currency) {
    state.cart.cliente.currency = currency
}

// seteo de costo de producto (Comentado porque no se usa en ninguna parte)
// export function setCostProduct(state, product) {
//     const detailId = product.detailId
//     const modelType = product.modelType
//     let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
//     item.cost = product.cost
//     calculateTotalBreakdown(state)
//     setIfRequiredFieldsFilled(state)
// }

// (Comentado porque no se usa en ninguna parte)
// export function setSalepriceProduct(state, product) {
//     const detailId = product.detailId
//     const modelType = product.modelType
//     let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
//     item.saleprice = product.saleprice
//     calculateTotalBreakdown(state)
//     setIfRequiredFieldsFilled(state)
// }

//para setear el tipo de descuento al item del cart
export function setTypeDiscount(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.typeDiscount = product.typeDiscount
}
// para itinerario
export function setItinerary(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.itinerario = product.itinerario
    setIfRequiredFieldsFilled(state)
}
//para estados BEO en modal edit item cart
export function setDetalleinfoBEO(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.orderdetailbeo.detailbeo.push(product.extraField)
}
export function updateBeneficiary(state, data) {
    const { beneficiarios } = data
    const { id, fullname, email, phone, room } = beneficiarios
    let item = state.cart.items.find(item => item.uuid === uuid)

    item.beneficiarios.id = id
    item.beneficiarios.fullname = fullname
    item.beneficiarios.email = email
    item.beneficiarios.phone = phone
    item.beneficiarios.room = room
}
export function deleteFieldBEO(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.orderdetailbeo.detailbeo = filter(item.orderdetailbeo.detailbeo, ({ key }) => key !== product.fieldToDelete.key)
}

export function setNoteItem(state, data) {
    const { key, notaInterna, notaCliente } = data
    let item = state.cart.items.find(item => item.uuid === key)
    item.note = notaInterna
    item.clientnote = notaCliente
    setIfRequiredFieldsFilled(state)
}

export function setDataProductItem(state, data) {
    const { uuid, cost, saleprice, originalSalePrice, salepriceWithDiscount, operationtime, note, clientnote, itinerario, extraField, orderdetailbeo, typeDiscount, isIncludenIn, beneficiarios,bookingReference } = data
    const { id, fullname, email, phone, room } = beneficiarios

    let item = state.cart.items.find(item => item.uuid === uuid)
    item.cost = cost
    item.saleprice = saleprice
    item.originalSalePrice = originalSalePrice
    item.salepriceWithDiscount = salepriceWithDiscount
    item.bookingReference = bookingReference
    item.operationtime = operationtime
    item.note = note
    item.clientnote = clientnote
    item.isIncludenIn = isIncludenIn

    if ((!!fullname) || (!!email) || (!!phone) || (!!room) ) {
        item.beneficiarios.fullname = fullname
        item.beneficiarios.email = email
        item.beneficiarios.phone = phone
        item.beneficiarios.room = room
    }

    //Para itinerario se pone el objeto directamente
    if (itinerario) {
        item.itinerario = itinerario
        if (item.itinerario.pickup && item.itinerario.pickup == '') delete item.itinerario.pickup
        if (item.itinerario.flighttime && item.itinerario.flighttime == '') delete item.itinerario.flighttime
    }
    //para indicar el descuento
    item.typeDiscount = typeDiscount
    //encabezados del beo
    item.orderdetailbeo.titulo = orderdetailbeo.titulo
    item.orderdetailbeo.backup = orderdetailbeo.backup
    item.orderdetailbeo.locacion = orderdetailbeo.locacion
    item.orderdetailbeo.comentario = orderdetailbeo.comentario
    item.orderdetailbeo.pax = orderdetailbeo.pax
    item.orderdetailbeo.numreserva = orderdetailbeo.numreserva


    // calculateTotalBreakdown(state)
    setIfRequiredFieldsFilled(state)
}

export function setDataProductItemRate(state, product){
    const items = state.cart.items.filter(item => item.keyRate === product.keyRate)
    const mainItem = items.find(item=> !!item.show)

    items.forEach(item => {
        const { operationtime, note, clientnote, itinerario, orderdetailbeo, typeDiscount, isIncludenIn, beneficiarios ,bookingReference} = mainItem
        const { fullname, email, phone, room } = beneficiarios

        item.operationtime = operationtime
        item.note = note
        item.clientnote = clientnote
        item.isIncludenIn = isIncludenIn
        item.bookingReference = bookingReference
        if (
            (fullname && fullname != '') ||
            (email && email != '') ||
            (phone && phone != '') ||
            (room && room != '')
        ) {
            item.beneficiarios.fullname = fullname
            item.beneficiarios.email = email
            item.beneficiarios.phone = phone
            item.beneficiarios.room = room
        }

        //Para itinerario se pone el objeto directamente
        if (itinerario) {
            item.itinerario = itinerario
            if (item.itinerario.pickup && item.itinerario.pickup == '') delete item.itinerario.pickup
            if (item.itinerario.flighttime && item.itinerario.flighttime == '') delete item.itinerario.flighttime
        }
        //para indicar el descuento
    
        //encabezados del beo
        item.orderdetailbeo.titulo = orderdetailbeo.titulo
        item.orderdetailbeo.backup = orderdetailbeo.backup
        item.orderdetailbeo.locacion = orderdetailbeo.locacion
        item.orderdetailbeo.comentario = orderdetailbeo.comentario
        item.orderdetailbeo.pax = orderdetailbeo.pax
        item.orderdetailbeo.numreserva = orderdetailbeo.numreserva
    })
}

export function setMainProduct(state, data) {
    const { key, bool } = data
    let item = state.cart.items.find(item => item.uuid === key)
    item.isMainProduct = bool
    if (bool) {
        const product = state.cart.items.filter(item => item.uuid !== key)
        product.forEach((prod) => {
            prod.isMainProduct = false
        })
    }
}

export function copyDataMainProduct(state, key) {
    let item = state.cart.items.find(item => item.uuid === key)
    const mainProduct = state.cart.items.find(item => item.isMainProduct === true)
    const clonned = structuredClone(mainProduct)
    const { operationdate, operationtime, beneficiarios, note, clientnote } = clonned
    item.operationdate = operationdate
    item.operationtime = operationtime
    item.beneficiarios = beneficiarios
    item.note = note
    item.clientnote = clientnote
}

export function handlerExtraPax(state, payload) {
    const { uuid, saleprice, salepriceWithDiscount, extraPax, pax, amountExtraPax } = payload
    let item = state.cart.items.find(item => item.uuid === uuid)
    item.saleprice = saleprice
    item.salepriceWithDiscount = salepriceWithDiscount
    item.itinerario.pax = pax
    item.itinerario.extraPax = extraPax
    item.itinerario.amountExtraPax = amountExtraPax
    // calculateTotalBreakdown(state)
    setIfRequiredFieldsFilled(state)
}

export function setDataBookingItem(state, data) {
    const { uuid, note, clientnote, term, firstPay, guests } = data
    let item = state.cart.items.find(item => item.uuid === uuid)
    const Selected = guests.adults.find(guest => guest.mainGuest === true)
    item.NamePrefix = Selected.NamePrefix
    item.GivenName = Selected.GivenName
    item.Surname = Selected.Surname
    item.PhoneNumber = Selected.PhoneNumber
    item.Email = Selected.Email
    item.note = note
    item.clientnote = clientnote
    item.term = term
    item.firstPay = firstPay
    item.guests = guests

    setIfRequiredFieldsFilled(state)
    // calculateTotalBreakdown(state);
}

export function setMainBooking(state, data) {
    const { key, bool } = data
    let item = state.cart.items.find(item => item.uuid === key)
    item.isMain = bool
    if (bool) {
        const bookings = state.cart.items.filter(item => item.uuid !== key)
        bookings.forEach((booking) => {
            booking.isMain = false
        })
    }
}

export function copyDataMainBooking(state, key) {
    let item = state.cart.items.find(item => item.uuid === key)
    const mainBooking = state.cart.items.find(item => item.isMain === true)
    const clonned = structuredClone(mainBooking)
    const { NamePrefix, GivenName, Surname, PhoneNumber, Email, note, clientnote, guests } = clonned
    item.NamePrefix = NamePrefix
    item.GivenName = GivenName
    item.Surname = Surname
    item.PhoneNumber = PhoneNumber
    item.Email = Email
    item.note = note
    item.clientnote = clientnote
    if (guests) { // Si hay arreglos de húespedes
        item.guests?.adults?.forEach((guest, index) => { // busco los huespedes de la reserva actual y los lleno según el index de los de la reserva principal
            guest.Adult = guests.adults[index]?.Adult || true
            guest.Email = guests.adults[index]?.Email || ''
            guest.GivenName = guests.adults[index]?.GivenName || ''
            guest.NamePrefix = guests.adults[index]?.NamePrefix || ''
            guest.PhoneNumber = guests.adults[index]?.PhoneNumber || ''
            guest.Surname = guests.adults[index]?.Surname || ''
            guest.mainGuest = guests.adults[index]?.mainGuest || false
        });
        if (item.guests?.childs && item.guests?.childs.length > 0) item.guests?.childs?.forEach((guest, index) => {
            guest.Adult = guests.childs[index]?.Adult || false
            guest.GivenName = guests.childs[index]?.GivenName || ''
            guest.Surname = guests.childs[index]?.Surname || ''
        });
    }
}


export function copyDataMainItem(state) {
    const mainBooking = state.cart.items.find(item => item.isMain === true)
    const mainProduct = state.cart.items.find((item) => item.isMainProduct === true)
    const infClient = state.cart.cliente
    if (mainBooking){
        const { GivenName, Surname, PhoneNumber, Email } = mainBooking
        infClient.email = Email
        infClient.name = GivenName
        infClient.lastname = Surname
        infClient.phone = PhoneNumber
    }
    else if (mainProduct) {
        const { fullname, email, phone } = mainProduct.beneficiarios
        infClient.email = email || ''
        infClient.name = fullname || ''
        infClient.phone = phone || ''
    }
}

export function addInclusionToBooking(state, data) {
    const { keyBooking, productKey } = data
    //busco el booking al que se le manipulará sus inclusiones
    const item = state.cart.items.find(item => item.uuid === keyBooking)
    //obtengo el resto de bookings para eliminar el nuevo producto que está trayendo
    const bookings = state.cart.items.filter(item => item.uuid != keyBooking && item.modelType == "booking")
    bookings.forEach((booking) => {
        booking.inclusions = booking.inclusions.filter(inclusion => inclusion.productKey != productKey)
    })
    delete data.keyBooking
    //para evitar que se duplique las inclusiones
    item.inclusions = item.inclusions.filter(inclusion => inclusion.productKey != productKey)
    item.inclusions.push(data)
    setIfRequiredFieldsFilled(state)
}
//delete includes room
export function deleteInclude(state, obj1) {
    let booking = state.cart.items.find(item => item.uuid === obj1.booking.uuid)
    let inclusion = booking.inclusiones.find(item => item.id === obj1.field)
    if (inclusion) {
        booking.inclusiones = booking.inclusiones.filter(item => item.id !== inclusion.id)
        booking.inclusioneslist = booking.inclusioneslist.filter(item => item.inclusion !== inclusion.id)
    }

}
export function setDataIncludes(state, data) {
    let booking = state.cart.items.find(item => item.uuid === data.v1)
    delete data.v1
    const obj = {
        inclusion: data.id
    }
    if (booking) {
        booking.inclusions.push(data)
    }

}

export function setDataBeoAreas(state, data) {
    const { uuid, extraField} = data
    let item = state.cart.items.find(item => item.uuid === uuid)
    const itemExist = item.orderdetailbeo.detailbeo.find(item => item.departamento === extraField.departamento)
    if (!itemExist) {
        item.orderdetailbeo.detailbeo.push(extraField) //array de detalles BEO
    }else{
        showAlertMessage('Ya existe', 'BellIcon', `No se puede duplicar el área.`, 'danger', 5000, 'bottom-right')
    }
}
export function removeInclusionToBooking(state, data) {
    const { keyBooking, productKey } = data
    const item = state.cart.items.find(item => item.uuid === keyBooking)
    item.inclusions = item.inclusions.filter(inclusion => inclusion.productKey != productKey)
    //para limpiar referencia en producto
    const itemProduct = state.cart.items.find(item => item.uuid === productKey)
    itemProduct.isIncludenIn = null
    setIfRequiredFieldsFilled(state)
}

//para guardar el nombre del cliente del cart
export function setidUserClient(state, idCliente) {
    state.cart.cliente.idUser = idCliente
}
//para guardar el nombre del cliente del cart
export function setInfoClient(state, clientData) {
    state.cart.cliente.name = clientData?.name?.trim() || ''
    state.cart.cliente.lastname = clientData?.lastname?.trim() || 'N/A'
    state.cart.cliente.email = clientData?.email?.trim() || ''
    state.cart.cliente.phone = clientData?.phone?.trim() || '5555555555'
    state.cart.cliente.credit = clientData?.credit || 0
    state.cart.cliente.creditBalance = clientData?.credit || ''
    state.cart.cliente.customertype = clientData?.customertype ? clientData.customertype : ""
    state.cart.cliente.id = clientData?.id || ''
    state.cart.cliente.language = clientData?.language_id || 1
}

export function setContract(state, contract) {
    const { contracts, owner } = contract
    let contractfortmat=[]
    state.cart.contract = ""
    state.cart.benefits=[]
    if (contracts != null) {
        contracts.forEach((contr) => {
            contr.isLoading = false
            contractfortmat.push(contr)
        })
        state.cart.contract = contractfortmat
        state.cart.cliente.name = owner.ownerName
        state.cart.cliente.lastname = owner.ownerLastname || 'N/A'
        state.cart.cliente.email = owner.ownerEmail?.trim() || ''
        state.cart.cliente.phone = owner.ownerPhone?.trim() || '5555555555'
    }

}

export function setContractEmail(state, contract) {
    if (contract != null) {
        state.cart.contract = contract
    }

}

export function setBenefits(state, benefits) {
    const { benefitsInfo, contractId } = benefits
    state.cart.benefits = []
    if (benefitsInfo != null) {
        state.cart.benefits = benefitsInfo
        state.cart.contractId = contractId
    }
}

export function setCurrentContractBenefitsList(state, currentBenefits) {
    state.cart.currentBenefits = currentBenefits
}

export function setCurrentContract(state, currentContract) {
    state.cart.currentContract = currentContract
}

export function setOwners(state, owner) {
    state.owners = []
    if (owner) state.owners = owner
}

export function setBookingInfo(state, benefits) {
    state.bookingInfo.benefits=benefits
}

export function setDescount(state) {
    const product = state.cart.items

    state.listBenefitUsed=[]
    let benefit=null
    let benefitLit
    let balanceBenefit=0
    let count=0
    const benefitValid = structuredClone(state.cart.benefits)

    product.forEach((pr) => {
        const year = parseInt(moment(pr.operationdate).format("YYYY"))
        benefitLit = !!benefitValid ? benefitValid.find(item => item.productDetailId == pr.detailId && item.year==year) : null

        if(benefitLit!=null) benefit=benefitLit
        else benefit=null

        if (benefit!=null){
            const { benfitname, balance, benefitId, year } = benefit
            const benefileItem = {
                balance,
                benefit: benefitId,
                benfitname,
                uuid: pr.uuid,
                discount: 100,
                year
            }

            balanceBenefit = balance
            if(balance>0) state.listBenefitUsed.push(benefileItem)
        }

        if (benefit != null && balanceBenefit > 0) {
            benefitLit.balance = benefitLit.balance-pr.qty
            pr.benefitDiscount = true

            aplicateDescountBenefit(state, pr.uuid, 100, "Beneficio de Propietario", benefit.benefitId)
            deleteBenefitMember(state,pr.uuid)
        } else {
            setDescountMember(state,pr.uuid)
            deleteBenefit(state,pr.uuid)
        }
    })

    // calculateTotalBreakdown(state)
}

export function setDescountMember(state,uuid=null) {
    const product = state.cart.items
    state.listMemberBenefitUsed=[]
    product.forEach((pr) => {
        const membershipsDiscount=state.cart.membershipsDiscount.find(item => item.categoryId == pr.idCategory)
        if(uuid==pr.uuid && pr.categoryName!="ReservasOwners" )pr.isBenefitDescout=false
        if(membershipsDiscount!=null && pr.isBenefitDescout==false ){
            pr.membershipDiscount = true
            state.listMemberBenefitUsed.push(pr)

            aplicateDescountBenefit(state, pr.uuid, membershipsDiscount?.discount, "Descuento de Membresía", '')
        }

    })
    // calculateTotalBreakdown(state)
}

export function setDescountMemberDirect(state,uuid) {
    if (!!uuid) {
        let product = state.cart.items.find(pr => pr.uuid == uuid)
        const memberDiscount = state.cart.membershipsDiscount.find(item => item.categoryId == product.idCategory)
        if( uuid==product.uuid && product.categoryName!="ReservasOwners" ) product.isBenefitDescout = false

        if( memberDiscount != null && product.isBenefitDescout==false ){
            product.membershipDiscount = true
            state.listMemberBenefitUsed.push(product)

            aplicateDescountBenefit(state, product.uuid, memberDiscount?.discount, "Descuento de Membresía", '')
        }
    }
}

export function aplicateDescountBenefit(state, uuid, desc, name,benefitProduct) {
    let productPromo = state.cart.items.find(item => item.uuid === uuid)

    let descuentoAplicar = desc
    if (descuentoAplicar==0){
        descuentoAplicar=100
    }

    productPromo.discountApplied = descuentoAplicar
    // productPromo.salepriceWithDiscount = structuredClone(productPromo.total)
    productPromo.typeDiscount = 3 //"Beneficio"
    if(!!benefitProduct){
        productPromo.isBenefitDescout=true
    }

    productPromo.promoName = name
    productPromo.benefitProduct = benefitProduct
    productPromo.contracts =state.cart.contractId
}

export function aplicateDescountBenefitReserv(state, data) {
    const { benefitsNights, desc, name,uuid } = data
    let productPromo = state.cart.items.find(item => item.uuid === uuid)

    const descuentoAplicar = desc
    productPromo.discountApplied = descuentoAplicar
    const decimalDiscount = parseFloat(descuentoAplicar) / 100;
    const descuento = productPromo.originalSalePrice * decimalDiscount;
    productPromo.salepriceWithDiscount = (Math.round(productPromo.originalSalePrice * 100) / 100).toFixed(2) - descuento;
    productPromo.typeDiscount = 3 //"Beneficio"

    productPromo.isBenefitDescout=true

    productPromo.benefitsNights=benefitsNights
    productPromo.promoName = name
    productPromo.benefitProduct = benefitsNights?.benefit
    productPromo.contracts =benefitsNights?.contract


}
export function setNameClient(state, nameCliente) {
    state.cart.cliente.name = nameCliente.trim()
}

//para guardar el nombre del cliente del cart
export function setNameAgente(state, nameAgent) {
    state.cart.cliente.customeragent = nameAgent.trim()
}
//para indicar el hotel del cliente
export function setHotelCliente(state, hotel) {
    state.cart.cliente.hotel = hotel
    setIfRequiredFieldsFilled(state)
}
//
export function setLanguageCliente(state, language) {
    state.cart.cliente.language = language
    setIfRequiredFieldsFilled(state)
}

//para indicar el hotel del cliente
export function setSihotCodeHotel(state, sihotCode) {
    state.cart.cliente.hotelsihotcode = sihotCode
}
//para guardar el tipo del cliente del cart
export function setTipoCliente(state, tipoCliente) {
    state.cart.cliente.customertype = tipoCliente
}

//para guardar los apellidos del cliente del cart
export function setLastNameClient(state, lastNameCliente) {
    state.cart.cliente.lastname = lastNameCliente
    state.cart.cliente.lastnameSearch = lastNameCliente

}
//para guardar el email del cliente del cart
export function setEmailCliente(state, emailCliente) {
    state.cart.cliente.email = emailCliente.trim()
}
//para guardar el email del cliente del cart
export function unsetEmailCliente(state, emailCliente) {
    state.cart.cliente.email = emailCliente
}

//para guardar el telefono del cliente del cart
export function setPhoneCliente(state, phoneCliente) {
    state.cart.cliente.phone = phoneCliente.trim()
}

export function clearHeadersCliente(state) {
    state.cart.cliente.id = null
    state.cart.cliente.name = ''
    state.cart.cliente.lastname = ''
    state.cart.cliente.phone = ''
    state.cart.cliente.email = ''
    state.cart.cliente.credit = 0;
    state.cart.cliente.resortCredit = 0;
    state.cart.membershipsDiscount=[];
    state.listMemberBenefitUsed=[]
    state.cart.benefits=[];
    
    // state.cart.items.forEach((product) => {
    //     aplicateDescountReset(state, product.id)
    // })
}

export function resetAllBenefitsProductsDiscounts(state) {
    state.cart.items.forEach((product) => {
        if (product.benefitDiscount){
            deleteBenefit(state, product.uuid)
            aplicateDescountResetUuid(state, product.uuid)
        }
        if (product.membershipDiscount) {
            deleteBenefitMember(state, product.uuid)
            aplicateDescountResetUuid(state, product.uuid)
        }
    })
}

export function clearOwnerInfo(state) {
    state.cart.contractId= null
    state.cart.membershipsDiscount= null
    state.cart.benefits = []
}

//para guardar la forma de pago del cliente en el cart
export function setPaymentMethodClient(state, paymentMethodyCliente) {
    state.cart.cliente.paymentmethod = paymentMethodyCliente
}

//para asignar la forma de pago del cliente en el cart
export function addPaymentClientList(state, paymentmethod) {
    paymentmethod.sort(function (a, b) {
        if (a.order < b.order) return 1
        if (a.order > b.order) return -1
        // a must be equal to b
        return 0;
    })
    paymentmethod.forEach((pm, index) => {
        if (index == 0) {
            state.cart.cliente.paymentmethod = pm?.id
            state.cart.cliente.paymentamount = pm?.total
            state.cart.cliente.paymentreference = pm?.reference
            state.cart.cliente.paymentcard = pm?.cc_bank || ''
            state.cart.cliente.paymentapproval = pm?.auth_bank || ''
            state.cart.cliente.paymentcardtype = pm?.cc_type_bank || ''
        } else {
            state.cart.cliente.paymentmethod2 = pm?.id
            state.cart.cliente.paymentamount2 = pm?.total
            state.cart.cliente.paymentreference2 = pm?.reference
            state.cart.cliente.paymentcard2 = pm?.cc_bank || ''
            state.cart.cliente.paymentapproval2 = pm?.auth_bank || ''
            state.cart.cliente.paymentcardtype2 = pm?.cc_type_bank || ''
        }
    });
}


//para guardar la forma de pago del cliente en el cart
export function addPaymentClient(state, paymentmethod) {
    const {id, name, amount, reference, order} = paymentmethod
    const {cc_bank, auth_bank, cc_type_bank} = state.paymentFormat

    if (paymentmethod.id == 7) state.cart.cliente.paymentList.push({
            id, name, total: amount, reference, order,
            cc_bank, auth_bank, cc_type_bank
        })
    else state.cart.cliente.paymentList.push({id, name, total: amount, reference, order})
}



//para guardar la formas de pago del cliente en el cart
export function deletePaymentMethodClient(state, idformatPay) {
    if (idformatPay?.credittouse && idformatPay?.credittouse != '0') {
        let saldoCr = 0
        let creditTotal = state.cart.cliente.paymentList.find(item => item.id == idformatPay.id)


        switch (idformatPay?.credittouse) {
            case "1":
                if (parseFloat(creditTotal?.total) <= parseFloat(state.cart.cliente.creditBalance)) {
                    state.cart.cliente.creditBalance = parseFloat(state.cart.cliente.creditBalance) + parseFloat(creditTotal?.total)
                } else {
                    saldoCr = parseFloat(state.cart.cliente.credit) - parseFloat(state.cart.cliente.creditBalance)
                    state.cart.cliente.creditBalance = parseFloat(state.cart.cliente.credit)
                }
                break;
            case "2":
                if (parseFloat(creditTotal?.total) <= parseFloat(state.cart.cliente.resortCreditBalance)) {
                    state.cart.cliente.resortCreditBalance = (parseFloat(state.cart.cliente.resortCreditBalance) + parseFloat(creditTotal?.total)).toFixed(2)
                } else {
                    saldoCr = parseFloat(state.cart.cliente.resortCreditBalance) - parseFloat(state.cart.cliente.resortCreditBalance)
                    state.cart.cliente.resortCreditBalance = parseFloat(state.cart.cliente.resortCreditBalance)
                }
                break;
            default:

        }
        // state.cart.cliente.creditBalance=parseFloat(state.cart.cliente.creditBalance) + parseFloat(creditTotal.total)
    }
    state.cart.cliente.paymentList = idformatPay ? state.cart.cliente.paymentList.filter(item => item.id != idformatPay.id) : []

    state.cart.cliente.paymentmethod = ''
    state.cart.cliente.paymentamount = ''
    state.cart.cliente.paymentreference = ''
    state.cart.cliente.paymentcard = ''
    state.cart.cliente.paymentapproval = ''
    state.cart.cliente.paymentcardtype = ''
    state.paymentFormat.cc_bank = ''
    state.paymentFormat.auth_bank = ''
    state.paymentFormat.cc_type_bank = ''
    delete state.paymentFormat.cc_bank
    delete state.paymentFormat.auth_bank
    delete state.paymentFormat.cc_type_bank

    state.cart.cliente.paymentmethod2 = ''
    state.cart.cliente.paymentamount2 = 0
    state.cart.cliente.paymentreference2 = ''
    state.cart.cliente.paymentcard2 = ''
    state.cart.cliente.paymentapproval2 = ''
    state.cart.cliente.paymentcardtype2 = ''
}

export function setIdPaymentFormat(state){
    const hasManyPaymentMethods = state.cart.cliente.paymentList.length > 0
    state.paymentFormat.id = hasManyPaymentMethods ? state.cart.cliente.paymentList[0].id: ''	  
}
//para borar la formas de pago del cliente en el cart
export function setReferenceClient(state, referenceCliente) {
    state.cart.cliente.paymentreference = referenceCliente.trim()
}

export function setPaymentLinkClient(state, paymentLinkCliente) {
    state.cart.cliente.paymentlink = paymentLinkCliente.trim()
}

export function balancePayCredit(state, idformatPay) {
    let creditTotal = state.cart.cliente.paymentList.find(item => item.id == idformatPay.id)
    switch (idformatPay.credittouse) {
        case "1":
            state.cart.cliente.creditBalance =
            parseFloat(state.cart.cliente.credit) -
            parseFloat(creditTotal.total);
            break;
        case "2":
            state.cart.cliente.resortCreditBalance = (
            parseFloat(state.cart.cliente.resortCredit) -
            parseFloat(creditTotal.total)).toFixed(2);
            state.cart.benefitId
            break;
        default:

    }
}

//para poner valor booleano en la propiedad del estado
export function setMakeRoomCharge(state, boolean) {
    state.cart.makeRoomCharge = boolean
}
//booleano checkbox cargo manual en modal
export function unsetMakeRoomCharge(state) {
    state.cart.makeRoomCharge = false
}
//para poner valor booleano en la propiedad applyManualRoomCharge del estado en cliente
export function setManualRoomChargeCliente(state, value) {
    state.cart.cliente.applyManualRoomCharge = value
}
export function unsetManualRoomChargeCliente(state) {
    state.cart.cliente.applyManualRoomCharge = false
}
//se agrega numero de habitación para el cliente
export function setLastNameChargeClient(state, lastname) {
    state.cart.cliente.lastnameSearch = lastname
}
export function unsetLastNameSearch(state) {
    state.cart.cliente.lastnameSearch = ''
}
//se setea los datos de la habitación en guests
export function setDataRoom(state, data) {
    state.guests = data
}
//para setear la reserva y habitacion del cliente
export function setReservaRoomCharge(state, data) {
    state.cart.numreserva = data.numReserva
    state.cart.room = data.room
    state.cart.cliente.paymentreference = data.numReserva
    state.cart.cliente.accountid = data.accountid
}
// limpio valores asociados al hacer busqueda por apellido en modal cargo habitación
export function unsetDataRoom(state) {
    state.guests = []
    state.cart.numreserva = ''
    state.cart.room = ''
    state.cart.cliente.paymentreference = ''
}
//deselecciono valor asociado a habitación
export function unselectDataRoom(state) {
    state.cart.numreserva = ''
    state.cart.room = ''
    state.cart.cliente.paymentreference = ''
}
//para poner valor en la propiedad orderCreated del estado
export function setOrderCreated(state, invoice) {
    state.orderCreated = invoice
}
//limpieza del carrito al terminar la venta
export function setEmptyCart(state) {
    state.cart.benefits = []
    state.cart.cliente.customeragent = ''
    state.cart.cliente.customertype = ''
    state.cart.cliente.email = ''
    state.cart.cliente.hotelsihotcode = ''
    state.cart.cliente.id = null
    state.cart.cliente.isBenefitCredit = false
    state.cart.cliente.lastname = ''
    state.cart.cliente.lastnameSearch = ''
    state.cart.cliente.name = ''
    state.cart.cliente.paymentamount = ''
    state.cart.cliente.paymentamount2 = 0
    state.cart.cliente.paymentapproval = ''
    state.cart.cliente.paymentapproval2 = ''
    state.cart.cliente.paymentcard = ''
    state.cart.cliente.paymentcard2 = ''
    state.cart.cliente.paymentcardtype = ''
    state.cart.cliente.paymentcardtype2 = ''
    state.cart.cliente.paymentlink = ''
    state.cart.cliente.paymentList = []
    state.cart.cliente.paymentmethod = ''
    state.cart.cliente.paymentreference = ''
    state.cart.cliente.phone = ''
    state.cart.contract = []
    state.cart.contractId = ""
    state.cart.firstPay = 0.00;
    state.cart.firstPayTotal = 0.00;
    state.cart.isPackage = false
    state.cart.items = []
    state.cart.listMemberBenefitUsed =[]
    state.cart.membershipsDiscount=[]
    state.cart.currentBenefits=[]
    state.cart.currentContract=null
    state.cart.needAuthorization = false
    state.cart.partial = 0;
    state.cart.totalBreakdown.discountApplied = 0
    state.cart.totalBreakdown.total = 0.00
    state.cart.subtotal = 0.00
    state.cart.totalDiscount = 0.00
    state.listMemberBenefitUsed = []
    state.listBenefitUsed=[]
    state.owners = []
    state.quoted.dataListcontent = ""
    state.quoted.dataListcontentAmenities = ""
    state.quoted.dataListcontentFired = ""
    state.quoted.dataListcontentTerms = ""
    state.quoted.idAmenities = ""
    state.quoted.idFired = ""
    state.quoted.idWelcom = ""
    state.quoted.isQuotes = false
    state.quoted.termsId = ""
    state.cart.cliente.credit = 0
    state.cart.cliente.resortCredit = 0
    state.cart.ownerbookingbenefits = {
        active:  false,
        idconfigurationRoom: '',
        nightsContract: false,
        nightsXtra: false,
        hasBothNights: false
    }

    delete state.cart.cliente.id
    delete state.cart.cliente.hotelsihotcode
    delete state.cart.cliente.hotel
    delete state.cart.cliente.isOwnerBooking
    delete state.cart.cliente.paymentapproval
    delete state.cart.cliente.paymentapproval2
    delete state.cart.cliente.paymentcard
    delete state.cart.cliente.paymentcard2
    delete state.cart.cliente.paymentcardtype
    delete state.cart.cliente.paymentcardtype2
    delete state.orderCreated
}

export function setDataOrder(state, data) {
    state.dataOrderPay = data
}
export function setInclucions(state, data) {
    state.dataListInclucions = data
}

export function setListDescriQuoted(state, data) {
    state.dataListDescriQuoted = data
}

export function setListDescriQuotedFired(state, data) {
    state.dataListDescriQuotedFired = data
}

export function setListDescriTerms(state, data) {
    state.dataListDescriTerms = data
}


export function setListDescriAmenities(state, data) {
    state.dataListDescriAmenities = data
}

export function setValidNull(state) {

    if (state.dataListDescriQuoted.length == 0 || state.dataListDescriQuotedFired.length == 0 || state.dataListDescriTerms.length == 0 || state.dataListDescriAmenities.length == 0) {
        state.validQuoted = true
    } else {
        state.validQuoted = false
    }

}
///mutaciones para validacion de usuario para bookingproductClone
export function setDataUserAuthorization(state, data) {
    const { code, idUser, isInvalid, username } = data
    state.userAuthorizeRate.isInvalid = isInvalid
    state.userAuthorizeRate.idUser = idUser
    state.userAuthorizeRate.username = username
    state.userAuthorizeRate.code = code
}
export function unsetDataUserAuthorization(state) {
    state.userAuthorizeRate.isInvalid = true
    state.userAuthorizeRate.idUser = null
    state.userAuthorizeRate.username = ''
    state.userAuthorizeRate.code = ''
}

export function setUserAuthorizationUrl(state, data) {
    const { url } = data
    state.userAuthorizeRate.urlAuthorization = url
}

export function setDataAuthBookingItem(state, data) {
    const { dateAuthorization, idUserAuthorizeRate, urlAuthorization, requiresAuthorization } = data
    const cart = state.cart.items
    const bookings = cart.filter(b => b.modelType === "booking" && b.requiresAuthorization)
    bookings.forEach((booking) => {
        booking.dateAuthorization = dateAuthorization
        booking.idUserAuthorizeRate = idUserAuthorizeRate
        booking.urlAuthorization = urlAuthorization
        booking.requiresAuthorization = requiresAuthorization
    })
    setIfRequiredFieldsFilled(state)
}

export function isAboutToUpdateCard(state, valid) {
    state.updateDataOrderPay.action = ''
    if (valid == 'firsTime') state.updateDataOrderPay.action = 'createpayment'
    if (valid == 'edit') state.updateDataOrderPay.action = 'updatecard'
    if (valid == 'cancel') {
        state.updateDataOrderPay.action = ''
        delete state.updateDataOrderPay.action
    }
}

export function updateUserTokenCard(state, payload) {
    state.updateDataOrderPay.card = []
    const {cardMonth, cardName, cardNumber, cardNumberNotMask, cardYear} = payload
    const tarjeta = cardNumberNotMask.replace(/ /g, "")
    const año = cardYear.toString().slice(-2)
    const cardType = cardTypeFinder(tarjeta)

    state.updateDataOrderPay.order = state.dataOrderPay.id
    state.updateDataOrderPay.card_type_id = cardType.id
    state.updateDataOrderPay.amount = state.dataOrderPay.firstpay

    state.updateDataOrderPay.card = {
        cardholder_name: cardName,
        card_number: tarjeta,
        expiry_date: cardMonth + año
    }
    if (state.updateDataOrderPay.action === 'updatecard'){
        state.updateDataOrderPay.amount = 1
    }
    state.updateDataOrderPay.valid = true
    // if (cardType.id === 1 || cardType.id === 2 || cardType.id === 3){

    //     state.updateDataOrderPay.order = state.dataOrderPay.id
    //     state.updateDataOrderPay.card_type_id = cardType.id
    //     state.updateDataOrderPay.amount = state.dataOrderPay.firstpay

    //     state.updateDataOrderPay.card = {
    //         cardholder_name: cardName,
    //         card_number: tarjeta,
    //         expiry_date: cardMonth + año
    //     }
    //     if (state.updateDataOrderPay.action === 'updatecard'){
    //         state.updateDataOrderPay.amount = 1
    //     }
    //     state.updateDataOrderPay.valid = true
    // } else {
    //     if (cardType.id == 0) showAlertMessage('Failed to load card', 'BellIcon', `Invalid Card Number.`, 'danger', 5000, 'bottom-right')
    //     else if (!cardType) showAlertMessage('Failed to load card', 'BellIcon', `No Card Number detected.`, 'danger', 5000, 'bottom-right')
    //     else showAlertMessage('Failed to load card', 'BellIcon', `Unsuported Card Number.`, 'warning', 5000, 'bottom-right')
    //     state.updateDataOrderPay.valid = false
    // }
}

export function deleteUserTokenCard(state) {
    state.updateDataOrderPay.card = []
    state.updateDataOrderPay.order = ''
    state.updateDataOrderPay.amount = ''
    state.updateDataOrderPay.card_type_id = ''
    state.updateDataOrderPay.action = ''

    delete state.updateDataOrderPay.card_type_id
    delete state.updateDataOrderPay.action

}

export function setMembershipsDiscount(state, membershipDiscount) {
    state.cart.membershipsDiscount = membershipDiscount

}

export function addFiles(state, files) {
    state.cart.files = files
}

export function deleteFiles(state) {
    state.cart.files = null
}

export function setEpayment(state, valid) {
    state.cart.epayment = valid
}

export function setResortCredit(state, setResortCredit) {
    let resortCredit = setResortCredit.find((benefit)  => benefit.benefit.code == 'RCOH')
    if (!resortCredit){
        resortCredit=0.00
    }else{
        state.cart.benefitId= resortCredit.benefit.benefit
        resortCredit = resortCredit.benefit.balance

    }
    state.cart.cliente.resortCredit =resortCredit

}

export function clearPaymentList(state, list = [] ) {
    state.cart.cliente.paymentList = list
}

//delete benefit
export function deleteBenefit(state, uuid) {
    let item = state.cart.items.find(item => item.uuid === uuid)
    if (!!item){
        state.listBenefitUsed= state.listBenefitUsed.filter(bn => bn.uuid !== item?.uuid)
        if (state.cart.ownerbookingbenefits.active || state.listBenefitUsed.length > 0) state.cart.cliente.paymentList = state.cart.cliente.paymentList?.filter(pm=> pm.id == 1055)
        else state.cart.cliente.paymentList = []
        item.benefitDiscount = false
        // calculateTotalBreakdown(state)
    }
}
export function deleteBenefitMember(state, uuid) {
    let item = state.cart.items.find(item => item.uuid === uuid)
    if (!!item){
        const validDescount= state.listMemberBenefitUsed.filter(bn => bn.uuid !== item?.uuid)
        state.listMemberBenefitUsed= validDescount || []
        if (state.cart.ownerbookingbenefits.active || state.listBenefitUsed.length > 0) state.cart.cliente.paymentList = state.cart.cliente.paymentList?.filter(pm=> pm.id == 1055)
        else state.cart.cliente.paymentList = []
        item.membershipDiscount = false
        // calculateTotalBreakdown(state)
    }
}


export function setResortCreditOne(state, setResortCredit) {
    let resortCredit = state.cart.benefits.find((benefit)  => benefit.benfitcode == 'RCOH')
    if (resortCredit==null) {
        resortCredit = 0.00
    }
    else{
        state.cart.benefitId = resortCredit.benefitId
        resortCredit = resortCredit.balance
    }
    state.cart.cliente.resortCredit =resortCredit

}

export function deleteOwnerBookingCartData(state) {
    state.cart.ownerbookingbenefits = {
        active: false,
        idconfigurationRoom: '',
        nightsContract: false,
        nightsXtra: false,
        hasBothNights: false
    }
    state.needAuthorization = false
}

export function setBenefitSelectedInOwnerBooking(state, benefit) {
    const benefitCode = benefit?.id

    state.cart.ownerbookingbenefits.nightsContract = benefitCode == 'NTC'
    state.cart.ownerbookingbenefits.nightsXtra = benefitCode == 'NTX'
    state.cart.selectedBenefitSelectedInOwnerBooking = benefit

}


