
export default [
    {
        path: '/sales/incidents',
        name: 'incidents',        
        component: () => import(/*webpackChunkName: "IncidentsView"*/'@/modules/incidents/views/Incidents'),        
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
        },      
    }    
]
