export function filteredContracts( state ) {
    let contracts = state.contracts 
    const query = state.filter.queryContracts   
    // con esto filtro los resultado por el name del contract, no es case sensitive
    if( query.length > 1 ){
        contracts = contracts.filter(
            contract => contract.code.toLowerCase().includes( query.toLowerCase() ) || 
                    contract.housingnumber.toString().includes( query ) || 
                    contract.numberContract.includes( query) ||
                    contract.housingcode.toLowerCase().includes( query.toLowerCase() ) ||
                    contract.nameOwner.toLowerCase().includes( query.toLowerCase()) ||
                    contract.membershipcode.toLowerCase().includes( query.toLowerCase())
        )        
    } 
    return contracts
}
