import { v4 as uuidv4 } from 'uuid'

import store from '@/store'
import router from '@/router'

import { find } from 'lodash'
import Vue from 'vue'
import * as moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const handlerErrors = (errorMessage, errorResponse) => {
    let stringError = ''
    const responseStatus = errorResponse.status || false
    const responseStatusText = errorResponse.statusText || false
    const responseStatusDataDetail = errorResponse.data.detail || false

    stringError += '<strong>Error message:</strong> ' + errorMessage + '<br>'

    if (responseStatus) {
        stringError += '<strong>Error code:</strong> ' + responseStatus + '<br>'
    }

    if (responseStatusText) {
        stringError += '<strong>Status text:</strong> ' + responseStatusText + '<br>'
    }

    if (responseStatusDataDetail) {
        stringError += '<strong>Detail:</strong> ' + responseStatusDataDetail + '<br>'
    }

    return stringError
}

const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}
const currentDateMinus = (months) => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + months)).slice(-2)
    // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + '01'
}

const formatDate = (fecha, locale) => {

    const date = new Date(fecha + 'T00:00:00')

    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = date.getMonth()
    const yyy = date.getFullYear()

    const stringEs = dia + '  ' + mesesSpanish[mes] + ' ' + yyy
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}

const formatDateOnly = (fecha, locale) => {

    const date = new Date(fecha)
    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const dia = date.getDate()
    const mes = ("0" + (date.getMonth() + 1)).slice(-2)
    const yyy = date.getFullYear()

    const stringEs = yyy + '-' + mes + '-' + dia
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}
const hoursOnly = (fecha) => {

    const date = new Date(fecha)
    const hora = date.getHours()
    const minutos = date.getMinutes()
    const tiempo = hora + ':' + minutos
    const stringHours = tiempo

    return stringHours
}

function daysMonths(payload) {
    const { year,month,rentalPoolId }=payload
    const dataExpenses= store.state.fivesClubProfit.expenses.incomeRentalPool
    const daysMonth = new Date(year, month, 0).getDate();
    let dailyIncomeDay=""
    let listDays = []
    let forDaysMonts = {forDaysMontAll:[],forDaysMontsGroup:[]}
    let count=1
    let numberCero=""
    let numberCeroMont=""
    if(month<10)numberCeroMont=0
    for (var day = 1; day <= daysMonth; day++) {
        if(day<10) numberCero=0
        else
        numberCero=""
        dailyIncomeDay = dataExpenses !=null ? dataExpenses[day-1].dailyIncome :""

        if(count<=10 ){
            listDays.push({rentalPoolId,dateIncome:''+numberCero+day+'/'+numberCeroMont+month+'/'+year+'',dailyIncome:dailyIncomeDay} )
            if(forDaysMonts.forDaysMontsGroup.length==2){
                forDaysMonts.forDaysMontsGroup.push(listDays)
            }
        }else{
            listDays.push({rentalPoolId,dateIncome:''+numberCero+day+'/'+numberCeroMont+month+'/'+year+'',dailyIncome:dailyIncomeDay} )
            forDaysMonts.forDaysMontsGroup.push(listDays)
            count=0
            listDays = []
        }
        count=count+1

    }
	return forDaysMonts
}


function daysMonthsAll(payload) {

    let forDaysMontAll=[]

    payload.forEach((day) => {
        forDaysMontAll= forDaysMontAll.concat(day);
    })


	return forDaysMontAll

}

const currentDateSecondsAndExpires = () => {
    const date = new Date()
    const seconds = date.getTime() / 1000
    return { currenTime: Math.trunc(seconds), expiresIn: Math.trunc(seconds) + 900 }
}

const sessionHasExpired = (expiration) => {
    const date = new Date()
    const currentSeconds = Math.trunc(date.getTime() / 1000)
    return currentSeconds > expiration ? true : false
}

const updateExpiresTimeUserOrLogout = (expiresTime) => {
    const { currenTime } = currentDateSecondsAndExpires()
    if (!sessionHasExpired(expiresTime)) {
        store.commit('auth/updateExpiresTime', currenTime, { root: true })
        return true
    } else {
        store.commit('auth/logout', {}, { root: true })
        store.commit('auth/setSesionExpired', true, { root: true })
        router.push('/login')
        return false
    }
}

const checkItemsCartSihotCode = (cart) => {
    let resultados = cart.filter(item => item.serviciosihot == null)
    if (resultados.length > 0) {
        store.commit('shop/setMakeRoomCharge', true, { root: true })
        store.commit('shop/setManualRoomChargeCliente', true, { root: true })
        return { ok: true, objetos: resultados }
    } else {
        store.commit('shop/setMakeRoomCharge', false, { root: true })
        store.commit('shop/setManualRoomChargeCliente', false, { root: true })
        return { ok: false, objetos: null }
    }

}

const findItemInSelectedOrder = (order, id) => {
    return order.find(order => order.id === id)
}
const formatBeoProductToSave = (detalles, id, infobeo) => {
    detalles.forEach((detalle) => {
        delete detalle.infobeo
        detalle.ordersdetail = id
        detalle.infobeo = infobeo
        detalle.departamento = detalle.departamentoid,
            delete detalle.departamentoid
        delete detalle.departamentoname
    })
    return detalles
}
const transfersIsValid = (detailOrder) => {
    let valiTranfers = detailOrder.filter(item => item.coupon != '')
    let resultados = valiTranfers.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '')

    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}

const transfersIsValidNull = (detail) => {
    const transfersIsInvalid =
        detail?.airline === '' ||
        detail?.pickup === '' ||
        detail?.flight === '' ||
        detail?.flighttime === '' ||
        detail?.ito === '' ||
        detail?.ifrom === ''

    const isOpenService = detail?.trip === 'Open Service'

    if (transfersIsInvalid) {
        if (isOpenService) return false
        else return true
    }
    else return false

}

const yearsMinMax = () => {
    const yearMin = moment().subtract(5, 'years').year()
    const yearMax = moment().add(5, 'years').year()
    return { yearMin, yearMax }
}

const stringAleatorio = () => {
    return uuidv4()
}
const validUserLogin = () => {
    const isLoggedUser = store.state.auth.isLogged
    const hasSalesChannel = store.state.shop.canalventa
    const hasPointOfSales = store.state.shop.puntoventa
    if (isLoggedUser && hasSalesChannel && hasPointOfSales) {
        return true
    } else {
        return false
    }
}



const validateDataInCart = (cart) => {
    const itemFind = find(cart, { requiredFieldsFilled: false })
    if (itemFind) {
        Vue.$toast({
            component: ToastificationContent,
            props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: `💡 Existe por lo menos algún elemento sin completar sus datos, asegurese de completar esa información.`,
                variant: 'danger',
            },
        }, {
            position: 'bottom-right',
            timeout: 4000,
        })
        return true
    } else {
        return false
    }
}

const camposRequeridosPorLLenar = (product) => {
    let camposFaltantes = []
    const { categoryName, subcategoryName,itinerario,upgradesDetail, operationdate, NamePrefix, GivenName, Surname, PhoneNumber, Email, term, typeDiscount, isIncludenIn, requiresAuthorization, guests,isBenefit } = product
    if (categoryName == "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Fecha de operación" ,isBenefit})
        }
        if (!itinerario.ifrom || itinerario.ifrom == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Origen" })
        }
        if (!itinerario.ito || itinerario.ito == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Destino" })
        }
        if (!itinerario.pax || itinerario.pax == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Pax" })
        }
        if (!itinerario.trip || itinerario.trip == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Tipo de servicio" })
        }
    }

    if (categoryName == "Reservas" || categoryName == "ReservasOwners" ) {
        if (!NamePrefix || NamePrefix == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Prefijo" })
        }
        if (!GivenName || GivenName == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Nombre" })

        }
        if (!Surname || Surname == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Apellido" })
        }
        if (!PhoneNumber || PhoneNumber == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Número de télefono" })
        }
        if (!Email || Email == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Email" })
        }
        if (!term || term == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Termino y condición" })
        }
        if (requiresAuthorization) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Falta autorizar el booking" })
        }
        if (guests) {
            const required = camposRequeridosDeBookingPorLLenar(product)
            if (required && required.camposFaltantes.length > 0) required.camposFaltantes.forEach(field => {
                const guest = {
                    name: field.guest,
                    campos: field.campos
                }
                camposFaltantes.push({ key: stringAleatorio(), campo: guest })
            })
        }
    }

    if (categoryName != "Transfer" && categoryName != "Reservas" &&  categoryName != "ReservasOwners") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Fecha de operación" })
        }
    }
    if (typeDiscount == 5 && !isIncludenIn) { // 'Incluido en el paquete'
        camposFaltantes.push({ key: stringAleatorio(), campo: "Reserva donde se incluye" })
    }


    if (subcategoryName == "Room Upgrades" ) {

        if (!upgradesDetail.idHotel || upgradesDetail.idHotel == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Hotel" })
        }
   
        if (!upgradesDetail.roomsTypesOriginName || upgradesDetail.roomsTypesOriginName == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Categoria de origen " })

        }
        if (!upgradesDetail.roomsTypesDestinationName || upgradesDetail.roomsTypesDestinationName == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Categoria de destino" })
        }
        if (!upgradesDetail.rangeDate || upgradesDetail.rangeDate == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Fecha incio - Fecha final" })
        }
        
    
    }
      if (subcategoryName == "Plan Upgrades" ) {

        if (!upgradesDetail.idHotel || upgradesDetail.idHotel == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Hotel" })
        }
        if (!upgradesDetail.mealPlanNameOriginName || upgradesDetail.mealPlanNameOriginName  == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Categoria de origen" })
        }
        if (!upgradesDetail.mealPlanNameDestinationName || upgradesDetail.mealPlanNameDestinationName == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Categoria de detino" })
        }
    
        if (!upgradesDetail.rangeDate || upgradesDetail.rangeDate == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Fecha incio - Fecha final" })
        }
        
    
    }
    
    return camposFaltantes
}

const camposRequeridosDeBookingPorLLenar = (booking) => {
   
    let camposFaltantes = []
    let specialMessage = []
    let Allfilled = true
    const {guests, requiresAuthorization, term, categoryName} = booking
    const occupantOwner = (categoryName == "ReservasOwners" && !booking.occupanttype)

    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    let isValidEmail = true
    const occupantType = booking.occupanttype ? parseInt( booking.occupanttype ) : null // si es 1, es propietario principal
    const isNotOwnerPrincipal = occupantType && occupantType !== 1


    // tipo de ocupante
    const guestsArray = [...guests.adults, ...guests.childs ]

    guestsArray.forEach((guest) => {
        const { NamePrefix, GivenName, Surname, PhoneNumber, Email, mainGuest } = guest

        const isMainGuest = guest.mainGuest ? guest.mainGuest === true  : false
        //sí huesped es mainGuest = true, no es el propietario principal y email viene con valor procedo a validar el email ingresado
        if( isMainGuest && isNotOwnerPrincipal && Email ){ 
            isValidEmail = regexEmail.test(Email)
        }

        const camposFaltantesInvitados = []

        camposFaltantesInvitados.push(
            !NamePrefix && mainGuest ? 'NamePrefix' : null,
            !GivenName ? 'GivenName' : null,
            !Surname ? 'Surname' : null,
            !PhoneNumber && mainGuest ? 'PhoneNumber' : null,
            !Email && mainGuest ? 'Email' : null,
            Email && !isValidEmail && mainGuest  ? 'Email Invalido' : null
        )

        guest.camposFaltantes = deleteNulls(camposFaltantesInvitados)
        
        if (guest.camposFaltantes.length > 0) guest.gotAllFilled = false
        else guest.gotAllFilled = true
    })

    const guestRequireFilled = guestsArray.filter(guest => guest.gotAllFilled == false)

    if (guestRequireFilled.length > 0 || !term || requiresAuthorization || occupantOwner) {
        Allfilled = false
        if (guestRequireFilled.length > 0) guestRequireFilled.forEach(field => {
            camposFaltantes.push({key: stringAleatorio(), campos: field.camposFaltantes, guest: field.guestType})
        })
        if (!term) specialMessage.push({ key: stringAleatorio(), msg: "Falta agregar los términos y condiciones" })
        if (requiresAuthorization) specialMessage.push({ key: stringAleatorio(), msg: "La reserva requiere autorización" })
        if (occupantOwner) specialMessage.push({ key: stringAleatorio(), msg: "Seleccione el tipo de ocupación de la reserva" })
        if ( !isValidEmail ) specialMessage.push({ key: stringAleatorio(), msg: "Hay un email ingresado que no es válido." })        
    }

    if (!Allfilled) return {
        camposFaltantes,
        Allfilled,
        message: 'Por favor, llene todas las casillas de los siguientes huéspedes: ',
        specialMessage
    }
    else return null
}

const toDecimal = (stringToDecimal) => {
    return String((Math.round(stringToDecimal * 100) / 100).toFixed(2))
}

const toJson = (stringToJson) => {
    return JSON.parse(JSON.stringify(stringToJson))
}

async function sweetAlert(payload){
    const {title, message, icon, confirmButton, cancelButton, textfield, options, html } = payload
    let config = {
        title: title,
        text: message,
        icon: icon,
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger mr-1'
        },
        buttonsStyling: false,
    }
    if (confirmButton) config.confirmButtonText = confirmButton
    if (cancelButton) {
        config.showCancelButton = true,
        config.cancelButtonText = cancelButton,
        config.reverseButtons = true
    }
    if (html) { // Agrego propiedades html quitando el texto en su lugar
        config.html = html
        config.text = null
        delete config.text
    }

    else if (textfield){ // Lo vuelvo un text input
        config.input = 'text',
        config.inputAttributes = {
            autocapitalize: 'off'
        }
    }
    else if (options){ // lo vuelvo un select (con un arreglo previo)
        config.input = 'select',
        config.inputAttributes = {
            autocapitalize: 'off'
        }
        config.inputPlaceholder = 'Seleccione una opción',
        config.customClass.inputOptions = 'form-control'
        config.inputValidator = (value) => {
            return new Promise((resolve) => {
                if (value != null && value != '') resolve()
                else resolve('Por favor, seleccione una opción')
            })
        }
    }

    const response = await Vue.swal.fire(config)
    const { value: Result, isConfirmed, isDenied, isDismissed, dismiss } = response // regreso todas las respuestas

    return { Result, isConfirmed, isDenied, isDismissed, dismiss }
}

const showAlertMessage = (title = 'Ok', icon = 'BellIcon', text, variant = 'success', timeout = '3000', position = 'bottom-right') => {
    Vue.$toast({
        component: ToastificationContent,
        props: { title, icon, text, variant },
    }, { timeout, position })
}


const addFormatPay = (payInfo, amount) => {
    let formaPagoInfo = {}
    formaPagoInfo = {
        'id': payInfo.id,
        'name': payInfo.name,
        'total': amount

    }
    return formaPagoInfo
}
const consoleMsgFinally = (action, message) => {
    if (process.env.NODE_ENV == 'development') {
        console.log(`[${action}] ${message}`)
    }
}

const typeFilter = (type, stringSearch) => {
    if (type != 'Elija una opción' && stringSearch != '') {
        if (type == 'Email') return {email: stringSearch}
        if (type == 'Teléfono') return {phone: stringSearch}
        if (type == 'Numero POS') return {pos: stringSearch}
        if (type == 'Nombre Cliente Pago') return {name: stringSearch}
        if (type == 'Nombre Titular Reserva') return {owner: stringSearch}
        if (type == 'Nombre Beneficiario Transfer') return {transBeneficiary: stringSearch}
        if (type == 'Nombre Beneficiario Servicios') return {serviceBeneficiary: stringSearch}
        if (type == 'Nombre Beneficiario') return {beneficiary: stringSearch}
    }
}

const makeParamsFilterMySales = (data) => {
    const { email, phone, pos, name, owner, transBeneficiary, serviceBeneficiary, beneficiary, idUser, canalVenta, tipoFecha, inicio, fin } = data
    if (email || phone || pos || name || owner || beneficiary || transBeneficiary || serviceBeneficiary) {
        const payload = {
            idu: idUser,
            idc: canalVenta,
            email,
            phone,
            pos: pos,
            name,
            owner,
            beneficiary,
            transBeneficiary,
            serviceBeneficiary
        }
        if (!email) delete payload.email
        if (!phone) delete payload.phone
        if (!pos) delete payload.pos
        if (!name) delete payload.name
        if (!owner) delete payload.owner
        if (!beneficiary) delete payload.beneficiary
        if (!transBeneficiary) delete payload.transBeneficiary
        if (!serviceBeneficiary) delete payload.serviceBeneficiary
        return payload
    }

    return {
        idu: idUser,
        fini: inicio,
        ffin: fin,
        idc: canalVenta,
        tfecha: tipoFecha,
    }
}

const makeParamsFilterDataOperations = (data) => {
    const { fechaInicio, fechaFin, hotel, categoria, subcategoria, tipo } = data
    const payload = {
        fini: fechaInicio,
        ffin: fechaFin,
        idhotel: hotel,
        idcat: categoria,
        idsubcat: subcategoria,
        tipo: tipo
    }

    if (hotel === 0) delete payload.idhotel
    if (categoria === 0) delete payload.idcat
    if (!tipo) delete payload.tipo
    if (!subcategoria) delete payload.idsubcat

    return payload

}

const makeParamsFilterUpdateDataOperation = (data) => {
    const { tipo, order, status, code, idUser } = data
    const payload = {
        orderdetail: order,
        status,
        code,
        idUser,
    }

    if (tipo == 'operar' || tipo == 'noShow'){
        payload.code = ''
        delete payload.reference
    }
    if (tipo == 'autorizacion'){
        payload.status = ''
        delete payload.status
    }

    return payload

}

function responseMsgUpdate(tipo) {
    switch (tipo) {
        case 'operar':
            return 'Estado se ha marcado como Operado'
        case 'noShow':
            return 'Estado se ha marcado como No show'
        case 'autorizacion':
            return 'Se ha actualizado la autorizacion'
        default:
            console.log('Lo lamentamos');
    }
}

const makeArrayCategories = ( categories ) => {

    let hotelServices = []
    let hotelServicesWithSubCategories = []
    let onlyCategories = []
    let withSubCategories = []
    let categoryServices = [
        { section: 'Services', action: 'get-events', text: 'Eventos', value: 'e634becc-7d3e-11ec-90d6-0242ac120003' },
        { section: 'Services', action: 'get-packages', text: 'Paquetes', value: 'f20a5f0e-7d3e-11ec-90d6-0242ac120003' },
    ]

    categories.forEach((category) => {
        
        const { section, id, name } = category
        if(name=="Transfer"){
            category.action = 'get-products-by-salesCore'
        }else{
            category.action = 'get-products-by-category'
        }
      
        category.text = name
        category.value = id
        delete category.id
        delete category.name
        if (section == 'Servicios Hoteleros') {
            if(category?.subcategory?.length){
                category?.subcategory?.sort((a, b) => a.text > b.text ? 1 : -1)
                category.uuid = stringAleatorio()
                category.action = 'get-products-by-sub-category'
                hotelServicesWithSubCategories.push(category)
            } else hotelServices.push(category)
            hotelServices.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente
        }

        if(category.subcategory?.length && section != 'Servicios Hoteleros'){
            category?.subcategory?.sort((a, b) => a.text > b.text ? 1 : -1)
            category.action = 'get-products-by-sub-category'
            category.uuid = stringAleatorio()
            withSubCategories.push( category )
            withSubCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente
        }

        if(!category.subcategory.length && section == 'Categorías'){
            category.action = 'get-products-by-category'
            onlyCategories.push( category )
            onlyCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente
        }
    })
    const bestSeller = { section: 'Categorías', action: 'get-products-best-seller', text: 'Best Seller', value: 'BestSeller-01' }
    onlyCategories.unshift(bestSeller)
    return { hotelServices, hotelServicesWithSubCategories, onlyCategories, withSubCategories, categoryServices }
}


const formatListProducts = (experiences, valorCategory) => {
    let items = []

    experiences.forEach((experience ) => {
        experience.id=experience.experienceId  ? experience.experienceId : experience.id
        experience.modelType = 'product'
        experience.metatag =experience.metatags
        experience.valueFromCategory = valorCategory
        experience.categoryName = experience.category.name || ''
        experience.subcategoryName = experience.subcategory?.name || ''
        experience.idCategory = experience.category.id || ''
        experience.coverImage=  experience.coverImage? experience.coverImage :  experience.coverImageUrl
        experience.total = '0.00'
        experience.keyRate = null
        experience.usabeo = experience.usabeo || experience.useBeo  || false
        
        delete experience.gallery
        delete experience.videolink
        delete experience.useBeo // todo se va en usabeo
        delete experience.weblink
        delete experience.type
        delete experience.experienceinfo
        delete experience.detailId
        delete experience.experienceavailability
        delete experience.rulesProduct
        if( experience.detail ){
            experience.detail.forEach( detail => {
                detail.descmax = detail.descmax || 0
                detail.qty = 0
                detail.subTotal = 0
                detail.allowExtraPax = detail.extrapax ? detail.extrapax : false
                detail.servicesihot = detail.servicesihot || null
                delete detail.seccionventaid
                delete detail.extrapax
                delete detail.maxDiscount
            })
          
        }


        if( experience.rates ){
            experience.name=experience.displayName
            experience.metatag =experience.metatags || experience.displayName
            let queryParams =store.state.start.queryParams
            let sum=0
            experience.modelType = 'rates'
            experience.rates.forEach( (rate,index) => {
              
                rate.detaildisplay= experience.name 
                rate.id=experience.experienceContractRateId
                rate.saleprice= rate.amount,
                rate.qty=0
                rate.subTotal=0
                rate.allowExtraPax= false
                rate.descmax = experience.maxDiscount || 0
                rate.isBenefit = experience.isBenefit || false

                rate.servicesihot = experience.servicesihot || rate.servicesihot || null

                switch (rate.type) {
                    case 1:
                        rate.typeDisplay = 'Adults'
                        rate.qty = queryParams.adults
                        break;
                    case 2: // Value of foo matches this criteria; execution starts from here
                        rate.typeDisplay ='Children'
                        rate.qty = queryParams.children || 0
                        break;
                    case 3:
                        rate.typeDisplay = 'Pax'
                        rate.qty = rate.num
                        break;
                    }
                    rate.subTotal = rate.total
                    
                    sum = rate.type === 3 ? experience.total : sum + rate.total
            })

            experience.total = sum
            experience.detail = experience.rates
        
        }
        
        delete experience.maxDiscount 
        delete experience.servicesihot 

       
        items.push(experience)
    })
 
    return items
}

const formatListPackage = (packages, valorCategory) => {
    packages.forEach((product) => {
        product.modelType = 'package'
        product.show=false
        product.valueFromCategory = valorCategory
        product.total = '0.00'
        product.categoryName = 'Packages'
        product.detail = []
        product.isProductPackage = true
        product.allowExtraPax =  false
        if(product.gallery.length){
            product.gallery.forEach((item) => {
                item.uuid = stringAleatorio()
            })
        }
        if(product.products.length){
            product.products.forEach((item) => {
                item.uuid = stringAleatorio()
            })
        }

        if(product.packageData.length){
            product.packageData.forEach((item) => {
                item.uuid = stringAleatorio()
                item.modelType = 'product'
            })
        }

        product.detail.push({
            descmax: 0,
            detaildisplay: product.name,
            id: product.id,
            saleprice: product.saleprice,
            servicesihot: product.servicesihot,
            qty: 0,
            subTotal: 0,

        })
        delete product.products
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.category
        delete product.productinfo
    })

    return packages
}

const formatListEvents = (events, valorCategory) => {
    events.forEach((product) => {
        const timeInitFormat = product.startTime.slice(0, -3)
        const timeEndFormat = product.endTime.slice(0, -3)
        const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        product.modelType = 'event'
        product.show=false
        product.categoryName = 'Events'
        product.valueFromCategory = valorCategory
        product.isProductPackage = false
        const detalleIngles = product.eventdetail.find(detail => detail.languaje.code == 'ENG')
        const detalleEspañol = product.eventdetail.find(detail => detail.languaje.code == 'SPA')
        let detalleEncontrado = detalleIngles || detalleEspañol
        product.detail = []
        product.detail.push( {
            descmax: detalleEncontrado ? parseInt(detalleEncontrado.descmax) : 0,
            detaildisplay: product.name,
            qty: 0,
            subTotal: 0,
            id: detalleEncontrado ? detalleEncontrado.id : product.id,
            saleprice: product.saleprice,
            servicesihot: detalleEncontrado ? detalleEncontrado.servicesihot : null,
            isProductPackage: false,
            description: detalleEncontrado ? detalleEncontrado.description : null,
            terms: detalleEncontrado ? detalleEncontrado.terms : null,
        })
        product.startDateFormat = formatDate(product.startDate, 'en')
        product.endDateFormat = formatDate(product.endDate, 'en')
        product.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
        product.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
        product.currencyCode = product.currency.code
        product.hotelName = product.hotel.name
        product.locationName = product.location.name
        product.description = detalleEncontrado ? detalleEncontrado.description : ''
        delete product.eventdetail
        delete product.gallery
        delete product.currency
        delete product.hotel
        delete product.location
        delete product.category
        delete product.productinfo
    })

    return events
}

function detectMobileDevice() {
    const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i)
    const isTablet = navigator.userAgent.toLowerCase().match(/tablet/i)
    const isAndroid = navigator.userAgent.toLowerCase().match(/android/i)
    const isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i)
    const isiPad = navigator.userAgent.toLowerCase().match(/ipad/i)

    if (isMobile || isTablet || isAndroid || isiPhone || isiPad) return true
    else return false
}

const getCanalDefault = channels => {
    const defaultC = channels.find(canal => canal.canalDefault)
    return defaultC ? defaultC : null
}

function toCurrency(amount, currency = "USD") {
    let local

    if (currency && currency == "MXN") local = 'es-MX'
    else if (!currency || currency == "USD") local = 'en-US'

    const dineros = Intl.NumberFormat(local, {
        style: "currency",
        currency: currency,
        useGrouping: true,
    })

    return dineros.format(amount)
}

const sortalphabetically = (array) => {
    const alphabetical = array.sort((a, b) => a.text > b.text ? 1 : -1)
    return alphabetical
}

const sortalphabeticallyalter = (array) => {
    const alphabetical = array.sort((a, b) => a.text > b.text ? -1 : 1)
    return alphabetical
}

const removeDuplicated = (array, key) => {
    const check = new Set();
    return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
}

function sanitizeString(str){
    str = str.replace(/([^a-zA-Z0-9 ,._-]+)/gi, "")
    return str.trim()
}

const filterFilesByType = (files) => {
    const filteredByType = files.filter( file =>
        file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type == "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        file.type == "application/pdf" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
    )
    if (filteredByType.length == files.length) return filteredByType
    else {
        showAlertMessage( 'Archivos inválidos', 'InfoIcon', 'No puedes subir archivos con un formato diferente a los establecidos: png, jpg, jpeg, docx, pptx, xlsx', 'danger', 4000, 'bottom-right')
        return false
    }
}

const filterFilesBySize = (files, size) => {
    const filteredBySize = files.filter( file => file.size < size)
    if (filteredBySize.length == files.length) return filteredBySize
    else  {
        showAlertMessage( 'Archivos inválidos', 'InfoIcon', `No puedes subir archivos con un tamaño a los establecidos: ${parseFloat(size/(1024 * 1024))}MB`, 'danger', 4000, 'bottom-right')
        return  false
    }
}

const filterFilesByName = (files) => {
    const filteredByName = files.filter( file => file.name == sanitizeString(file.name))
    if (filteredByName.length == files.length) return filteredByName
    else {
        showAlertMessage( 'Archivos inválidos', 'InfoIcon', 'No puedes subir archivos con un nombre con caracteres especiales', 'warning', 4000, 'bottom-right')
        return false
    }
}


const listYear = () => {
    var d = new Date();
    var n = d.getFullYear()+1;
    var nLast = d.getFullYear()-1;
    var select = []
    for(var i = n; i >= nLast; i--) {
        select.push({"id":i})
    }
    return select
}

const validYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear - 2; i < currentYear + 1; i++) {
      years.push(i + 1);
    }

    return years;
}

const toTitleCase = (str) => {
    const valid = typeof str
    if (valid == 'string') return str?.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        }
    )
    else {
        console.warn(`[toTitleCase]: ${str} no es un valor de tipo string`);
        return str
    }
}

const formatProducList = (items) => {
    var select = []
    if(items.length>0) {
        var select = []
        items.forEach((pr) => {
            if (pr.modelType != 'booking') {
                let year=  moment(pr.operationdate).format("YYYY")
                const { operationdate,uuid, detailId,idCategory } = pr
                select.push({operationdate,idCategory,uuid,detailId,year})
            }
        })

        var hash = {}
        var array = select.filter(function(current){
            let go = current.detailId + current.year
            let exists = !hash[go] || false
            hash[go] = true
            return exists
        })
        select=array
    }

    return select
}

const deleteNulls = (payload) => {
    if ( Array.isArray(payload) ) return payload.filter(Boolean)
    else Object.keys(payload).forEach(key => {
      if (payload[key] === null) delete payload[key]
    })
    return payload
  }

function numberWithCommas(number) {    
    /**
     para admitir solo números 123456
     números con parte decimal con punto 123456.00
     números separados con comas y parte decimal con punto 123,456.00
    */
    const regex = /^(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/;
    // regex solo Numeros 123456
    const regexOnlyNumber = /^\d+$/;
    // regex de numero separados en comas los miles 123,456
    const regexCommas = /^(\d{1,3}(,\d{3})*|\d+)$/;
    //regex parte decimal con punto 123456.00
    const regexDecimal = /^-?\d+\.\d+$/;
    
    // si el regex no se cumple retorno vacio
    if( !regex.test(number) ){
        return ''
    }
    
    if( regex.test(number) ){
        //si viene solo entero 467740 -> 467,740.00
        if( regexOnlyNumber.test(number) ){ 
            const numero = parseInt(number)            
            return numero.toLocaleString('en-US', {minimumFractionDigits: 2});
        }
        //si el entero ya viene separado en comas, le agrega la parte decimal 467,740 -> 467,740.00
        if( regexCommas.test(number) ){                     
            return `${number}.00`;
        }
        //si viene algo como esto 467740.12719 -> 467,740.12719
        if( regexDecimal.test(number) ){                     
            const numero = parseFloat(number);
            const formatter = new Intl.NumberFormat('en-US', {
                style: "decimal",
                useGrouping: true,
                minimumFractionDigits: 0,
                maximumFractionDigits: 20,
            });
            return formatter.format(numero)
        }
          
        return number // si ya viene así 467,740.12719 no se hace nada, ya es válido
    }
   
}

const formatDateSpecific = (payload) => {
    const {date, language, format, toFormat} = payload
    const languageName = language && language == 'English' ? 'en' : 'es' // el usuario conectado trae un idioma

    const toDate = format ? moment(date, format) : moment(date)
    const formatDate = toFormat || 'ddd'+`${languageName == 'es' ? ' ' : ', '}`+'DD'+'/'+'MM'+'/'+'YYYY'

    return toDate.locale(languageName).format(formatDate)
}

const getLocalFilePath = (file) => {
    const url = URL.createObjectURL(file);
    URL.revokeObjectURL(file) // free memory
    return url
}
const formatTime = (payload = {time, language, format, toFormat}) => {
    const {time, language, format, toFormat} = payload
    const languageName = language === 'English' ? 'en' : 'es' // el usuario conectado trae un idioma

    const toDate = format ? moment(time, format) : moment(time)
    const formatDate = toFormat || "hh:mm A"

    return toDate.locale(languageName).format(formatDate)
}

const splitTime =(time) => {
    if (time) {
        const hasSeason = time.match(/am/g) || time.match(/pm/g)
        const hasSeparator = time.match(/[:]/g)
        if (hasSeason?.length){
            time = time.replace(/[a-zA-Z]/g, '')
            if (!hasSeparator) {
                time = time.match(/..?/g)
                time = formatTime({time: `${time[0]}:${time[1]} `, format:"HH:mm:ss"})
            } else time = formatTime({time: `${time} ${hasSeason[0]}`, format:"hh:mm A"})
            return time
        }
        if(hasSeparator){
            time = formatTime({time: `${time}:00`, format:"HH:mm:ss"})
            return time
        }
        else {
            time = time.match(/..?/g)
            time = formatTime({time: `${time[0]}:${time[1]}:00`, format:"HH:mm:ss"})
            return time
        }
    }
}

const makeTime = async (time = 600) => {
    const myPromise = await new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('making time')
        resolve()
      }, time);
    })
    return myPromise
  }

const isANumber = (string) => {
    return string ? !isNaN( string ) && string > 0 : false
}

export {
    listYear,
    validUserLogin,
    handlerErrors,
    currentDate,
    formatDate,
    formatDateOnly,
    formatDateSpecific,
    hoursOnly,
    currentDateSecondsAndExpires,
    sessionHasExpired,
    updateExpiresTimeUserOrLogout,
    validateDataInCart,
    addFormatPay,
    findItemInSelectedOrder,
    formatBeoProductToSave,
    transfersIsValid,
    transfersIsValidNull,
    checkItemsCartSihotCode,
    yearsMinMax,
    stringAleatorio,
    camposRequeridosPorLLenar,
    camposRequeridosDeBookingPorLLenar,
    toDecimal,
    toJson,
    showAlertMessage,
    sweetAlert,
    consoleMsgFinally,
    typeFilter,
    makeParamsFilterMySales,
    makeParamsFilterDataOperations,
    makeParamsFilterUpdateDataOperation,
    responseMsgUpdate,
    makeArrayCategories,
    formatListProducts,
    formatListPackage,
    formatListEvents,
    detectMobileDevice,
    toCurrency,
    sortalphabetically,
    sortalphabeticallyalter,
    removeDuplicated,
    getCanalDefault,
    sanitizeString,
    filterFilesByType,
    filterFilesBySize,
    filterFilesByName,
    toTitleCase,
    currentDateMinus,
    formatProducList,
    deleteNulls,
    daysMonths,
    numberWithCommas,
    daysMonthsAll,
    getLocalFilePath,
    formatTime,
    splitTime,
    makeTime,
    validYears,
    isANumber
}