export default {
    costs: [],
    fees:[],
    feesType:[],
    expenses: [],
    costsAreBlocked:null,
    performanceIsBlocked:null,
    rentalPoolFilter:[],
    blockingDataProfit:null,
    blockingDataAnualProfit:null,
    selectedFees:null,
    performance: [],
    performanceConcept: [],
    benefitsMonetizationConfig: [],
    roomsSubTypes: [],
    catalogPerformanceConcepts: [],
    filter: {
        queryConsts: ''
        ,queryFees:'',
        queryPerformance: ''
    },
   
}