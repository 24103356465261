import { toCurrency } from '@/helpers/helpers'

export function setLoadingProcess( state, bool) {
    state.isloadingProcess = bool
}
//para altera el estado, setear ordenes
export function setOrdersOperation(state, ordersOperation) {
    ordersOperation.forEach( item => {
        item.total = toCurrency(parseFloat(item.total) * parseFloat(item.currencyExchangeRate)) + ' ' + item.currencyCode
        item.unitPrice = toCurrency(parseFloat(item.unitPrice) * parseFloat(item.currencyExchangeRate)) + ' ' + item.currencyCode
    })
    state.ordersOperation = ordersOperation
}

export function setOrderDetail(state, detail) {
    state.orderDetail = detail
}

export function setOrderOperationSelected(state, orderselected) {
    state.selectedOrderOperation = orderselected
}

export function setSuppliers(state, suppliers) {
    state.suppliers = suppliers
}

export function setOperators(state, operators) {
    state.operators = operators
}
export function unsetOperators(state) {
    state.operators = null
}

//start module state Categories
export function setCategories(state, categories) {
    state.categories = categories
}

export function setSupplierOrderOperation(state, supplier) {
    state.selectedOrderOperation.supplier = supplier
}

export function setOperatorOrderOperation(state, operator) {
    state.selectedOrderOperation.operator = operator
}

export function updateInfoValid(state, update) {
    state.updateStatus = update
}


//operations module state errors, orderDetail itinerario
export function setErrorOrdersOperation(state, errorMessage){
    state.errors.ordersOperation.error = true
    state.errors.ordersOperation.message = errorMessage    
}
export function unsetErroOrdersOperation(state) {
    state.errors.ordersOperation.error = false
    state.errors.ordersOperation.message = ''
}

//operations module state errors, updateDataOperation
export function setErrorUpdateDataOperation(state, errorMessage){
    state.errors.updateDataOperation.error = true
    state.errors.updateDataOperation.message = errorMessage    
}
export function unsetErrorUpdateDataOperation(state) {
    state.errors.updateDataOperation.error = false
    state.errors.updateDataOperation.message = ''
}

//operations module state errors, orderDetail itinerario
export function setErrorUpdateItineraryOrdersOperation(state, errorMessage){
    state.errors.itineraryOrdersOperation.error = true
    state.errors.itineraryOrdersOperation.message = errorMessage    
}
export function unsetErrorUpdateItineraryOrdersOperation(state) {
    state.errors.itineraryOrdersOperation.error = false
    state.errors.itineraryOrdersOperation.message = ''
}

//operations module state errors, confirm detail
export function setErrorConfirmDetailOrder(state, errorMessage){
    state.errors.confirmDetailOrder.error = true
    state.errors.confirmDetailOrder.message = errorMessage    
}
export function unsetErrorConfirmDetailOrder(state) {
    state.errors.confirmDetailOrder.error = false
    state.errors.confirmDetailOrder.message = ''
}

//operations module state errors, fetchSuppliers
export function setErrorFetchSuppliers(state, errorMessage){
    state.errors.fetchSuppliers.error = true
    state.errors.fetchSuppliers.message = errorMessage    
}
export function unsetErrorFetchSuppliers(state) {
    state.errors.fetchSuppliers.error = false
    state.errors.fetchSuppliers.message = ''
}

//operations module state errors, fetchOperatorsBySupplier
export function setErrorFetchOperatorsBySupplier(state, errorMessage){
    state.errors.fetchOperatorsBySupplier.error = true
    state.errors.fetchOperatorsBySupplier.message = errorMessage    
}
export function unsetErrorFetchOperatorsBySupplier(state) {
    state.errors.fetchOperatorsBySupplier.error = false
    state.errors.fetchOperatorsBySupplier.message = ''
}

//operations module state errors, updateSupplierOperator
export function setErrorUpdateSupplierOperatorstate(state, errorMessage){
    state.errors.updateSupplierOperator.error = true
    state.errors.updateSupplierOperator.message = errorMessage    
}
export function unsetErrorUpdateSupplierOperatorstate(state) {
    state.errors.updateSupplierOperator.error = false
    state.errors.updateSupplierOperator.message = ''
}

//operations module state errors, setErrorUpdateItinerary
export function setErrorUpdateItinerary(state, errorMessage){
    state.errors.updateItinerary.error = true
    state.errors.updateItinerary.message = errorMessage    
}
export function unsetErrorUpdateItinerary(state) {
    state.errors.updateItinerary.error = false
    state.errors.updateItinerary.message = ''
}



