export default {
    isloadingProcess: false,
    users:[],
    title:[],
    incType:[],
    descRes:[],
    incident: {
        id: '',
        room: '',
        incidentName: '',
        titleIncdents:'',
        categoryId:'',
        resoluc:'',
        createdby: '',
        createUser:'',
        crateDate: '',
        crateHour: '',
        assigned: '',
        pos: "",
        statusIncident: '',
        summary: '',
        description: '',
        file: '',
        compensation:0
    },
    status: '',
    summary: [],
    commentsFile: null,
    errors: {
        incident: { error: false, message: '' },

    },
}