import Vue from 'vue'

import { consoleMsgFinally } from '../../../helpers/helpers'
import { makeCategoriesFaq, makeQuestionsAnswers } from '../../../helpers/faq'

export async function fetchFaq( context ) {
    
    try {
        context.commit('faq/setIsLoadingQuestions', true, { root: true } )        
        const response = await Vue.axios({
            url: '/faq/'
        })
        const payload = response && response.data 
        const categories = makeCategoriesFaq( payload )
        const questions = makeQuestionsAnswers( payload )
        context.commit('faq/setCategories', categories, { root: true } )
        context.commit('faq/setQuestions', questions, { root: true } )
        context.commit('faq/setCategorySelected', categories[0], { root: true } )
                   
    } catch (error) {
        console.log(error)
    } finally {
        context.commit('faq/setIsLoadingQuestions', false, { root: true } )
        consoleMsgFinally('faq/fetchFaq', 'La petición para los datos del faq terminó')
    }
}
