export function setLoadingProcess(state, bool) {
    state.isloadingProcess = bool
}

export function deleteFile(state, file) {
    state.incident.file = state.incident.file.filter(item => item.name != file.name)


}
export function addFileIncident(state, file) {
    state.incident.file = file

}
export function addcommentsFile(state, file) {
    state.commentsFile = file

}

//start module state status
export function setStatusIncident(state, status) {

    state.status = status
}

export function setErrorStatusIncident(state) {
    state.status = ''
    state.errors.incident.message = ''
}

export function setIncident(state, data) {
    console.log(data)
    state.incident.id = data.id
    state.incident.room = ''
    state.incident.resoluc = data.closeIncidents
    state.incident.categoryId = data.typeIncidents 
    state.incident.incidentName = data.title
    state.incident.titleIncdents = data.titleIncdents
    state.incident.createdby = data.createdby
    state.incident.compensation = data.compensation 
    state.incident.crateDate = data.datecreate
    state.incident.crateHour = data.time
    state.incident.assigned = data.assigned
    state.incident.assignedIncident = data.assignedIncident
    state.incident.pos = data.pos
    state.incident.statusIncident = data.status.id
    state.incident.summary = data.summary
    state.incident.description = data.description
    state.incident.file = data.files

}

export function setIncidentClear(state) {
    state.incident.id = ''
    state.incident.room = ''
    state.incident.incidentName = ''
    state.incident.resoluc =  ''
    state.incident.categoryId = ''
    state.incident.createdby = ''
    state.incident.compensation = 0 
    state.incident.crateDate = ''
    state.incident.crateHour = ''
    state.incident.assigned = ''
    state.incident.pos = ''
    state.incident.statusIncident = ''
    state.incident.summary = ''
    state.incident.description = ''
    state.incident.file = ''
    state.summary = []

}

export function setIncidentsdescription(state, data) {
    state.summary = data

}

export function setUsers(state, data) {
    state.users = data
}

export function setTitle(state, data) {
    state.title = data
}

export function setType(state,data){
    state.incType=data
}

export function setDescRes(state,data){
    state.descRes=data
}


export function unsetErrorIncidents(state) {
    state.errors.incident = false
    state.errors.incident = false
    state.errors.message = ''
}