export const optionsSelectCutoff = [
    {
        id: 1,
        type: 1,
        season: 1,
        label: 'Reservas - Alta'
    },
    {
        id: 2,
        type: 1,
        season: 2,
        label: 'Reservas - Baja'
    },
    {
        id: 3,
        type: 2,
        season: 1,
        label: 'Cancelación - Alta'
    },
    {
        id: 4,
        type: 2,
        season: 2,
        label: 'Cancelación - Baja'
    }
]

export const origins = [
    {
        id: 1,
        name: 'Inicio'
    },
    {
        id: 2,
        name: 'Anual'
    },

]


export const idHousing = [
    {
        id: 1,
        name: 'Propietario'
    },
    {
        id: 2,
        name: 'Familiar'
    },
    {
        id: 3,
        name: 'Co-Propietario'
    },
    {
        id: 4,
        name: 'Comunidad de Bienes'
    },
]

export const modalidadPool = [
    {
        id: 1,
        name: 'Pool'
    },
    {
        id: 2,
        name: 'Individual'
    },
]

export const filtroOwners = [
    {
        id: 1,
        value: 'Name',
        name: 'Nombre/Apellido'
    },
    {
        id: 2,
        value: 'EmailOwner',
        name: 'Correo'
    },
    {
        id: 3,
        value: 'Code',
        name: 'Código'
    },
    {
        id: 4,
        value: 'Resort',
        name: 'Resort'
    }
  ]

export const filtroContracts = [
    {
        id: 0,
        value: null,
        name: 'Seleccione '
    },
    {
        id: 1,
        value: 'membership',
        name: 'Membresía'
    },
    {
        id: 2,
        value: 'resort',
        name: 'Resort'
    },
    {
        id: 3,
        value: 'duration',
        name: 'Duración'
    },
    {
        id: 4,
        value: 'idStatus',
        name: 'Status'
    },
    {
        id: 5,
        value: 'email',
        name: 'Por Email'
    },
    {
        id: 6,
        value: 'nameLastName',
        name: 'Por nombre, apellido'
    }
]

export const fileTypes = [
    {
        id: 1,
        value: 'Presentación',
        text: 'Presentación',
    },
    {
        id: 2,
        value: 'Video',
        text: 'Video',
    },
    {
        id: 3,
        value: 'Word',
        text: 'Word',
    },
    {
        id: 4,
        value: 'Pdf',
        text: 'Pdf',
    }
]



export const filtroBooking = [
    {
        id: 1,
        value: 'resort',
        name: 'Resort'
    },

    {
        id: 2,
        value: 'idStatus',
        name: 'Status'
    },
    {
        id: 3,
        value: 'numberHousing',
        name: 'Numero de vivienda'
    },
    {
        id: 4,
        value: 'email',
        name: 'Email'
    },
    {
        id: 5,
        value: 'propietario',
        name: 'Propietario'
    },
    {
        id: 6,
        value: 'invitado',
        name: 'Huesped'
    },
    {
        id: 7,
        value: 'reservetype',
        name: 'Tipo de reserva'
    },
    {
        id: 8,
        value: 'occupanttype',
        name: 'Tipo de Ocupante'
    },
    {
        id: 9,
        value: 'membership',
        name: 'Membresía'
    },
    {
        id: 10,
        value: 'folio',
        name: 'Código de Confirmación'
    },
    {
        id: 11,
        value: 'benefit',
        name: 'Beneficio'
    },
    {
        id: 12,
        value: 'order',
        name: 'Número de Orden'
    },
  ]


export const reservetype  = [
    { id: '1', name: 'Principal' },
    { id: '2', name: 'Adicional' },
]

export const occupanttype = [
    { id: '1', name: 'Propietario' },
    { id: '2', name: 'Invitado' },
    { id: '3', name: 'Interval' },
]

export const statusOwnerBooking = [
    { id: '2', name: 'Confirmada' },
    { id: '3', name: 'Cancelada' },
    { id: '6', name: 'Pendiente Cancelación' },
    { id: '1', name: 'Pendiente Confirmación' },
    { id: '5', name: 'Pendiente Modificación' },
    { id: '4', name: 'Por Autorizar' },
]

export const nameDocuments = [

    {
        name: 'Nombre test',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 2',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 3',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 4',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 5',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 6',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 7',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 8',
        typename: "Contrato"
    },
    {
        name: 'Nombre test 9',
        typename: "Contrato"
    },
]

export const ownerType = [
    { id: '1', name: 'Disfrute' },
    { id: '2', name: 'Inversionista' },
    { id: '3', name: 'Otros' },
]

export const condominalFeePeriodicity = [
    { id: 'ME', name: 'Mensual' },
    { id: 'BI', name: 'Bimestral' },
    { id: 'TR', name: 'Trimestral' },
    { id: 'CU', name: 'Cuatrimestral' },
    { id: 'SE', name: 'Semestral' },
]

export const seedBudgetCondosDetail = [
    {
        id:2637,
        type:"Cargo",
        subtype:"Cuota Condominal",
        quantity:"-857.88",
        paymentdate:null,
        paymentmethod:"",
        reference:null,
        notes:null,
        isMassPayment:false,
        createdate:"2023-06-01T20:50:20.093333",
        userName:"Salescore Administrator"
     },
     {
        id:2639,
        type:"Abono",
        subtype:"Cuota Condominal",
        quantity:"-857.88",
        paymentdate:null,
        paymentmethod:"",
        reference:null,
        notes:null,
        isMassPayment:false,
        createdate:"2023-06-01T20:50:20.093333",
        userName:"Salescore Administrator"
     }
 ]