import { mapState, mapActions, mapMutations } from 'vuex'
import { stringAleatorio, showAlertMessage, currentDate, toJson } from '@/helpers/helpers'
import { version } from '../../../../package'

export const market = {
  data () {
    return {
      params: this.$route.query,
      codesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
    }
  },
  computed:{
    ...mapState('auth',['isLogged'])    ,
    ...mapState('shop',['cart','dataListInclucions']),
    ...mapState('start', ['categories', 'currencies','destinations','airlines','departaments','payloadParamsRates']),
  },
  methods: {
    ...mapMutations('auth',['logout']),
    ...mapMutations('shop',[
      'resetCartCustomerBreakdownOrder',
      'setOperationDateProduct',
      'setBlackoutsDateProduct',
      'unsetCanalVenta',
      'unsetPuntoDeVenta',
      'setEmptyCart',
      'unsetLastNameSearch',
      'unsetDataRoom',

      'addProductInCart',
      'addProductsFromPackage',
      'addBookingInCart',
      'addBookingInCartOwners',
      'removeProductInCart',
      'setDataProductItem',

      'clearPaymentList',
      'resetAllBenefitsProductsDiscounts'
    ]),
    ...mapMutations('bookings',['resetToInitialState']),
    ...mapActions('shop', ['fetchBlackoutDatesProduct', 'calculateTotalWithDiscounts']),
    ...mapMutations('products',['setQtyProductDetail','setQtySingleProductDetail', 'setProducts','setSelectedCategory']),

    generateProductsToAddCart(product){
      const newDetail = product.detail.filter(detail => detail.qty > 0 )  //busco producto detalle al que se le modificó cantidades
      product.detail = newDetail
      if(product.modelType=="rates") {
        product.operationdate= this.payloadParamsRates.dateRange
      }
      const items = this.createProductsToAddCart(product)
      if(!items.length){
        const text = `Elija una opción de las variantes, indique una cantidad`
        showAlertMessage( 'Elija una opción', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      }
      return items
    },

    createProductsToAddCart(product){
      const totalProducts = product.detail.length
      let items = []
      for (let index = 0; index < totalProducts; index++) {
        items.push({...product, detailSelected: product.detail[index] }) //genero el arreglo de productos a agregar al cart
      }
      items.forEach( item => {
        delete item.detail //borro array detail del item
      })
      return items
    },
    formatProductBeforeAddingToCart(product){
      //DONDE REMUEVO Y AÑADO ELEMENTOS ANTES DE AÑADIR AL CART
      delete product.detailSelected.currency
      delete product.detailSelected.seccionventaid
      const { descmax, detaildisplay, id, saleprice, servicesihot, qty, paxcost, paxmax, pax, allowExtraPax,cost } = product.detailSelected
      //AÑADO PROPIEDADES ADICIONALES
      product.uuid = stringAleatorio() //les genero key unicos aleatorios
      // Amounts
      // Valores Unitarios
      product.saleprice = saleprice
      product.originalSalePrice = saleprice
      product.salepriceWithDiscount = saleprice
      product.cost = product.cost ? "0.00": cost
      product.unitPrice = saleprice
      product.descmax = parseInt(descmax) || 0
      product.discountApplied = 0
      product.discount = 0
      product.typeDiscount = 1 // 'Sin descuento'
      // Valores Totales
      product.total = saleprice * qty
      product.grossSale = saleprice * qty
      product.totaldiscount = 0
      product.netSale = saleprice * qty
      product.typeRate = product.detailSelected.typeDisplay
      product.qty = qty || 1
      product.detaildisplay = detaildisplay
      product.orderdetailbeo = this.setBeo(product)
      product.isIncludenIn = null
      product.operationdate = product.operationdate
      product.operationtime = '12:00'
      product.bookingReference=null
      product.isGettingUnavaliableDates=false
      product.note = ''
      product.show=false
      product.uuidPackage = null
      product.isPromo = false
      product.isBenefit = product.detailSelected.isBenefit
      product.isBenefitDescout= false
      product.benefitDiscount = false
      product.membershipDiscount = false
      product.benefitProduct = ''
      product.contracts = ''
      product.promoName = ''
      product.clientnote = ''
      product.isMain = false
      product.orderextraFields = []
      product.requiredFieldsFilled = false
      product.allowExtraPax = allowExtraPax
      product.beneficiarios = { fullname: '', phone: '', email: '', room: '', }
      product.itinerario = null
      if ( product.categoryName == 'Transfer' ) {
        product.itinerario = {}
        product.itinerario.nameBenefi = ''
        product.itinerario.lastNameBenefi = ''
        product.itinerario.trip = 'Arrival'
        product.itinerario.paxCost = paxcost
        product.itinerario.pax = 0
        product.itinerario.minPax =  pax ? parseInt(pax) : 0
        product.itinerario.extraPax = 0
        product.itinerario.amountExtraPax = '0.00'
        product.itinerario.maxPax = paxmax ? parseInt(paxmax) : 0
      }


      
      product.upgradesDetail = null
      

      if ( product.subcategoryName == 'Plan Upgrades' || product.subcategoryName == "Room Upgrades" ) {
        product.upgradesDetail = {}
        product.upgradesDetail.mealPlanNameOriginName =null
        product.upgradesDetail.mealPlanNameDestinationName =null
        product.upgradesDetail.roomsTypesOriginName =null
        product.upgradesDetail.roomsTypesDestinationName =null
        product.upgradesDetail.idHotel = null
        product.upgradesDetail.rangeDate = null
        product.upgradesDetail.dateIn = null
        product.upgradesDetail.dateOut = null
        

      }
      
      product.detailId =id
      product.serviciosihot = servicesihot || null
      product.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}
      product.belongsToPackage = false
      product.supplierPromo = product?.supplierPromo?.length > 0 ? product.supplierPromo : []
      //REMUEVO detailSelected
      delete product.detailSelected
      //mas deletes
      // delete product.coverImage
      delete product.category
      delete product.detail
      delete product.currencyCode
      delete product.type
      delete product.gallery
      delete product.videolink
      delete product.weblink
      delete product.productinfo
      delete product.terms
      delete product.servicesihot
      delete product.currency
      delete product.hotel
      delete product.metatag
      delete product.location
      delete product.startDate
      delete product.startTime
      delete product.endDate
      delete product.endDateFormat
      delete product.endTimeFormat
      delete product.hotelName
      delete product.locationName
      delete product.startDateFormat
      delete product.startTimeFormat
      return product
    },
    formatProductsPackage(item){
      const items = []
      const { isProductPackage, packageData, qty, uuid, servicesihot, valueFromCategory, paxcost, paxmax, pax, allowExtraPax  } = item
      if(isProductPackage && packageData.length){
        packageData.forEach( product => {
          // Amounts
          // Valores Unitarios
          product.saleprice = product.saleprice
          product.originalSalePrice = product.saleprice
          product.salepriceWithDiscount = product.saleprice
          product.cost = "0.00"
          product.unitPrice = product.saleprice
          product.descmax = parseInt(product.descmax) || 0
          product.discountApplied = 0
          product.discount = 0
          product.typeDiscount = 1 // 'Sin descuento'
          // Valores Totales
          product.total = product.saleprice * qty
          product.grossSale = product.saleprice * qty
          product.totaldiscount = 0
          product.netSale = product.saleprice * qty

          product.uuid = stringAleatorio()
          product.qty = qty
          product.itinerario = null
          product.detaildisplay = product.name || ''
          product.orderdetailbeo = this.setBeo(item)
          product.isIncludenIn = null,
          product.operationdate = null
          product.operationtime = '12:00'
          product.bookingReference=null
          product.isGettingUnavaliableDates=false
          product.note = ''
          product.uuidPackage = uuid
          product.isPromo = false
          product.isBenefit = false
          product.isBenefitDescout= false
          product.benefitDiscount = false
          product.membershipDiscount = false
          product.allowExtraPax = allowExtraPax
          product.benefitProduct = ''
          product.contracts = ''
          product.promoName = ''
          product.clientnote = '',
          product.isMain = false
          product.show=false
          product.orderextraFields = []
          product.requiredFieldsFilled = false
          product.beneficiarios = { fullname: '', phone: '', email: '', room: '', }
          product.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}
          if ( product.categoryName == 'Transfer' ) {
            product.itinerario = {}
            product.itinerario.nameBenefi = ''
            product.itinerario.lastNameBenefi = ''
            product.itinerario.trip = 'Arrival'
            product.itinerario.paxCost = paxcost
            product.itinerario.pax = 0
            product.itinerario.minPax =  pax ? parseInt(pax) : 0
            product.itinerario.extraPax = 0
            product.itinerario.amountExtraPax = '0.00'
            product.itinerario.maxPax = paxmax ? parseInt(paxmax) : 0
          }

          product.detailId = product.detail.id
          product.belongsToPackage = true
          product.serviciosihot = servicesihot || null
          product.valueFromCategory = valueFromCategory

          delete product.description
          // delete product.supplierPromo
          delete product.detail
          delete product.weblink
          delete product.metatag

          items.push(product)
        })
      }
      return items
    },
    setBeo( product) {
      const { detaildisplay, usabeo } = product
      if (usabeo) {
        return {
          titulo: `BEO  de ${detaildisplay}`,
          backup: null, locacion: null, comentario: '', pax: 0, numreserva: '', detailbeo: []
        }
      }
      return { titulo: '', backup: null, locacion: null, comentario: '', pax: 0, numreserva: '', detailbeo: [] }
    },

    formatPhoneNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which
      const respuesta = this.codesAllowed.includes(keyCode)
      if (!respuesta) {
        event.preventDefault()
      }
    },
    async qtyHasChanged(product){
      const{ uuid, qty, operationdate } = product
      // const text = `La cantidad ha sido incrementado: La fecha`
      // showAlertMessage( 'La cantidad ha cambiado', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      await this.setConfigBlackoutDatesProduct(product, qty)
      if(operationdate){
        const payload = { uuid, date: null }
        this.setOperationDateProduct(payload)
      }
    },
    async qtyHasChangedRate(product,qty){
      product.qty=qty
      const{ uuid, operationdate } = product
      // const text = `La cantidad ha sido incrementado: La fecha`
      // showAlertMessage( 'La cantidad ha cambiado', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      await this.setConfigBlackoutDatesProduct(product, qty)
      if(operationdate){
        const payload = { uuid, date: null }
        
        this.setOperationDateProduct(payload)
      }
    },
    async setConfigBlackoutDatesProduct(item, qty){
      const { uuid, id,modelType } = item
      if(modelType=="rates"){
        return false
      }
      item.isGettingUnavaliableDates=true
    
      const dates = await this.fetchBlackoutDatesProduct({idP:id, qty, fini: currentDate()})
      item.isGettingUnavaliableDates=false
      dates.sort( (a,b) => { return new Date(a.date) - new Date(b.date) })
      const blackoutDates = dates.map( d => d.date)
      // const maxDate = blackoutDates.length ? blackoutDates[blackoutDates.length - 1] : ''
      const configDate = { uuid, blackoutDates , maxDate: '' }
      this.setBlackoutsDateProduct(configDate)
    },
    existsInCart( item ) {
      const { detailId, categoryName, modelType }  = item
      const prodInCart = this.cart.items.find( product => product.detailId === detailId && product.modelType === modelType && modelType!="rates"  )
      return ( prodInCart && categoryName != "Transfer" ) ? true : false
    },
    handlerQtyProduct( product, detail){
      const idProduct = product.id
      const idDetail = detail.id
      const payload = { idProduct, idDetail, qty: detail.qty }
      this.setQtyProductDetail( payload )
    },
    handlerQtyProductSingle(product, detail){
      const idProduct = product.id
      const idDetail = detail.id
      const payload = { idProduct, idDetail, qty: detail.qty }
      this.setQtySingleProductDetail( payload )
    },
    clearStorage(){
      const localVersion = localStorage.getItem('appVersion')
      if(!localVersion){
        this.logoutForce()
        localStorage.removeItem('vuex') //clear vuex
        localStorage.setItem('appVersion', version)
        window.location.reload(true)
      }
      if( localVersion ){
        if( localVersion !== version ){
          this.logoutForce()
          localStorage.removeItem('vuex') //clear vuex
          localStorage.setItem('appVersion', version)
          window.location.reload(true)
        }
      }
    },
    logoutForce(){
      if(this.isLogged){
        this.logout()
        this.unsetCanalVenta()
        this.unsetPuntoDeVenta()
        this.setEmptyCart()
        this.unsetLastNameSearch()
        this.unsetDataRoom()
        this.resetToInitialState()
        this.setProducts([])
        this.setSelectedCategory(null)
        this.$router.push({ name: 'auth-login' })
      }
    },
    categoriesIsEmpty(){
      let isEmpty = true
      const { hotelServices, onlyCategories, withSubCategories, categoryServices } = this.categories
      if(hotelServices.length){
        isEmpty = false
      }
      if(onlyCategories.length){
        isEmpty = false
      }
      if(withSubCategories.length){
        isEmpty = false
      }
      if(categoryServices.length){
        isEmpty = false
      }
      return isEmpty
    },
    initialContentSummaryIsEmpty(){
      let isEmpty = true
      if(this.dataListInclucions.length){
        isEmpty = false
      }
      if(this.destinations.length){
        isEmpty = false
      }
      if(this.airlines.length){
        isEmpty = false
      }
      if(this.departaments.length){
        isEmpty = false
      }
      return isEmpty
    },
    async addProductInCartFlow(item, type) {
      const productsPackage = this.formatProductsPackage({...item}) //por si es paquete, desde el mixin

      if (type == 'Reservas') this.addBookingInCart(item)
      else if (type == 'ReservasOwners') this.addBookingInCartOwners(item)
      else {
        this.addProductInCart(item)
        await this.setConfigBlackoutDatesProduct(item, item.qty)  // desde el mixin market
      }

      if (item.isProductPackage && productsPackage.length){
        this.addProductsFromPackage(productsPackage)
      }
    },
    async manageAddProductInCart(item, type) {
      await this.addProductInCartFlow(item, type)
      this.resetAllBenefitsProductsDiscounts()
      this.clearPaymentList()
      const recalculated = await this.calculateTotalWithDiscounts()
      if (!recalculated) this.removeProductInCart(item)
    },
    async manageRemoveProductInCart(payload) {
      this.removeProductInCart(payload)
      this.resetAllBenefitsProductsDiscounts()
      this.clearPaymentList()
      await this.calculateTotalWithDiscounts()
    },
    async manageSetDataProductItem(product) {
      this.setDataProductItem(product)
      await this.calculateTotalWithDiscounts()
    }

  }
}

