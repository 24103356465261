import store from '../store/index'
import { stringAleatorio, toJson, deleteNulls } from './helpers'
import { optionsSelectCutoff } from '@/data/fivesClub'
import { canChangeEditBooking } from "@/data/data"

const createArraysToSaveAMembership = (blackouts = [], benefits = [], cutoffs = []) => {
    const ArrBlackouts = []
    const ArrBenefits = []
    const ArrCutoffs = []

    blackouts.forEach(({ id }) => {
        ArrBlackouts.push({ id })
    })

    benefits.forEach(({ id, qty, discount, initCan, endCan, state }) => {
        if (state == true) {
            ArrBenefits.push({ id, qty: parseInt(qty), discount: parseInt(discount != null ? discount : 0), initCan: parseInt(initCan != null ? initCan : 0), endCan: parseInt(endCan != null ? endCan : 0) })
        }

    })

    cutoffs.forEach(({ id, days, type, season, status }) => {
        const cutoff = { id, days: parseInt(days), type, season, status }
        if (id < 0) {
            delete cutoff.id
        }
        if (status == true) {
            ArrCutoffs.push(cutoff)
        }
    })
    return [ArrBlackouts, ArrBenefits, ArrCutoffs]
}

const createArrayToUpdateMembership = ( payload )=> {
    const { membershipBlackouts, membershipBenefits, membershipCutoff, newBenefits, newCutoffs, cancelationPolicies, newPolicies } = payload

    const arrBlackouts = membershipBlackouts.map(bl => bl.id)
    const arrBenefits = [].concat(membershipBenefits, newBenefits)
    const arrCutoffs = [].concat(membershipCutoff, newCutoffs)
    const arrPolicies = [].concat(cancelationPolicies, newPolicies)


    arrPolicies.sort((a, b) => a.priority > b.priority ? 1 : -1).forEach((pl, index) => {
      pl.priority = parseInt(index + 1)
    })

    return [arrBlackouts, arrBenefits, arrCutoffs, arrPolicies]

}

const benefitsCuttoffBlackouts = ( membership ) => {
    const blackoutsState = store.state.fivesClubCatalogs.blackouts
    const { membershipBlackout, Benefits, Cutoff, Policies } = membership
    const blackouts = blackoutsState.filter(item => membershipBlackout.includes(item.id)) //filter por arreglo de ids)

    membership.membershipBenefits = toJson(Benefits)
    membership.membershipBlackouts = blackouts
    membership.membershipCutoff = toJson(Cutoff)
    membership.cancelationPolicies = Policies

    if( membership.membershipCutoff.length > 0 ){
        membership.membershipCutoff.forEach( cuttoff => { //recorro los cutoff de la membership y le agrego su nombre buscandolo de mi catalog de optionsSelectCutoff
            const cutoffData = optionsSelectCutoff.find(c => c.type == cuttoff.type && c.season == cuttoff.season)
            cuttoff.name = cutoffData ? cutoffData.label : ''
        })
    }
    delete membership.membershipBlackout
    delete membership.Benefits
    delete membership.Cutoff
    delete membership.Policies
    return membership
}

const verifyDistincMembershipData = (data) => {
    const {values, oldData, newData} = data // requiere los indices de los objetos y que estén en arreglos.
    let finalData = {}

    values.forEach(content => {
        // console.log(`%c${content}`, "background: red; color: yellow; font-size: x-large")

        const newValues = []
        newData[content].forEach(item => {
            let dataChanged = []
            if (typeof item == 'string' || typeof item == 'number') {
                // console.warn(item)
                // console.log('newData: ', item)
                // console.log('oldData: ', oldData[content] )
                if (!oldData[content].includes(item)) newValues.push(item)
            } else if (item.id) {
                Object.keys(item).forEach(value => {
                    const oldDataValue = oldData[content].find(d => d.id == item.id)
                    // console.warn(value)
                    // console.log('newData: ', item[value])
                    // console.log('oldData: ', oldDataValue[value] )
                    if (item[value] != oldDataValue[value]) dataChanged.push({Modificado: value, de: oldDataValue[value], a: item[value]})
                })
                if (dataChanged.length > 0) {
                    newValues.push(item)
                    // console.log(`%c Datos cambiados en ${content}:`, "background: blue; color: white")
                    // console.table(dataChanged);
                }
            } else newValues.push(item)
        })

        finalData[content] = newValues
    })
    return finalData
}

const setArraysToEditAMembership = (membership) => {
    const blackoutsState = store.state.fivesClubCatalogs.blackouts
    const benefitsState = store.state.fivesClubCatalogs.benefits

    const { membershipBlackout, Benefits, Cutoff } = membership
    const benefits = []
    benefitsState.forEach( benefitOpt => {
        const benefit = Benefits.find(item => item.idBenfit === benefitOpt.id)
        if (benefit) {
            const { id, name, initCan, endCan } = benefitOpt
            benefits.push({ id, name, qty: benefit.can, state: true, discount: benefit.discount, initCan, endCan })
        } else {
            const { id, name } = benefitOpt
            benefits.push({ id, name, qty: benefitOpt.can, state: false })
        }
    })
    const blackouts = blackoutsState.filter(item => membershipBlackout.includes(item.id)) //filter por arreglo de ids

    const cutoffs = []
    optionsSelectCutoff.forEach(cut => {
        cut.type = Number(cut.type)
        cut.season = Number(cut.season)
        //no se pudo hacer filter por arreglo de ids, por ocupar doble criteriod de busqueda
        const cutoff = Cutoff.find(coff => coff.id === cut.id)
        if (cutoff) {
            cutoff.id = cut.id
            cutoff.label = cut.label
            cutoff.state = true
            cutoffs.push(cutoff)
        } else {

            cutoffs.push(cut)
        }
    })
    return [blackouts, benefits, cutoffs]

}

const setBenefitsInputEdit = Benefits => { // recibe Benefits
    /* can:5 discount:6 endCan:0 idBenfit:117 initCan:0 nameBenefit:"TEST" */
    const benefitsState = store.state.fivesClubCatalogs.benefits
    const benefits = []
    Benefits.forEach( benefitOpt => {
        const benefit = benefitsState.find(item => item.id === benefitOpt.idBenfit)
        if (benefit) {
            const { id, name } = benefit
            benefits.push({ id, name, qty: benefitOpt.can, discount: benefitOpt.discount  })
        }
    })
    return benefits
}

const setCutoffsInputEdit = optionsCutoffs => {
    const cutoffs = []
    optionsCutoffs.forEach(cut => {
        cut.type = Number(cut.type)
        cut.season = Number(cut.season)
        const cutoff = optionsSelectCutoff.find(coff => coff.type === cut.type && coff.season === cut.season)
        if (cutoff) {
            const { id, type, season, label } = cutoff
            cutoffs.push({ id, days: cut.days, type, season, status: cut.status, label })
        }
    })
    return cutoffs
}
const formatCutoffs = () => {
    const seasons = store.state.fivesClubCatalogs.seasons
    const cutoffs = store.state.fivesClubCatalogs.cutoffs
    const cuttoffsFormatted = []

    cutoffs.forEach(cutoff => {
        const season = seasons.find(item => item.id === cutoff.season)
        cutoff.seasonData = season
        cutoff.seasonName = season ? season.name : ''

        cuttoffsFormatted.push(cutoff)
    })
    return cuttoffsFormatted
}

const removeDatesDuplicated = dates => {

    const valueArr = dates.map(function (item) { return item.range });
    const isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx
    })

    const newDates = dates.filter((currentValue, index, array) => array.findIndex(date => (date.range === currentValue.range)) === index)
    return { newDates, thereAreDuplicates: isDuplicate }
}

const checkExistingBlackouts = dates => {
    const {actualBl, newBl} = dates
    let repeatedBlackout = []
    let repeatedDate = []
    let repeatedName = []
    let hasOneRepeatedDate = []
    let hasSameDate = null
    let response = {
        exists: false,
        msg: ''
    }

    actualBl.forEach(blackouts =>{ // Blackouts del contrato
        let dateContract = []
        let newDatesBl = []
        let repeatedDate = []
        blackouts.datesBlackout.forEach(cDates => { // fechas del blackout en curso
            dateContract.push({dateStart: cDates.dateStart, dateEnd: cDates.dateEnd})
        })
        newBl.newDates.forEach(newD =>{ // fechas del blackout a guardar
            newDatesBl.push({dateStart: newD.dateStart, dateEnd: newD.dateEnd})
            hasSameDate = dateContract.filter(date => date.dateStart == newD.dateStart && dates.dateEnd == newD.dateEnd)
            if (hasSameDate.length!=0) repeatedDate.push(hasSameDate)
        })


        const areEquals = JSON.stringify(newDatesBl) == JSON.stringify(dateContract)

        if (areEquals && blackouts.name == newBl.name) {
            repeatedBlackout.push(blackouts.name)
            response.exists = true
            return response
        }

        else if (areEquals) {
            repeatedDate.push(blackouts.name)
            response.exists = true
            return response
        }

        else if (repeatedDate.length > 0){
            hasOneRepeatedDate.push(blackouts.name)
            response.exists = true
            return response
        }

        else if (blackouts.name == newBl.name) {
            repeatedName.push(blackouts.name)
            response.exists = true
            return response
        }
    })

    if (repeatedBlackout.length > 0) response.msg = `${repeatedBlackout} ya existe, intente otra configuración.`
    if (repeatedDate.length > 0) response.msg = `las fechas coinciden con otro blackout existente: ${repeatedDate}`
    if (hasOneRepeatedDate.length > 0) response.msg = `hay algunas fechas que coinciden con otro blackout existente: ${hasOneRepeatedDate}`
    if (repeatedName.length > 0) response.msg = `El nombre coincide con otro blackout existente: ${repeatedName}`

    return response
}

const createArrayDates = dates => {
    const arrDates = []
    dates.forEach(date => {
        arrDates.push({ dateStart: date.dateStart, dateEnd: date.dateEnd })
    })
    return arrDates
}

const formatDatesBlackout = datesBlackout => {
    const isEmpty = Object.keys(datesBlackout).length === 0
    if(!isEmpty){
        datesBlackout.forEach(date => {
            date.range = `${date.dateStart} to ${date.dateEnd}`
            date.uuid = stringAleatorio()
        })
        return datesBlackout
    } else {
        return []
    }
}

const formatDateRangesBlackout = datesBlackout => {
    const range = []
    if (datesBlackout) datesBlackout?.forEach(dates => {
        range.push({
            dateEnd: dates?.dateEnd,
            dateStart: dates?.dateStart,
            range: dates?.range,
            uuid: dates?.uuid
        })
    })
    return range
}

const setArraysToEditCustomers = (customers) => {
    const { housing } = customers
    const housingList = []
    housing.forEach(housingOpt => {
        housingOpt.state = true
        housingList.push(housingOpt)
    })

    return [ housingList ]

}

const createArraysToSaveProducts = (product) => {

    const productList = []
    product.forEach(productOpt => {
        productList.push({ idProduct:productOpt.id,status:productOpt.status })
    })

    return productList
}

const createArraysToSaveCustomers = (housingList,) => {

    const ArrHousing = []

    housingList.forEach(housing => {
        ArrHousing.push({
            id: 0, //si es nuevo me lo pones en 0
            idHousing: housing.id,
            percentage: !!housing.percent ? housing.percent : 0,
            datein: !!housing.dateEnd ? housing.dateEnd : null,
            dateOut: !!housing.dateEnd ? housing.dateEnd : null,
            relationId: !!housing.housingType ? housing.housingType : null,
            idContract: !!housing.idContract ? housing.idContract : null,
        })
    })
    return ArrHousing
}

const createArraysToUpdateCustomers = (housingList,newHousingList, idOwner) => {
    const ArrHousing = []

    housingList.forEach(housing => {
        if (housing.state == true) {
            ArrHousing.push({
                id: !!housing.idOwnerHousing ? housing.idOwnerHousing : 0,
                idOwner,
                idHousing: housing.idHousing,
                percentage: !!housing.percentage ? housing.percentage : 0,
                datein: !!housing.dateIn ? housing.dateIn : null,
                dateOut: !!housing.dateOut ? housing.dateOut : null,
                idContract: !!housing.idContract ? housing.idContract : null,
                relationId: housing.relation,
            })
        }
    })
    newHousingList.forEach(housing => {
        if (housing.state == true) {
            ArrHousing.push({
                id: 0,
                idOwner,
                idHousing: housing.id,
                percentage: !!housing.percent ? housing.percent : 0,
                datein: !!housing.dateIn ? housing.dateIn : null,
                dateOut: !!housing.dateOut ? housing.dateOut : null,
                relationId: !!housing.relation ? housing.relation : null,
                idContract: !!housing.idContract ? housing.idContract : null,
            })
        }
    })


    return ArrHousing
}

const setArraysToEditPool = (pool) => {

    const getHousingState = store.state.fivesClubCatalogs.housing
    const housingId = pool.housings
    const housingList = getHousingState.filter(item => housingId.includes(item.id)) //filter por arreglo de ids
    return [housingList]

}

const addIsLoadingToArray = ( items ) => {
    if (items) items.forEach(typeBenefit => {
        typeBenefit.isLoading = false
    })
    return items
}

const formatAddressCondos = ( items ) => {
    items.forEach(condo => {
        condo.fullAddress = `${condo.address} ${condo.street} ${condo.zip}`
    })
    return items
}

function setArrayContractsBenefits(contractsBenefits) {
    let extra = []
    const ContractBenefits = contractsBenefits[0].benefitMembership

    let ExtraBenefits = []

    contractsBenefits[0].benefitExtra.forEach(item => {
        const {id, benefit, benefitname, qty, amount, dateIn, dateOut, isExtra} = item

        ExtraBenefits.push({
            id: id,
            benefit: benefit,
            benefitname: benefitname,
            qty: qty,
            amount: amount,
            dateIn: dateIn,
            dateOut: dateOut,
            isExtra: isExtra
        })
    });

    contractsBenefits[0].benefitNews.forEach(item => {
        const {id, benefit, benefitname, qty, amount, dateIn, dateOut, isExtra} = item

        ExtraBenefits.push({
            id: id,
            benefit: benefit,
            benefitname: benefitname,
            qty: qty,
            amount: amount,
            dateIn: dateIn,
            dateOut: dateOut,
            isExtra: isExtra
        })
    });

    extra = {
        ContractBenefits,
        ExtraBenefits
    }
    return extra
}


const createArraysTocategory = ( categories,categoriesSave) => {
    const ArrCategories = []
    categories.forEach(category => {
        const categoriesInfo = categoriesSave.find(item => item.category === category.id)
        if (categoriesInfo) {
            const { id, descount } = categoriesInfo
            ArrCategories.push({ id, name:category.name, descount,state:true })
        }else if(category.descount!=null) {
            const { id, descount,state } = category
            ArrCategories.push({ id, name:category.name, descount,state })
        }
    })
    return ArrCategories
}

const createArraysTocategoryEdit = ( categories,categoriesSave ) => {
    const ArrCategories = []
    categories.forEach(cat => {
        const categoriesInfo = categoriesSave.find(item => item.category === cat.id)
        if (categoriesInfo) {
            const { category, descount } = categoriesInfo
            ArrCategories.push({ id: category, name: cat.name, descount,state:true })
        } else {
            const { id, name } = cat
            ArrCategories.push({ id, name, descount: null,state:false })
        }
    })

    return ArrCategories
}

const makeParamsFilterHousing = ( data ) => {
    const { Number, IdCondo, IdSubCondo, activeResidences, resort, roomType, rentalPoolId, paymentTypeId, balanceDue  } = data

    const payload =  {
        Number: Number || null,
        IdCondo: IdCondo || null,
        IdSubCondo: IdSubCondo || null,
        activeResidences: activeResidences !== null ? activeResidences : null,        
        resort: resort || null,
        roomType: roomType || null,
        rentalPoolId: rentalPoolId || null,
        paymentTypeId: paymentTypeId || null,
        balanceDue: balanceDue || false,
    }
    if( !payload.balanceDue ){
        delete payload.balanceDue
    }

    return deleteNulls(payload)

}

const makeParamsFilterOwners = ( data ) => {
    const { typeFilter, filter } = data

    const payload =  {
        typeFilter,
        filter,
    }
    if( !typeFilter ){
        delete payload.typeFilter
    }
    if( !filter ){
        delete payload.filter
    }

    return payload

}

const makeParamsFilterContracts = ( data ) => {
    const { typeFilter, duration, email, membership, nameLastname, numHouse, resort, status, idContract, paymentType, returnType, rentalPool } = data

    const filters = {
        IdMembership: membership || null,
        IdResorts: resort || null,
        number: numHouse || null,
        duration: duration || null,
        IdStatus: status || null,
        email: email || null,
        name: nameLastname || null,
        IdContract: idContract || null,
        paymentType: paymentType || null,
        returnType: returnType || null,
        rentalPool: rentalPool || null
    }

    return deleteNulls(filters)
}

const makeParamsFilterOwnersBookings = ( data ) => {
    const { type, fechaInicio, fechaFin, filterByDates, email, nameLastname, numHouse, resort, status, idContract,
        reservetype, nameOwner, occupanttype, fromAx, fromSalescore, fromWeb, isCheckIn, isCheckOut, numberHousing,
        membership,folio, order ,benefit, DownExcel,all } = data

    let payload = {
        DownExcel: DownExcel || null,
        filterByDates,
        checkin: filterByDates ? fechaInicio : null,
        checkout: filterByDates ? fechaFin : null,
        IdResorts: resort || null,
        number: numHouse || null,
        IdStatus: status || null,
        email: email || null,
        name: nameLastname || null,
        nameOwner: nameOwner || null,
        IdContract: idContract || null,
        reservetype: reservetype || null,
        occupanttype: occupanttype || null,
        numberHousing: numberHousing || null,
        membership: membership || null,
        folio: folio || null,
        benefit: benefit || null,
        order: order || null,

        ishistory: fromAx ? 1 : null,
        originbooking: fromSalescore ? "2" : fromWeb ? "1" : null,
        ischeckin: isCheckIn ? 1 : null,
        ischeckout: isCheckOut ? 1 : null,
        all: all || null
    }

    return deleteNulls(payload)
}
const setNameOwnersInContracts = ( contracts ) => {
    contracts.forEach(contract => {
        const owner = contract.owners.find(owner => owner.currentidOwner === contract.owner)
        const nameOwner = owner ? `${owner.ownerName} ${owner.ownerLastname}` : '--'
        contract.nameOwner = nameOwner.trim()
        contract.initialStatus = contract.statusContract
    })
    return contracts
}

const createArrayToEditPoolRent = ( housings, ids) => {

    let arrHousings = []

    housings.forEach(housing => {
        const housingsInfo = ids.find(item => item.id === housing.id)
        if (housingsInfo) {
            const { id, name,numberHousing } = housing
            arrHousings.push({ id, name, numberHousing,state:true })
        }else{
            const { id, name,numberHousing  } = housing
            arrHousings.push({ id, name, numberHousing,state:false })
        }
    })
    return arrHousings
}

const payloadBookigOwner = ( payload ) => {

    const {
        adults,
        children,
        datein,
        dateout,bookingValid,
        notes,occupanttype,roomBooking,codeRoom,reservetype,
        isguest,
        nights,
        namesbeneficiaries,
        housing,
        housingcode,
        housingname,
        housingnumber,
        id,
        internalnotes,
        ishistory,
        contractid,
        code,reservanumber,
        occupantname,
        reservename,
        fullname,status,cancelNotes,
        needAuthorization,
        noteAuthorization,
        AuthorizationBy,
        dateAuthorization
      } = payload

    namesbeneficiaries.forEach( beneficiario => {
        beneficiario.key = stringAleatorio()
    })
    const booking = {
        checkIn : datein,
        checkInCheckOut : `${datein} to ${dateout}`,
        bookingValid:bookingValid,
        checkOut : dateout,
        comments : notes,
        isAGuest : isguest,
        roomBooking:roomBooking,
        codeRoom:codeRoom,
        occupanttype : occupanttype,
        reservetype : reservetype,
        nights,
        contractid,
        numberAdults : adults,
        numberChildren : children,
        status,
        cancelNotes,
        mainOwner: {
            name: namesbeneficiaries.length > 0 ? namesbeneficiaries[0].name : '',
            lastname: namesbeneficiaries.length > 0 ? namesbeneficiaries[0].lastname : '',
            email: namesbeneficiaries.length > 0 ? namesbeneficiaries[0].email : '',
            phone: namesbeneficiaries.length > 0 ? namesbeneficiaries[0].phone : '',
        },
        dataChildrens: namesbeneficiaries.filter(item => !item.adult),
        adultsDetail:  namesbeneficiaries.filter(item => item.adult),
        invitedAdults: namesbeneficiaries.filter(item => item.adult),
        //...rest
        housing, housingcode, housingname, housingnumber, id, internalnotes, ishistory,
        needAuthorization, noteAuthorization, AuthorizationBy, dateAuthorization,
        // dataChildrens:
        bookingname: "No. Folio: "+`${reservanumber}: ${fullname} | ${occupantname} ${reservename}`
    }
    return booking
}

const payloadBookigOwnerDingus = ( payload ) => {

    const {namesbeneficiaries,  } =  payload
    namesbeneficiaries.forEach( beneficiario => {
        beneficiario.key = stringAleatorio()
    })

    const booking = {
        ...payload,
        dateIn : payload.datein,
        rangeDate : `${payload.datein} to ${payload.dateout}`,
        dateOut : payload.dateout,
        ninos: namesbeneficiaries.filter(item => !item.adult),
        infoAdults:  namesbeneficiaries.filter(item => item.adult),
        invitedAdults: namesbeneficiaries.filter(item => item.adult),
        //...rest

        // dataChildrens:
    }
    return booking
}

const createPayloadOwnerBooking = ( bookingRequest ) => {
    const { adults, bookingAvailibility, children, dateIn, dateOut, housingid,
        idUser, internalNotes, isguest, needAuthorization, infoBeneficiaries,
        nights, occupanttype, origin, owner, reasonAuthorization, idconfigurationRoom } = bookingRequest // todo trabaja con owner para el payload

    const payload = {
        adults,
        children,
        dateIn,
        dateOut,
        housing: housingid,
        id: 0,
        infoBeneficiaries,
        internalnotes: internalNotes,
        isguest,
        needAuthorization,
        nights,
        occupanttype, //si es propietario 1, sino 2
        origin,
        owner: owner.idOwner,
        reasonAuthorization,
        reservetype: bookingAvailibility,
        status: 2,
        userId: idUser,
        idconfigurationRoom
    }

    return payload
}

const makeParamsOwners = ( data ) => {
    let arrayNames = []

    const { infoAdults, children }=  data

    infoAdults.forEach(item => {
        const { email, name,lastname,phone ,ismain} = item
        arrayNames.push({email,edad:0, name,lastname,phone,ismain: ismain ? true : false, adult: true })
    })

    children.forEach(item => {
        const { email, name,lastname,phone,edad } = item
        arrayNames.push({email,edad, name,lastname,phone, ismain: false, adult: false})
    })

    return arrayNames
}

const makeParamsOwnersEdit = ( data ) => {
    let arrayNames = []

    data.forEach(item => {
       const { email,edad, name,lastname,phone ,ismain,adult} = item
       arrayNames.push({email,edad:edad || 0, name,lastname,phone,ismain: ismain ? true : false, adult: adult ? true : false})
   })

   return arrayNames
}
const makeParamsForOwnersNigths = ( data ) => {
    const benefit = data.find(item => item.code === "TA")
    return benefit ? benefit : false
}

const findSelectedBenefitsforOwnersNigths = ( data ) => {
    const benefit = data.find(item => item.selected === true)
    return benefit ? benefit : false
}

const setSelectedBenefitSwitch = (bookingInfo) => {
    if (bookingInfo?.benefits != '') return bookingInfo?.benefits?.some((item) => item.selected)
}

const setCanChangeEditBooking = (bookingValid) => {
   let result=null
    const status=canChangeEditBooking.find(permisse => permisse.name == bookingValid.name ).canChageStatus

    if (bookingValid.isChange == true )
        result=status

    return result

}

const setFormInfoTransfer = (data, idBooking) => {
    const payload = []
    data.forEach(item => {
        const { id, arrivalairline,arrivalflight,arrivaldate,arrivaltime,departureairline, departureflight,
            departuredate,departuretime,baggage,babychair,arrivalorder,departureorder} = item

        let filters = {
            arrivalairline: !!arrivalairline ? arrivalairline : null,
            arrivalflight: !!arrivalflight ? arrivalflight : null,
            arrivaldate: !!arrivaldate ? arrivaldate : null,
            arrivaltime: !!arrivaltime ? arrivaltime : null,
            departureairline: !!departureairline ? departureairline : null,
            departureflight: !!departureflight ? departureflight : null,
            departuredate: !!departuredate ? departuredate : null,
            departuretime: !!departuretime ? departuretime : null,
            baggage: !!baggage ? baggage : null,
            babychair: !!babychair ? babychair : null,
            arrivalorder: !!arrivalorder ? arrivalorder : null,
            departureorder: !!departureorder ? departureorder : null,
        }
        filters = deleteNulls(filters)

        if (Object.keys(filters).length > 0) {
            filters.id = !!id ? id : null
            filters.booking_id = idBooking
            payload.push(filters)
        }
    });

    return deleteNulls(payload)
}

const setFormInfoMeal = (data) => {
    const { id, mealplan, allergies, specialMeal} = data

    const filters = {
        id: !!id ? id : null,
        mealplan: !!mealplan ? mealplan : null,
        allergies: !!allergies ? allergies : null,
        specialMeal: !!specialMeal ? specialMeal : null
    }

    return deleteNulls(filters)
}

const setFormInfoPrefer = (data) => {
    const { id, ownerbox,requirementsroom,additionalactivities,specialcelebrations,extrarooms} = data

    const filters = {
        id: !!id ? id : null,
        ownerbox: !!ownerbox ? ownerbox : null,
        requirementsroom: !!requirementsroom ? requirementsroom : null,
        additionalactivities: !!additionalactivities ? additionalactivities : null,
        specialcelebrations: !!specialcelebrations ? specialcelebrations : null,
        extrarooms: !!extrarooms ? extrarooms : null
    }

    return deleteNulls(filters)
}

const setFormInfoRestaurant = (data, idBooking) => {
    
    const payload = []
    data.forEach(item => {
        const { id, date, restaurant, schedule, confirmation} = item

        let filters = {
            date: !!date ? date : null,
            restaurant: !!restaurant ? restaurant : null,
            schedule: !!schedule ? schedule : null,
            confirmation: !!confirmation ? confirmation : null,
        }
        filters = deleteNulls(filters)
        if (Object.keys(filters).length > 0) {
            filters.id = !!id ? id : null
            filters.booking_id = idBooking
            payload.push(filters)
        }

    });

    return deleteNulls(payload)
}

export {
    makeParamsOwnersEdit,
    payloadBookigOwnerDingus,
    createPayloadOwnerBooking,
    makeParamsForOwnersNigths,
    findSelectedBenefitsforOwnersNigths,
    makeParamsOwners,
    formatDateRangesBlackout,
    createArraysToSaveProducts,
    setArraysToEditPool,
    setArraysToEditCustomers,
    createArraysToSaveCustomers,
    createArraysToUpdateCustomers,
    createArraysToSaveAMembership,
    setArraysToEditAMembership,
    verifyDistincMembershipData,
    formatCutoffs,
    removeDatesDuplicated,
    checkExistingBlackouts,
    createArrayDates,
    formatDatesBlackout,
    setBenefitsInputEdit,
    setCutoffsInputEdit,
    addIsLoadingToArray,
    setArrayContractsBenefits,
    formatAddressCondos,
    createArraysTocategory,
    createArraysTocategoryEdit,
    makeParamsFilterHousing,
    makeParamsFilterOwners,
    makeParamsFilterContracts,
    createArrayToEditPoolRent,
    makeParamsFilterOwnersBookings,
    setNameOwnersInContracts,
    payloadBookigOwner,
    benefitsCuttoffBlackouts,
    createArrayToUpdateMembership,
    setSelectedBenefitSwitch,
    setCanChangeEditBooking,
    setFormInfoTransfer,
    setFormInfoMeal,
    setFormInfoPrefer,
    setFormInfoRestaurant
}