
export default {         
    myTabs:[
        {
            keyTab: '8370bdda-9112-11ec-b909-0242ac120002',
            isActive: true,
            isMain: true
        }
    ],
    rooms:[],
    searchForms:[
        {
            adults: 1, 
            ageChildren:[],                
            dateIn: '',
            dateOut: '',
            fromTab: '8370bdda-9112-11ec-b909-0242ac120002',
            idHotel: '',
            language: 'en',
            nights: 0,
            numberChildren: 0, 
            promoCode:'',
            rangeDate: null,
            vendorcode: '',                    
        }
    ],
    userAuthorizeRate: {
        isInvalid: true,
        idUser: null,
        username: '',
        code:'',                    
        urlAuthorization: ''
    }, 
}