export function setBenefits(state, benefits) {
    state.benefits = benefits
}
export function setBenefitsProducts(state, benefitsProducts) {
    state.benefitsProducts = benefitsProducts
}

export function setBlackouts(state, blackouts) {
    state.blackouts = blackouts
}

export function setBlackoutsTitles(state, blackouts) {
    state.blackoutsOptions = blackouts
}
export function setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods
}

export function setListCategories(state, category) {
    state.categoriesProducts = category
}

export function setTypeMemberships(state, typeMemberships) {
    state.typeMemberships = typeMemberships
}

export function setTypesBenefits(state, typesBenefits) {
    state.typesBenefits = typesBenefits
}

export function setGroupsTypesBenefits(state, groupsTypesBenefits) {
    state.groupsTypesBenefits = groupsTypesBenefits
}

export function setMemberships(state, memberships) {
    state.memberships = memberships
}

export function setMembershipsTitles(state, memberships) {
    state.membershipsOptions = memberships
}
export function setBenefitTitles(state, benefit) {
    state.benefitOptions = benefit
}


export function setCutoffs(state, cutoffs) {
    state.cutoffs = cutoffs
}

export function setHousing(state, housing) {
    state.housing = housing
}


export function setCondominiumFees(state, condominiumFees) {
    state.condominiumFees = condominiumFees
}

export function setCondominiumFeeSelected(state, data) {
    const { id, boolean } = data

    state.condominiumFees.forEach(condFee => {
        condFee.isSelectedItem = false                    
    })
    
    const idx = state.condominiumFees.findIndex( b => b.id === id )
    state.condominiumFees[idx].isSelectedItem = boolean     
}

export function setIsLoadingDetailsCondominiumFee(state, data) {
    const { id, boolean } = data

    state.condominiumFees.forEach(condFee => {
        condFee.isLoadingDetails = false                    
    })
    
    const idx = state.condominiumFees.findIndex( b => b.id === id )
    state.condominiumFees[idx].isLoadingDetails = boolean     
}

export function setDetailsCondominiumFee(state, data) {
    const { id, details } = data
  
    const idx = state.condominiumFees.findIndex( b => b.id === id )
    state.condominiumFees[idx].detailsFee = details     
}


//booleano para indicar si todos los housing está marcados
export function setIsMarkedAllHousing(state, boolean) {
    state.isMarkedAllHousing = boolean
}

//marcar uno en concreto
export function setMarkedHousing(state, data) {
    const { isMarked, id } = data    
    const idx = state.housing.findIndex( b => b.id === id )
    state.housing[idx].isMarked = isMarked
}


//marcar a todos los housing
export function setAllMarkedHousing(state, data) { 
    const { isMarked } = data    
    state.housing.forEach(housing => {
        housing.isMarked = isMarked                
    })
}

export function setSelectedFeeHousing(state, data){
    state.selectedFeeHousing = data
}

export function setCondos(state, condos) {
    state.condos = condos
}

export function setCondominiumDataToEdit (state, data) {
    state.condominiumDataEdit.mainData = data
}

export function setSelectedYearBudgetCondominium (state, year) {
    state.condominiumDataEdit.selectedYear = year
}

export function setSelectedBudgetCondominium (state, budget) {
    state.condominiumDataEdit.selectedBudget = budget
}

export function setListAnnualCondominiumBudgets (state, list) {
    state.listAnnualCondominiumBudgets = list
}

export function deleteBudgetInlist (state, idBudget) {
    state.listAnnualCondominiumBudgets = state.listAnnualCondominiumBudgets.filter( budget => budget.id !== idBudget ) 
}


export function setCondominalBudgetByPeriods(state, periods) {
    state.condominalBudgetByPeriods = periods
}

export function setCondominalFeesHousingByYear(state, feesYear) {
    state.condominalFeesHousingByYear = feesYear     
}

export function updateCondominalFeesHousing(state, data) {
    const { id } = data         
    const idx = state.condominalFeesHousingByYear.findIndex( b => b.id === id )
    state.condominalFeesHousingByYear[idx] = data 
    console.log('me actualizo')    
}

export function setIsShowingDetailsHousing(state, data) {
    const { id, bool } = data
	
    state.filter.queryCondominalFeesHousingByYear = '' // si tiene filtro lo reseteo


    state.condominalFeesHousingByYear.forEach(period => {
        period.isShowingDetails = false                
    })	 

    const idx = state.condominalFeesHousingByYear.findIndex( b => b.id === id )
    state.condominalFeesHousingByYear[idx].isShowingDetails = bool 
}

export function setFilterSearchCondominalFeesHousingByYear(state, filterQuery) {
    state.filter.queryCondominalFeesHousingByYear = filterQuery

    state.condominalFeesHousingByYear.forEach(period => {
        period.isShowingDetails = false  // si la tabla esta viendo un detalle en especifico, lo limpio              
    })
}

export function setShowAllFeesHousingByYear(state) {
    
    state.condominalFeesHousingByYear.forEach(period => {
        period.isShowingDetails = false  // si la tabla esta viendo un detalle en especifico, lo limpio              
    })

}

export function setFeeHousingDetail(state, detailsFee) {
    state.detailsFeeHousing = detailsFee
}

export function setBudgetByPeriodSelected(state, data) {
    const { idPeriod, boolean } = data

    state.condominalBudgetByPeriods.forEach(period => {
        period.isSelectedItem = false                    
    })
    
    const idx = state.condominalBudgetByPeriods.findIndex( b => b.idPeriod === idPeriod )
    state.condominalBudgetByPeriods[idx].isSelectedItem = boolean     
}

export function setIsLoadingDetailsBudgetByPeriod(state, data) {
    const { idPeriod, boolean } = data

    state.condominalBudgetByPeriods.forEach(condFee => {
        condFee.isLoadingDetails = false                    
    })
    
    const idx = state.condominalBudgetByPeriods.findIndex( b => b.idPeriod === idPeriod )
    state.condominalBudgetByPeriods[idx].isLoadingDetails = boolean     
}

export function setDetailsBudgetByPeriod(state, data) {
    const { idPeriod, details } = data
  
    const idx = state.condominalBudgetByPeriods.findIndex( b => b.idPeriod === idPeriod )
    state.condominalBudgetByPeriods[idx].detailsPeriod = details     
}

// este es para el booleano en el head de la tabla de periodos
export function setIsMarkedAllBudgetByPeriods(state, boolean) {
    state.isMarkedAllBudgetByPeriods = boolean
}

//marcar un periodo en concreto
export function setMarkedBudgetByPeriod(state, data) {    
    const { isMarked, idPeriod } = data    
    const idx = state.condominalBudgetByPeriods.findIndex( b => b.idPeriod === idPeriod )
    state.condominalBudgetByPeriods[idx].isMarked = isMarked
}


//marcar a todos los periodos
export function setAllMarkedBudgetByPeriods(state, data) { 
    const { isMarked } = data    
    state.condominalBudgetByPeriods.forEach(period => {
        period.isMarked = isMarked                
    })
}



export function setSubCondos(state, subCondos) {
    state.subCondos = subCondos
}

export function setSeasons(state, seasons) {
    state.seasons = seasons
}

export function setPaymentTypes(state, paymentTypes) {
    state.paymentTypes = paymentTypes
}

export function setRetornos(state, retornos) {
    state.retornos = retornos
}

export function setCategories(state, categories) {
    state.categories = categories
}

export function setCancellationReasons(state, cancellationReasons) {
    state.cancellationReasons = cancellationReasons
}

export function setBookingInfo(state, benefits) {
    state.bookingInfo.benefits = benefits
}

export function setRequestOwners(state, request) {
    state.requestOwners = request
}

export function setRequestHousing(state, request) {
    state.requestHousing = request
}

export function removePropertyRequestHousing(state, property) {
    delete state.requestHousing[property]  // borro propiedad
    const isObjectEmpty = Object.keys(state.requestHousing).length === 0 && state.requestHousing.constructor === Object
    if(isObjectEmpty){
        state.requestHousing = null
    }  
}

export function setGetHousing(state, getHousing) {
    state.getHousing = getHousing
}


export function setNacionality(state, nationalities) {
    state.nationalities = nationalities
}

export function setRentalPool(state, rentalPool) {
    state.rentalPool = rentalPool
}

export function setNewsLetters(state, newsLetters) {
    state.newsLetters = newsLetters
}

export function setHotelImg(state, hotelImg) {
    state.hotelImg = hotelImg
}


//para poner en el state el item a actualizar
export function setSelectedBenefit(state, selectedBenefit) {
    state.selectedBenefit = selectedBenefit
}

export function setSelectedBlackout(state, selectedBlackout) {
    state.selectedBlackout = selectedBlackout
}

export function setSelectedTypeMembership(state, selectedTypeMembership) {
    state.selectedTypeMembership = selectedTypeMembership
}

export function setSelectedTypeBenefit(state, selectedTypeBenefit) {
    state.selectedTypeBenefit = selectedTypeBenefit
}

export function setSelectedGroupTypeBenefit(state, selectedGroupTypeBenefit) {
    state.selectedGroupTypeBenefit = selectedGroupTypeBenefit
}

export function setSelectedMembership(state, selectedMembership) {
    state.selectedMembership = selectedMembership
}

export function setAvailableBenefits(state, benefits) {
    state.selectedMembership.benefitsAvailable = benefits
}

export function removeBenefitMembership(state, benefit) {
    const { idBenfit } = benefit
    state.selectedMembership.membershipBenefits = state.selectedMembership.membershipBenefits.filter(b => b.idBenfit !== idBenfit)
}

export function removeCuttofMembership(state, cuttoff) {
    const { id } = cuttoff
    state.selectedMembership.membershipCutoff = state.selectedMembership.membershipCutoff.filter(c => c.id !== id)
}

export function addRemoveNewBenefitMembership(state, payload) {
    const { isChecked, idBenefit } = payload
    if(isChecked){
        state.selectedMembership.newBenefits = [...state.selectedMembership.newBenefits, {...payload } ]
    }
    if(!isChecked){
        state.selectedMembership.newBenefits = state.selectedMembership.newBenefits.filter(b => b.idBenefit !== idBenefit)
    }
}

export function updateNewBenefitMembership(state, payload) {
    const { idBenefit, qty, discount } = payload
    const idx = state.selectedMembership.newBenefits.findIndex( b => b.idBenefit === idBenefit )
    state.selectedMembership.newBenefits[idx].qty = qty
    state.selectedMembership.newBenefits[idx].discount = discount
}

//gestion interna
export function addRemoveNewCutoffMembership(state, payload) {
    const { isChecked, idCutoff } = payload
    if(isChecked){
        state.selectedMembership.newCutoffs = [...state.selectedMembership.newCutoffs, {...payload } ]
    }
    if(!isChecked){
        state.selectedMembership.newCutoffs = state.selectedMembership.newCutoffs.filter(c => c.idCutoff !== idCutoff)
    }
}

export function updateNewCutoffMembership(state, payload) {
    const { idCutoff, days } = payload
    const idx = state.selectedMembership.newCutoffs.findIndex( b => b.idCutoff === idCutoff )
    state.selectedMembership.newCutoffs[idx].days = days
}

export function addPolicyMembership(state, payload) {
    state.selectedMembership.newPolicies.push(payload)
}
export function removePolicyMembershipTemporal(state, key) {
    state.selectedMembership.newPolicies = state.selectedMembership.newPolicies.filter(policy => policy.key != key)
}
export function setSelectedCondo(state, selectedCondos) {
    state.selectedCondos = selectedCondos
}

export function setCondoFees(state, condominalFees) {
    state.selectedCondos.condominalFees = condominalFees
}

export function setSelectedSubCondo(state, selectedSubCondos) {
    state.selectedSubCondos = selectedSubCondos
}
export function setSelectedHousing(state, selectedHousing) {
    state.selectedHousing = selectedHousing
}

export function setSelectedSeason(state, selectedSeason) {
    state.selectedSeason = selectedSeason
}

export function setSelectedCutoff(state, selectedCutoff) {
    state.selectedCutoff = selectedCutoff
}
export function setSelectedOwner(state, selectedOwner) {
    state.selectedOwner = selectedOwner
}
export function updateHousingListOwner(state, housingList) {
    state.selectedOwner.optionsHousingList = housingList
    state.selectedOwner.housing = housingList
}

export function setSelectedOwnerPassword(state, owner) {
    state.selectedOwnerPassword = owner
}
export function setSelectedRentalPool(state, selectedRentalPool) {
    state.selectedRentalPool = selectedRentalPool
}

export function setQueryFilter(state, params) {
    state.filter.query = params
}

export function setQueryPendingRsvs(state, params) {
    state.filter.queryPendingRsvs = params
}

export function setSelectedNewsLetters(state, selectedNewsLetters) {
    state.selectedNewsLetters = selectedNewsLetters
}

export function setSelectedHotelImg(state, selectedHotelImg) {
    state.selectedHotelImg = selectedHotelImg
}

export function setOwners(state, owners) {
    state.owners =null
    state.owners = owners
}

export function setOwnerHousing(state, housing) {
    state.owners.housing = housing
    console.log(housing);
    state.owners.optionsHousingList = housing.map(house => {
        return {...house, status: true}
    })
}

export function setOwnersBookings(state, ownersBookings) {
    state.ownersBookings = ownersBookings
}

export function setOwnersBookingsId(state, ownersBookings) {
    state.ownersBookings=state.ownersBookings.filter(b => b.id !== ownersBookings.id)
    state.ownersBookings.unshift({...ownersBookings })
}

export function setSelectedOwnerBooking(state, selectedOwnerBooking) {
    state.selectedOwnerBooking = selectedOwnerBooking
}

export function setOwnersBookingsFilters(state, ownerBookingFilters) {
    state.ownerBookingFilters = ownerBookingFilters
}

export function setIsLoadingOwnersBookings(state, boolean) {
    state.isLoadingOwnerBookings = boolean
}
export function setOwnersBookingsFatsFilters(state, ownerBookingFastFilter) {
    state.ownerBookingFastFilter = ownerBookingFastFilter
}
export function setAuthorization(state, payload) {
    if(payload!=null){
        const { authorization, blackoutValid,additional,nightsValid,cutoff,info,nightsTA } = payload
        state.authorization = authorization
        state.authorizationList.authorization = authorization == false ? 1 : 2
        state.authorizationList.blackoutValid=blackoutValid
        state.authorizationList.additional= additional == false ? 1 : 2
        state.authorizationList.nightsValid=nightsValid
        state.authorizationList.cutoff=cutoff
        state.authorizationList.highSeason=nightsTA
        state.reasonAuthorization=info
    }
}


export function setRoomsDetail(state, bookingInfo) {

    const { benefit, orderDetailBookings,  } = bookingInfo

    const benefitList = []
    var nuevoArray    = []
	var arrayTemporal = []

    benefit.forEach(item1 => {
	    arrayTemporal = nuevoArray.filter(resp => resp.benefit.code == item1.benefit.code).length
	    if(arrayTemporal > 0) null
        else nuevoArray.push(item1)
    })

	var benefitqTemporal = []
    var totalBenefit = []
    var infoBenefit = {}
    let balanceSum = 0;
    let benefitqtySum = 0;
    let usedSum = 0;

    nuevoArray.forEach(item2 => {
	    benefitqTemporal = benefit.filter(resp => resp.benefit.code == item2.benefit.code)
	    if(benefitqTemporal.length>0){
            balanceSum =0
            benefitqtySum=0
            usedSum =0
            infoBenefit =0

            benefitqTemporal.forEach(item => {
                balanceSum += item.benefit.balance;
                benefitqtySum+= item.benefit.benefitqty
                usedSum +=  item.benefit.used;
                infoBenefit =  item.benefit
            })

	        totalBenefit.push({"benefit":{...infoBenefit,"balance":balanceSum,"used":usedSum,"benefitqty":benefitqtySum}})
	    }
    });

    totalBenefit.forEach(item => {
        benefitList.push({
            ...item.benefit,
            selected:false
        })
    })

    state.bookingInfo = orderDetailBookings
    state.bookingInfo.categoryroomName = bookingInfo?.categoryroomName
    const bookingBenefits = benefitList?.filter(item => item.groupsTypes == 'Noches')

    let alreadySelected = false
    bookingBenefits?.forEach((item, index) => {
        if (index == 0 && item.code !== 'TA' ){
            item.selected = true
            alreadySelected = true
        }
        else if (item.code !== 'TA' && !alreadySelected) {
            item.selected = true
            alreadySelected = true
        }
    })

    state.bookingInfo.benefits = bookingBenefits
    state.bookingInfo.rangeDate=`${ state.bookingInfo.datein} to ${ state.bookingInfo.dateout}`
    const listInfo = []
    listInfo.push({
        name : bookingInfo.orderDetailBookings.name,
        lastname :bookingInfo.orderDetailBookings.lastname || 'N/A',
        phone : bookingInfo.orderDetailBookings.phone  || '555555555' ,
        email : bookingInfo.orderDetailBookings.email,
        ismain:true
    })

    state.bookingInfo.adultsDetail=listInfo
    state.bookingInfo.invitedAdults=listInfo

    state.bookingInfo.infoAdults=listInfo
    state.bookingInfo.reservetype=1,
    state.bookingInfo.occupanttype=1
    state.bookingInfo.year=bookingInfo.year
}
export function setRoomsDetailWeb(state, bookingInfo) {

    const { benefit, orderDetailBookings,  } = bookingInfo

    const benefitList = []

    var nuevoArray    = []
	var arrayTemporal = []
	for(var i=0; i<benefit.length; i++){
	    arrayTemporal = nuevoArray.filter(resp => resp.benefit.code == benefit[i].benefit.code)
	    if(arrayTemporal.length>0){

	    }else{
	        nuevoArray.push(benefit[i])
	    }
	}

	var benefitqTemporal = []
    var totalBenefit = []
    var infoBenefit = {}
    let balanceSum = 0;
    let benefitqtySum = 0;
    let usedSum = 0;
	for(var i=0; i<nuevoArray.length; i++){
	    benefitqTemporal = benefit.filter(resp => resp.benefit.code == nuevoArray[i].benefit.code)
	    if(benefitqTemporal.length>0){
            balanceSum =0
            benefitqtySum=0
            usedSum =0
            infoBenefit =0

            benefitqTemporal.forEach(item => {

                balanceSum += item.benefit.balance;
                benefitqtySum+= item.benefit.benefitqty
                usedSum +=  item.benefit.used;
                infoBenefit =  item.benefit


            })



	        totalBenefit.push({"benefit":{...infoBenefit,"balance":balanceSum,"used":usedSum,"benefitqty":benefitqtySum}})
	    }else{

	    }
	}

    totalBenefit.forEach(item => {
            if ( orderDetailBookings.benefit==item.benefit.code ){
                benefitList.push({
                    ...item.benefit,
                    selected:true

                     })
            }else{
                benefitList.push({
                    ...item.benefit,
                    selected:false
                     })
            }

            })

// console.log(benefitList)

    state.bookingInfo.adultsDetail=orderDetailBookings
    state.bookingInfo.invitedAdults=orderDetailBookings
    state.bookingInfo = orderDetailBookings
    state.bookingInfo.numberChildren=state.bookingInfo.children
    state.bookingInfo.benefits = benefitList.filter(item => item.groupsTypes == 'Noches')
    state.bookingInfo.rangeDate=`${ state.bookingInfo.datein} to ${ state.bookingInfo.dateout}`
// console.log(state.bookingInfo.benefits)

}


export function clearDataSearchForm(state) {

    state.bookingInfo.owner=""
    state.bookingInfo.adults = 1
    state.bookingInfo.dingusCode = ''
    state.bookingInfo.hotel = ''
    state.bookingInfo.language = 'en'
    state.bookingInfo.nights = 0
    state.bookingInfo.numberChildren = 0
    state.bookingInfo.ninos = []
    state.bookingInfo.promoCodeManual = ''
    state.bookingInfo.rangeDate = ''
    state.bookingInfo.roomCode = ''
    state.bookingInfo.vendorcode = ''
    state.bookingInfo.idRsv = ''
    state.bookingInfo.adultsDetail=null
    state.bookingInfo.adultsDetail=null
    state.bookingInfo.infoAdults=null
    state.bookingInfo.benefits=''

}

export function setAgeChildsBookOwner(state, ageChilds) {
    state.bookingInfo.ninos = ageChilds
}

export function setNumberChildrenBookOwner(state, number) {
    state.bookingInfo.numberChildren = number
}
export function setSpecificAgeChilBookOwner(state, data) {
    const { key, edad,name } = data
    const item = state.bookingInfo.ninos.find( nino => nino.key === key )
    item.edad = edad
    item.name = name
    item.lastname = lastName
    item.phone = phone
    item.email = email
}

export function setAdultsBookOwner(state, ageAdult) {

    state.bookingInfo.infoAdults = ageAdult
    state.bookingInfo.adultsDetail= ageAdult
    state.bookingInfo.invitedAdults= ageAdult

}
export function setNumberAdultsBookOwner(state, number) {
    state.bookingInfo.adults = number
}
export function setSpecificAdultsBookOwner(state, data) {
    const { key, edad, name,lastName,phone,email } = data
    const item = state.bookingInfo.infoAdults.find( adult => adult.key === key )
    item.edad = edad
    item.name = name
    item.lastname = lastName
    item.phone = phone
    item.email = email

}

export function setOwnerBookingToEdit(state, booking) {
    state.selectedOwnerBookingEdit = booking
}


export function addBenefitsProducts(state, payload) {
    const { status, id } = payload
    const idx = state.benefitsProducts.findIndex( b => b.id === id )
    state.benefitsProducts[idx].status =status  == false ? true :false
}

export function setIsLoadingPendingReservations(state, bool) {
    state.isLoadingPendingReservations = bool
}

export function setPendindAuthRsvs(state, requestPendindAuthRsvs) {
    state.requestPendindAuthRsvs = requestPendindAuthRsvs
}

export function setPendingReservations(state, pendingReservations) {
    state.pendingReservations = pendingReservations
}

export function changeStatusOwnerBooking(state, payload) {
    const { id, status, statusname} = payload
    const idx = state.ownersBookings.findIndex(ownerBook => ownerBook.id === id )
    state.ownersBookings[idx].status = status
    state.ownersBookings[idx].statusname = statusname
}

export function setBenefitYearList(state, payload){
    const { year, benefit} = payload
    const benefitList = []
    let alreadySelected = false
    benefit.forEach(item => {
        benefitList.push({
            ...item.benefit,
            selected:false
        })
    })
    const bookingBenefits = benefitList.filter(item => item.groupsTypes == 'Noches')

    bookingBenefits.forEach((item, index) => {
        if (index == 0 && item.code !== 'TA' ){
            item.selected = true
            alreadySelected = true
        }
        else if (item.code !== 'TA' && !alreadySelected) {
            item.selected = true
            alreadySelected = true
        }
    })
    state.bookingInfo.year=year
    state.bookingInfo.benefits =bookingBenefits
}

export function setBenefitSelected(state,benefit ){
    state.benefitId = benefit
}

export function setBenefitSelectedEdit(state, benefit ){
    const bookingBenefits  = state.bookingInfo.benefits.find( item => item.benefit == benefit)
    bookingBenefits.selected = true
}

export function setcanChangeSatus(state, canChangeSatus ){
   state.bookingInfo.canChangeSatus=canChangeSatus
}

export function setCondominalFeesYearsList(state){
    const initYear = new Date().getFullYear() - 1
    let years = []

    for (let i = 0; i < 7; i++) {
        years.push({
            value: initYear + i,
            label: `Cuotas de ${initYear + i}`,
            disabled: false
        })
    }

    if (state?.selectedCondos?.condominalFees?.length > 0) {
        state?.selectedCondos?.condominalFees?.forEach(condoFee => {
            let existingYear = years.find(year => year.value == condoFee.yearCondoFee)
            if (existingYear) existingYear.disabled = true
            else years.push({
                value: condoFee?.yearCondoFee,
                label: `Cuotas de ${condoFee?.yearCondoFee}`,
                disabled: condoFee?.yearCondoFee < initYear
            })
        })
    }

    state.selectedCondos.yearsList = years?.sort(function(a, b){return a?.value - b?.value})
}

export function setSocialNetworks(state, socialNetworks) {
    state.socialNetworks = socialNetworks
}

export function setInfoTransfer(state, infoTransfer){
    state.infoTransfer = infoTransfer
}

export function unsetInfoTransfer(state){
    state.infoTransfer = [{
        arrivalairline: '',
        arrivalflight: '',
        arrivaldate: '',
        arrivaltime: '',
        departureairline: '',
        departureflight: '',
        departuredate: '',
        departuretime: '',
        baggage: '',
        babychair: '',
        arrivalorder: '',
        departureorder: '',
    }]
}

export function setInfoMeal(state, infoMeal){
    state.infoMeal = infoMeal
}

export function unsetInfoMeal(state){
    state.infoMeal = {
        mealplan: '',
        allergies: '',
        specialMeal: '',
    }
}

export function setInfoPreferences(state, infoPreferences){
    state.infoPreferences = infoPreferences
}

export function unsetInfoPreferences(state){
    state.infoPreferences = {
        ownerbox: '',
        requirementsroom: '',
        additionalactivities: '',
        specialcelebrations: '',
        extrarooms: '',
    }
}

export function setInfoRestaurants(state, infoRestaurants){
    
    state.infoRestaurants = infoRestaurants
}

export function unsetInfoRestaurants(state){
    state.infoRestaurants = [{
        date: '',
        restaurant: '',
        schedule: '',
        confirmation: '',
    }]
}

export function setTransactionsAgreementsList(state, payload){
    state.transactionsAgreementsList = payload
}
