import store from '../../../../store/index'

//Descuento aplicado a toda la orden
export function totalDiscountCuenta(state) {

    let sum = 0;
    const decimalDiscount = parseFloat(state.cart.totalBreakdown.discountApplied) / 100;
    state.cart.items.forEach((product) => {
        sum += (parseFloat(product.saleprice) * parseFloat(product.qty));
    })
    return (Math.round(sum * decimalDiscount * 100) / 100).toFixed(2);
}

//sumatoria en el total de descuento en cada producto,se ocupa para obtener el subtotal

export function totalBreakdown(state) {
    return (Math.round(state.cart.totalBreakdown.total * 100) / 100).toFixed(2);
}

//Suma de listado de formas de pago aplicado a toda la orden
export function totalBalance(state) {

    let sum = 0;
    var total = parseFloat((state.cart.firstPayTotal).toFixed(2));
    if (state.cart.partial == 0) {
        total = parseFloat((Math.round(state.cart.totalBreakdown.total * 100) / 100).toFixed(2));
    }

    state.cart.cliente.paymentList.forEach((product) => {
        sum += (parseFloat(product.total));
    })
        
    if (state.cart.firstPay != 0) {
        total = state.cart.firstPayTotal
    }
    return (parseFloat(total - sum)).toFixed(2);
}

export function totalCredit(state){
    

    return state.cart.cliente.creditBalance
    
}
export function totalResortCredit(state){
    

    return state.cart.cliente.resortCreditBalance
    
}

export function onlyBookings(state) {
    let items = []
    const bookings = state.cart.items.filter(item => item.modelType === 'booking')
    bookings.forEach(({ detaildisplay, uuid }) => {
        items.push({ name: detaildisplay, key: uuid })
    })
    return items
}

export function onlyKeyBookings(state) {
    let items = []
    const bookings = state.cart.items.filter(item => item.modelType === 'booking' && item.categoryName != 'ReservasOwners')
    bookings.forEach(({ uuid }) => {
        items.push(uuid)
    })
    return items
}

export function onlyKeyOwnerBookings(state) {
    let items = []
    const bookings = state.cart.items.filter(item => item.categoryName == 'ReservasOwners')
    bookings.forEach(({ uuid }) => {
        items.push(uuid)
    })
    return items
}

export function selectedOwnerBookingBenefit(state) {
    return state.cart.selectedBenefitSelectedInOwnerBooking
}
// state.equipos = [...new Set(state.equipos.concat(respuesta.data.devices))]

// Totales globales
export function total(state){
    return ((Math.round(parseFloat(state.cart.totalBreakdown.total) * 100) / 100).toFixed(2))
}
export function subTotal(state){
    return ((Math.round(parseFloat(state.cart.subtotal) * 100) / 100).toFixed(2))
}
export function subTotalParcial(state){
    return ((Math.round(parseFloat(state.cart.firstPay) * 100) / 100).toFixed(2))
}
export function subTotalParcialBooking(state){
    return ((Math.round(parseFloat(state.cart.firstPayTotal) * 100) / 100).toFixed(2))
}
export function totalDiscount(state){
    return ((Math.round(parseFloat(state.cart.totalDiscount) * 100) / 100).toFixed(2))
}

// Totales por divisa
export function tc(state){
    const currencieValor = state.cart.cliente.currency ? state.cart.cliente.currency.value : 1
    return ((Math.round(parseFloat(currencieValor) * 100) / 100).toFixed(2))
}

export function totalDivisa(state){
    const currencieValor = state.cart.cliente.currency ? state.cart.cliente.currency.value : 1
    return ((Math.round((parseFloat(state.cart.totalBreakdown.total) * parseFloat(currencieValor)) * 100) / 100).toFixed(2))
}

export function totalDivisaParcial(state){
    const currencieValor = state.cart.cliente.currency ? state.cart.cliente.currency.value : 1
    return ((Math.round((parseFloat(state.cart.firstPayTotal) * parseFloat(currencieValor)) * 100) / 100).toFixed(2))
}

export function totalDivisaBooking(state){
    const currencieValor = state.cart.cliente.currency ? state.cart.cliente.currency.value : 1
    return ((Math.round((parseFloat(state.cart.firstPay) * parseFloat(currencieValor)) * 100) / 100).toFixed(2))
}
