
export function filteredDetailProfit( state ) {
    let costs = state.costs        
    if( state.filter.queryConsts.length > 1 && costs.length!=0) {
        costs = state.costs.filter( costs => costs.ownername.toLowerCase().includes( state.filter.queryConsts.toLowerCase() ))        
    } 
    return costs
}


export function filteredFees( state ) {
    let fees = state.fees        
    if( state.filter.queryFees.length > 1) {
        fees = state.fees.filter( feesItem => feesItem.valueType.toLowerCase().includes( state.filter.queryFees.toLowerCase() ))        
    } 
    return fees
}

export function filteredDetailPerformance( state ) {
    let performance = state.performance        
    if( state.filter.queryPerformance.length > 1 && performance.length!=0) {
        performance = state.performance.filter( performance => performance.ownername.toLowerCase().includes( state.filter.queryPerformance.toLowerCase() ))        
    } 
    return performance
}