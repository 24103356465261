
export default [
    {
        path: '/operations',
        name: 'operations',
        component: () => import('@/modules/operations/views/Operations'),                
        meta: {
            requiresAuth: true,
            // permissions:['operation_menu_show_operation']
        },
    },
    {
        path: '/operations/incidents/:order/:detail',
        name: 'detail-incidents',
        component: () => import('@/modules/operations/views/Incidents'),                        
        meta: {
            requiresAuth: true,           
            // permissions:['operation_menu_show_operation']
        },
        props: ( route ) => {
            return {
                id: route.params.id
            }
        },
    },   
]

