
export default [
    {
        path: '/groups',
        name: 'groups',        
        component: () => import(/*webpackChunkName: "GroupsView"*/'@/modules/groups/views/ListRooms'),        
        meta: {
            requiresAuth: true,   
            // permissions: ['groups_menu_show_group']                     
        },      
    }    
]