
export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import(/*webpackChunkName: "Login"*/'@/modules/auth/views/Login'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            requiresAuth: false,            
        },
    },
    {
        path: '/not-authorized',
        name: 'error-403',
           resource: 'Auth',
         component: () => import(/*webpackChunkName: "NotAuthorized"*/'@/modules/auth/views/NotAuthorized'),
        meta: {
            layout: 'full',
            requiresAuth: false,            
        },
    },
    {
        path: '/thank-you',
        name: 'page-thanks',        
        component: () => import(/*webpackChunkName: "Thanks"*/'@/modules/auth/views/Thanks'),
        meta: {
            layout: 'full',
            requiresAuth: false,            
        },
    },
    {
        path: '/authorize-action',
        name: 'page-auth',        
        component: () => import(/*webpackChunkName: "AuthorizeAction"*/'@/modules/auth/views/AuthorizeAction'),
        meta: {
            layout: 'full',
            requiresAuth: false,            
        },
    }  
]