import store from '../../../store/index'

export function filteredOrders( state ) {
    let rooms = state.rooms
    //con esto filtro los resultado por el name del order, no es case sensitive
    if( state.filterO.query.length > 1 ){
        rooms = rooms.filter( room => room.roomCode.toLowerCase().includes( state.filter.query.toLowerCase() ) )
    }
    return rooms;
}

export function filteredOrdersItems( state ) {    
    return state.rooms;
}

export function detailOrderGetter( state ) {    
    return state.detailOrder;
}

