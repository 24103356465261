import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import IdleVue from 'idle-vue'
import { can } from '@/directives/acl' //para gestion del dom con permisos

// import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import Modal from 'ant-design-vue/lib/modal' //lo llamo aqui porque daba problemas en vue-ant-desing.js

// 3rd party plugins
require('./libs')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Modal)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')
Vue.config.productionTip = false
//timer de actividad de usuario idle-vue
const eventsHub = new Vue()
const idleTimeUser = process.env.VUE_APP_IDLE_TIME_USER_CONNECTED || 1800000
Vue.use(IdleVue, { eventEmitter: eventsHub, store, idleTime: idleTimeUser, startAtIdle: false})

Vue.directive('can', can) //registro la directiva

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
