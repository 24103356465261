export default {
    categories:{
        hotelServices: [],
        onlyCategories: [],
        withSubCategories: [],
        categoryServices: [],
    },
    marketpermissions:[],
    airlines:[],
    currencies:[],
    customerTypes:[],
    departaments:[],
    destinations:[],
    discounts:[],
    extraFieldsTypes:[],      
    hotels:[],
    isLoadingCategories: false,    
    languages:[],    
    paymentMethods:[],
    useCredit:false,
    promoCodes:[],
    selectedCategory: null,
    terms:[],
    payloadParamsRates:null,
    queryParams: {
        adults:1,
        children:0,
        date:null
    }      
        
}