import { defaultPermissions } from '@/data/data'

import { generateNavigationUser } from '@/helpers/authHelper'

import Vue from 'vue'
import { showAlertMessage, consoleMsgFinally, getCanalDefault } from '@/helpers/helpers'

export async function signIn(context, user) {
    // console.log(aliasPermissions)
    try {
        context.commit('auth/setLoadingAuth', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: '/login/',
            data: user
        })
        //desestructuración de lo que me regresa en el respose payload
        const { id, name, lastname, group, fp, puntosventa, infocanal, permisosFront, token } = response && response.data
        const permissionsToUse = permisosFront.length > 0 ? permisosFront : defaultPermissions
        //ordeno pv en orden alfabetico
        puntosventa.sort(function (a, b) { if (a.name > b.name) { return 1 } if (a.name < b.name) { return -1 } return 0 })

        user.idUser = id
        user.name = name
        user.lastname = lastname
        user.group = group || ''
        user.fp = fp.sort((a, b) => a.name > b.name ? 1 : -1) //ordeno alfabeticamente
        user.fpBackup = fp.sort((a, b) => a.name > b.name ? 1 : -1) //ordeno alfabeticamente
        user.canalventa = getCanalDefault(infocanal)?.canalventa
        user.sectionCanal = getCanalDefault(infocanal)?.seccioncanalventa
        user.token = token

        user.puntosventa = puntosventa
        user.channels = infocanal
        context.commit('groups/setUserLandingTrue', true, { root: true })
        if (user.canalventa && user.puntosventa.length > 0) {
            delete user.password //del user elimino el password, para guardarlo en el state
            context.commit('setUser', user)
            context.commit('setPermissionsUser', permissionsToUse)
            context.commit('setNavigationUser', generateNavigationUser(permissionsToUse ) ) //genero lst navegacion user por sus permisos
            context.commit('shop/setCanalVenta', user.canalventa.id, { root: true })
            context.commit('shop/setSellerCanalVenta', user.canalventa.id, { root: true })
            context.commit('start/setPaymentMethods', user.fp, { root: true })
            context.commit('shop/setidUserClient', user.idUser, { root: true })

            if (user.puntosventa.length == 1) {
                context.commit('shop/setPuntoDeVenta', puntosventa[0].id, { root: true })
            }
        } else {
            const text = '😪😪 Aun no tiene un canal de venta y/o punto de venta para su cuenta, contacte a sistemas para que se le asigne respectivamente..'
            showAlertMessage('Error', 'BellIcon', text, 'danger', 4000, 'bottom-right')
        }
    } catch (error) {
        if (error.response) {
            if (error.response.statusText == 'Bad Request') {
                showAlertMessage(`${error.response.statusText || 'No existe usuario'} ${': datos incorrectos'}`, 'InfoIcon', '😪😪 Usuario o Contraseña incorrectos, o el usuario no existe', 'danger', 5000, 'bottom-right')
            }
        }
    } finally {
        context.commit('auth/setLoadingAuth', false, { root: true }) //se terminar de mostrar el loader
        consoleMsgFinally('auth/signIn', 'Peticion de datos de usuario terminada')
    }
}

export async function fetchPaymentMethods(context, payload) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/paymentmethodbysalechannel/',
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const {status, data} = respuesta
        if (status) context.commit('setUserFP', data)
        return status
    } catch (error) {
        const respuesta = error.response && error.response.data
        console.warn(respuesta.message)
        return false
    } finally {
        consoleMsgFinally('auth/fetchPaymentMethods', 'La peticion para solicitar los métodos de pago del canal de venta ha terminado')
    }
}

export async function fetchCatalogAuthorization(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: 'fivesClub/getCatalogAuthorization/',
            data:payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        const respuesta = error.response && error.response.data
        console.warn(respuesta?.message || error)
        return respuesta || false
    } finally {
        consoleMsgFinally('auth/fetchCatalogAuthorization', 'La peticion para solicitar los métodos de pago del canal de venta ha terminado')
    }
}