import store from '../../../store'
import { showAlertMessage } from '@/helpers/helpers'

const isAuthenticatedGuard = (to, from, next) => {
    const { isLogged } =  store.state.auth
    const { cart } =  store.state.shop
    if ( isLogged  ) {
        if (to.path == "/sales/payment" && (cart?.items?.length == 0 || cart?.requiredFields?.length > 0) ) {
            let msg = ''
            if (cart?.items?.length == 0) msg = `Seleccione por lo menos un producto para acceder a esta sección.`
            if (cart?.requiredFields?.length > 0) msg = `No puede acceder a esta sección hasta que todos los productos hayan sido llenados correctamente.`

            showAlertMessage( 'Acceso denegado', 'AlertCircleIcon', msg, 'warning', 5000, 'bottom-right')
            next( {path: '/sales/order'} )
        } else next()
    } else next( {name: 'auth-login'} )
}

export default isAuthenticatedGuard
