
export function setTransactions(state, transactions) {
    state.transactions = transactions
}

export function setDetailTransactions(state, detailTransactions) {
    state.detailTransactions = detailTransactions
}

export function setRequestDetailTransactions(state, request) {
    state.requestDetailTransactions = request
}

export function setRequestTransactions(state, request) {
    state.requestTransactions = request
}
