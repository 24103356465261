import { stringAleatorio, toJson } from './helpers'
import { formatGuestsInBooking, setGuestsInPayload } from './shopHelper'


const createItemsFromOrder = ( items ) => {

    const orderDetail = {
        events : [],
        packages : [],
        products : [],
        bookings : [],
        transfers : [],
        experiences : [],
        transactions: []
    }

    items.forEach((item) => {
        if( item.booking?.id ){
            item.booking.rangeDate = `${item.booking.dateIn} to ${item.booking.dateOut}`
            item.booking.rooms = []
            item.booking.previousDateIn = item.booking.dateIn
            item.booking.previousDateOut = item.booking.dateOut
            item.booking.previousNights = item.booking.nights
            item.booking.previousAdults = item.booking.adults
            item.booking.isLoadingDataBooking = false
            item.booking.isUpdatingDataBooking = false
            orderDetail.bookings.push( item )
        } else delete item.booking

        if( item.product ){
            if ( item.categoryname && item.categoryname == 'Transfer' ) orderDetail.transfers.push( item )
            else {
                item.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}
                orderDetail.products.push( item )
            }
        }

        if( item.package ) orderDetail.packages.push( item )
        if( item.event ) orderDetail.events.push( item )
        if( item.experience ) orderDetail.experiences.push(item)
    })
    return orderDetail
}

const formatTransactions = ( transactions ) => {
    let listTransactions = []
    transactions.forEach((transaction) => {
        if ( transaction.transactionstatus.name == "Approved" || transaction.transactionstatus.name == "Canceled") listTransactions.push(transaction)
    })
    return listTransactions
}
const checkIfOrderDetailHasItems = ( orderDetail ) => {
    const { events, packages, products, bookings, transfers, experiences } = orderDetail
    let orderDetailHasItems = false
    if( events.length > 0 || packages.length > 0 || products.length > 0 || bookings.length > 0 || transfers.length > 0 || experiences.length > 0 ) orderDetailHasItems = true
    return orderDetailHasItems
}

const formatBookings = ( rooms, isPromo, datos) => {
    const { dateIn, dateOut } = datos
    rooms.forEach( room => {
        room.detailSelected = room.rates.length ? room.rates[0] : []
        room.priceInit = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'
        room.priceInitDefault = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'
        room.detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }
        room.keyRoom = stringAleatorio()
        room.dateIn = dateIn
        room.dateOut = dateOut
        room.promoCodeManual = datos.promoCodeManual
        room.showingRates = true
        room.rates.forEach( ( rate ) => {
            const esPromo = isPromo ? isPromo : false
            rate.allowPriceMatch = true
            rate.divisa = { currencyid: 1, code: "USD", value: "1.00" }
            rate.isPromo = esPromo
            rate.manualPrice = ''
            rate.priceInit = rate.AmountAfterTax
            rate.priceMatchTax = ''
        })
    })
    return rooms
}

const formatRoomPromoCode = ( room, datosRequest, message, isPromo ) => {

    const { priceInit, keyRoom, allowPriceMatch, manualRate} = datosRequest
    room.detailSelected = room.rates[0] || []
    room.detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }
    room.keyRoom = keyRoom || ''
    room.priceInit = priceInit
    room.showingRates = true
    //agrego cosas al listado de rates
    room.rates.forEach( ( rate ) => {
        const priceMatchTax = !message ? rate.AmountAfterTax : "0.00"
        const esPromo = isPromo ? isPromo : false
        rate.divisa = { currencyid: 1, code: "USD", value: "1.00" }
        rate.priceMatchTax = priceMatchTax
        rate.priceInit = priceInit || ''
        rate.manualPrice = manualRate
        rate.allowPriceMatch = allowPriceMatch
        rate.isPromo = esPromo
    })
    return room
}

const makePayloadRoomRate = ( data ) => {
    const { previousRooms, newRoom, keyRoom } = data
    const room = previousRooms.find( room => room.keyRoom === keyRoom )
    room.rates = newRoom.rates
    room.priceInit = newRoom.priceInit
    room.detailSelected = newRoom.detailSelected
    return toJson(previousRooms)
}

const formatInfoRates = ( rates ) => {
    const list = []
    rates.forEach((rate) => {
        const { DateIn, DateOut, Rate  } = rate
        const item = { checkin: DateIn, checkout: DateOut, rate: Rate }
        list.push(item)
    })
    return toJson(list)
}

const formatOrderItems = (data) => {
    let orders = data
    let ordersItems = []

    orders.forEach(order => {
        order.orderDate = order.orderDate.split('T')[0]
        order.customername = order.customer.name + ' ' + order.customer.lastname
        order.vendedor = order.saleuser_name + ' ' + order.saleuser_lastname

        const invoice = order.invoice
        const cliente = order.customername
        const tipoCliente = order.customertype || ''
        const vendedor = order.saleuser_name + ' ' + order.saleuser_lastname
        const fechaOrden = order.orderDate || ''
        const fechaPago = order.paymentdate || 'No pagado'
        const operationdate = order.operationdate || ''
        const metodoPago = order.paymentmethod_name
        const referencia = order.paymentreference
        // const vBruto = order.grossSale
        // const vDescuento = order.discount
        const totalSale = order.totalSale
        const estado = order.orderstatusname
        const moneda = order.currency.name

        let confirmados = 0
        let operados = 0
        let itemsInOrder = 0

        order.detail.forEach(detalle => {
            itemsInOrder++
            if (detalle.confirmationdate) confirmados++
            if (detalle.operationby) operados++
            ordersItems.push({
                key: Math.random().toString(36).substring(2, 9),
                invoice,
                sku: detalle.productSKU,
                productoDetalle: detalle.itemsold,
                cliente,
                tipoCliente,
                vendedor,
                numeroCupon: detalle.coupon,
                fechaOrden,
                fechaPago,
                operationdate,
                metodoPago,
                referencia,
                qty: detalle.qty,
                unitPrice: detalle.unitPrice,
                vDescuento: (Math.round(detalle.discount * 100) / 100).toFixed(2),
                vTotal: (Math.round(detalle.total * 100) / 100).toFixed(2),
                moneda,
                totalSale,
                estado
            })
        })

        order.itemsInOrder = itemsInOrder
        order.confirmados = confirmados
        order.operados = operados

    })

    orders.sort((x,y) => {return y.id - x.id}) //ordeno por id, de manera descendente
    return {orders, ordersItems}
}

const formatModelIntoHistoryLog = (log) => {
    const {typeObject, details} = log
    // const {booking, itinerary} = detail
    let items = []

    details.forEach(detail => {
        if (typeObject == 'Orders') items.push({
            id: detail.id,
            payment: {
                firstpay: detail?.firstpay || '',
                customeragent: detail?.customeragent || '',
                paymentdate: detail?.paymentdate || '',
                paymentmethod: detail?.paymentmethod || '',
                paymentreference: detail?.paymentreference || '',
                paymentmethod2: detail?.paymentmethod2 || '',
                paymentreference2: detail?.paymentreference2 || '',
                balance: detail?.balance || '',
                totalPaid: detail?.totalPaid || ''
            },
            sale: {
                customer: detail?.customer || '',
                saleusername: detail?.saleusername || '',
                totalSale: detail?.totalSale || '',
                hotel: detail.hotel || '',
                orderStatus: detail.orderStatus
            },
            logs: {
                logcreatedAt: detail?.logcreatedAt || '',
                logcreatedBy: detail?.logcreatedBy || '',
                logoperation: detail?.logoperation || ''
            }
        })
        else if (typeObject == 'booking') items.push({
            id: detail.id,
            huesped: {
                nameprefix: detail?.nameprefix || '',
                givenname: detail?.givenname || '',
                surname: detail?.surname || '',
                email: detail?.email || '',
                phonenumber: detail?.phonenumber || '',
            },
            booking: {
                hotel: detail?.hotel || '',
                datein: detail?.datein || '',
                dateout: detail?.dateout || '',
                nights: detail?.nights || '',
                adults: detail?.adults || '',
                children: detail?.children || '',
            },
            datastatus: {
                bookingstatus: detail?.bookingstatus || '',
                reference: detail?.reference || '',
                mealplancode: detail?.mealplancode || '',
                promotioncode: detail?.promotioncode || '',
                rateplancode: detail?.rateplancode || '',
                roomtypename: detail?.roomtypename || '',
                netTotal: detail?.netTotal || ''
            },
            logs: {
                logcreatedAt: detail?.logcreatedAt || '',
                logcreatedBy: detail?.logcreatedBy || '',
                logoperation: detail?.logoperation || ''
            }
        })
        else if (typeObject == 'guests') items.push({
            adults: {
                ismain: detail?.ismain || null,
                name: detail?.name || null,
                lastname: detail?.lastname || null,
                address: detail?.address || null,
                email: detail?.email || null,
                phone: detail?.phone || null,
            },
            childs: {
                name: detail?.name || null,
                lastname: detail?.lastname || null,
                edad: detail?.edad || null,
            },
            logs: {
                logcreatedAt: detail?.logcreatedAt || '',
                logcreatedBy: detail?.logcreatedBy || '',
                logoperation: detail?.logoperation || ''
            }
        })
        else if (typeObject == 'itinerary') items.push({
            id: detail.id,
            beneficiary:{
                name: detail?.benefitedName || '',
                lastname: detail?.benefitedLastName || '',
            },
            service: {
                pax: detail?.pax || '',
                trip: detail?.trip || '',
                ifrom: detail?.ifrom || '',
                ito: detail?.ito || '',
            },
            flight: {
                flight: detail?.flight || null,
                flighttime: detail?.flighttime || null,
                pickup: detail?.pickup || null,
                typeflight: detail?.typeflight || null,
                airline: detail?.airline || null,
            },
            logs: {
                logcreatedAt: detail?.logcreatedAt || '',
                logcreatedBy: detail?.logcreatedBy || '',
                logoperation: detail?.logoperation || ''
            }
        })
        else items.push({
            id: detail.id,
            notas: {
                note: detail?.note || '',
                clientnote: detail?.clientnote || '',
            },
            operacion: {
                operationdate: detail?.operationdate || '',
                operationtime: detail?.operationtime || '',
            },
            beneficiario: detail?.beneficiario || null,
            totales: {
                total: detail?.total || '',
                totalpay: detail?.totalpay || '',
                unitPrice: detail?.unitPrice || '',
                netTotal: detail?.netTotal || '',
                firstpay: detail?.firstpay || '',
                orderdetailStatus: detail?.orderdetailStatus || ''
            },
            logs: {
                logcreatedAt: detail?.logcreatedAt || '',
                logcreatedBy: detail?.logcreatedBy || '',
                logoperation: detail?.logoperation || ''
            }
        })
    });

    return items
}

const getProductName = (item) => {
    const detail = item.details[0]
    if (item.typeObject == 'booking') return `${detail.roomtypename}: ${detail.roomtypecode} - ${detail.rateplanname} - ${detail.mealplancode}`
    else if (item.typeObject == 'itinerary') return `${detail.benefitedName} ${detail.benefitedLastName} | ${detail.trip}: ${detail.ifrom} → ${detail.ito}`
    else if (detail.name) {
        if (detail.lastname) return `${detail.name} ${detail.lastname}`
        else return detail.name
    }
    else return null
}

const formatGuestsInMySalesBooking = (item) => {
    let newGuestsArray
    let guests = []
    let adults
    let childs

    newGuestsArray = formatGuestsInBooking(structuredClone(item)) // arreglo base para el nuevo formulario

    if (item.guests.length == 0 && !item.guests.adults) null
    else {
        const item2 = structuredClone(item)

        if (item.oldguests) item2.guests = setnewoldarray(item.oldguests, item.guests)

        adults = !item.guests.adults ? item.guests?.filter(adults => adults.adult == true) : item2.guests?.filter(adults => adults.adult == true)
        childs = !item.guests.childs ? item.guests?.filter(childs => !childs.adult) : item2.guests?.filter(childs => !childs.adult)

    }

    newGuestsArray.guests.adults.forEach((adult, index) => {
        guests.push({
            idForm: index,
            id: adults[index]?.id || null,
            mainGuest: adults[index]?.ismain || adult.ismain || false,
            NamePrefix: adult.mainGuest ? item.nameprefix : null,
            GivenName: adults[index]?.name || adult.name || '',
            Surname: adults[index]?.lastname || adult.lastname || '',
            PhoneNumber: adults[index]?.phone || adult.phone || '',
            Email: adults[index]?.email || adult.email || '',
            Address: adults[index]?.address || adult.address || '',
            guestType: adult.mainGuest ? 'Main Guest' : `guest ${index + 1}`,
            Adult: true
        })
    })

    newGuestsArray.guests.childs.forEach((child, index) => {
        guests.push({
            idForm: index,
            id: childs[index]?.id || null,
            GivenName: childs[index]?.name || child.name || '',
            Surname: childs[index]?.lastname || child.lastname || '',
            guestType: `child ${index + 1}`,
            Age: child.Age,
            Adult: false
        })
    })

    item.guests = {
        adults: guests.filter(adults => adults.Adult == true),
        childs: guests.filter(childs => childs.Adult == false)
    }

    return item

}

const setnewoldarray = (oldguests, guests) => {
    if (oldguests && oldguests.length > 0) oldguests.forEach(item => {
        let newguest = item.adult ? guests.adults?.find(guest => guest.id == item.id) : guests.childs?.find(guest => guest.id == item.id)

        if (item.ismain) {
            item.ismain = newguest?.mainGuest || item?.ismain || '',
            item.name = newguest?.GivenName || item?.name || '',
            item.lastname = newguest?.Surname || item?.lastname || '',
            item.email = newguest?.Email || item?.email || '',
            item.phone = newguest?.PhoneNumber || item?.phone || ''
        }
        else {
            item.name = newguest?.GivenName || item?.name || '',
            item.lastname = newguest?.Surname || item?.lastname || ''
        }
    })
    else oldguests = setGuestsInPayload(guests)

    return oldguests
}

export {
    createItemsFromOrder,
    checkIfOrderDetailHasItems,
    formatTransactions,
    formatBookings,
    formatRoomPromoCode,
    makePayloadRoomRate,
    formatInfoRates,
    formatOrderItems,
    formatModelIntoHistoryLog,
    getProductName,
    formatGuestsInMySalesBooking,
    setnewoldarray
}