const makeCategoriesFaq = ( faqArray ) => {    
    const categories = []
    faqArray.forEach( ({ id, categoria, title, subtitle }) => {                 
        categories.push( {id, categoria, title, subtitle }  )          
    }) 
    return categories
}

const makeQuestionsAnswers = ( faqArray ) => {    
    const questionsAnswers = []
    faqArray.forEach( ({ id, preguntas }) => {                 
        questionsAnswers.push( ...getQuestionsAnswers(id, preguntas) )
    }) 
    return questionsAnswers
}

const getQuestionsAnswers = ( idCategory, questionAnswers ) => {    
    const questions = []
    questionAnswers.forEach( ({ id, question, answer }) => {                 
        questions.push( {idCategory, idQuestion: id, question, answer }  )                          
    }) 
    return questions
}
export { 
    makeCategoriesFaq,
    makeQuestionsAnswers
}