export const appVersion = { version: '2.4.5', date: '2022-01-18' }

export const fields = [
    { key: 'invoice', label: 'Invoice', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'customername', label: 'Información' },
    { key: 'producto', label: 'Producto' },
    { key: 'paymentmethod_name', label: 'Método de pago', class: 'text-center' },
    { key: 'paymentreference', label: 'Referencia', class: 'text-center' },
    { key: `totalSale`, label: 'Total venta', sortable: true, class: 'text-center' },
    { key: 'orderstatusname', label: 'Estado', class: 'text-center' },
    { key: 'actions', label: 'Acciones' }
]

export const fieldsOperations = [
    { key: 'order', label: 'No. POS', sortable: true, sortDirection: 'desc' },
    { key: 'information', label: 'Info. cliente' },
    { key: 'categoryname', label: 'Categoría', sortable: true, },
    { key: 'itemsold', label: 'Producto' },
    // { key: 'cliente', label: 'Cliente' },
    // { key: 'paymentForm', label: 'Payment' },
    // { key: 'reference', label: 'Reference' },
    { key: 'qty', label: 'Qty', sortable: true, class: 'text-center' },
    // { key: 'unitPrice', label: 'Precio', sortable: true, class: 'text-center' },
    // { key: 'discount', label: 'Discount', sortable: true, class: 'text-center' },
    { key: 'total', label: 'Total', class: 'text-center' },
    { key: 'orderdetailstatusname', label: 'Estado', class: 'text-center' },
    { key: 'actions', label: 'Acciones', class: 'text-center' },
    { key: 'couponDoc', label: 'Cupón', class: 'text-center' },
]
export const fieldsRooms = [
    { key: 'hotelname', label: 'Hotel', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'name', label: 'Nombre', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'nightsGroup', label: 'Noches', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'mealplan.name', label: 'Plan', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'tipogrupo.tipogruponame', label: 'Tipo de grupo', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'arrivaldate', label: 'Fecha de llegada', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'departuredate', label: 'Fecha de salida', class: 'text-center', sortable: true, sortDirection: 'desc' },

    { key: 'typeCurrency.code', label: 'Moneda', class: 'text-center', sortable: true, sortDirection: 'desc' },
    { key: 'actions', label: 'Acciones' }
]

export const optionsTrip = [
    { value: 'Arrival', text: 'Arrival' },
    { value: 'Departure', text: 'Departure' },
    { value: 'Inter Hotel', text: 'Interhotel' },
    { value: 'Open Service', text: 'Open Service' },
    // { value: 'Transporte Colaborador', text: 'Transporte Colaborador' },
]

export const filtroVentasList = [
    { value: 1, text: 'Fecha de venta' },
    { value: 2, text: 'Fecha de pago' },
    { value: 3, text: 'Fecha de operación' },
]

export const filtroDataCliente = [
    { value: 0, text: 'Elija una opción' },
    { value: 1, text: 'Email' },
    { value: 2, text: 'Teléfono' },
    { value: 3, text: 'Numero POS' },
    { value: 4, text: 'Nombre Cliente Pago' },
    // { value: 5, text: 'Nombre Titular Reserva' },
    // { value: 6, text: 'Nombre Beneficiario Transfer' },
    // { value: 7, text: 'Nombre Beneficiario Servicios' },
    { value: 8, text: 'Nombre Beneficiario' },
]

export const OneWayOption = [
    { value: 'One-way', text: 'One-way' }
]

export const typeDiscounts = [
    { value: 1, text: 'Sin descuento', discount: 0 },
    { value: 2, text: 'Descuento', discount: undefined },
    { value: 3, text: 'Beneficio', discount: 100 },
    { value: 4, text: 'Cortesía', discount: 100 },
    { value: 5, text: 'Incluido en el paquete', discount: 100 },
    { value: 6, text: 'Promoción', discount: undefined },
    // { value: 7, text: 'Promo Supplier', discount: undefined },
]

export const discounttype = [
    { value: 1, text: 'Monto', code: '$' },
    { value: 2, text: 'Porcentaje', code: '%' },
]

export const codeHotels = [
    { idHotel: 1, key: 'TFB', code: 1105 },
    { idHotel: 2, key: 'TFO', code: 1100 },
    { idHotel: 3, key: 'TFD', code: null }
]

export const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
]

export const itemViewOptionsClub = [
    { icon: 'UsersIcon', value: 'club-view' },
    { icon: 'BriefcaseIcon', value: 'market-view' },
]
export const sortByOptions = [
    { text: 'Orden alfabético', value: 'alfabetico' },
    { text: 'Precio Ascendente', value: 'price-asc' },
    { text: 'Precio Descendente', value: 'price-desc' },
]
export const filterOptions = {
    priceRangeDefined: [
        { text: 'All', value: 'all' },
        { text: '<= $10', value: '<=10' },
        { text: '$10 - $100', value: '10-100' },
        { text: '$100 - $500', value: '100-500' },
        { text: '>= $500', value: '>=500' },
    ]
}

export const filters = {
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 100],
    categories: [],
    hotel: '',
    category: '',
    service: '',
    ratings: null,
    currentPage: 1,
    perPage: 12,
}

export const categoryHotels = [
    { text: 'The Fives Downtown', value: 9 },
    { text: 'The Fives Oceanfront', value: 10 },
    { text: 'The Fives Beach', value: 11 }
]

export const services = [
    { value: 1, text: 'Eventos', action: 'get-events' },
    { value: 2, text: 'Paquetes', action: 'get-packages' },
]

export const vendorOptions = [
    { value: 'CONTACTCENTER', title: 'Contact center' },
]

export const rateOptions = [
    { value: '', title: 'Todos' },
    { value: 'ASIRACK', title: 'ASI' }
]
export const languageOptions = [
    { value: 'es', title: 'Español' },
    { value: 'en', title: 'Ingles' },
]

export const adultOptions = [
    { value: 1, title: 1 },
    { value: 2, title: 2 },
    { value: 3, title: 3 },
    { value: 4, title: 4 },
    { value: 5, title: 5 },
    { value: 6, title: 6 },
    { value: 7, title: 7 },
    { value: 8, title: 8 },
]

export const childrenOptions = [
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
    { value: 6, text: 6 },
    { value: 7, text: 7 },
    { value: 8, text: 8 },
    { value: 9, text: 9 },
]
export const ageChildrenOptions = [
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
    { value: 6, text: 6 },
    { value: 7, text: 7 },
    { value: 8, text: 8 },
    { value: 9, text: 9 },
    { value: 10, text: 10 },
    { value: 11, text: 11 },
    { value: 12, text: 12 },
    { value: 13, text: 13 },
    { value: 14, text: 14 },
    { value: 15, text: 15 },
    { value: 16, text: 16 },
    { value: 17, text: 17 },
]

export const searchBookingData = {
    vendor: '',
    language: null,
    name: '',
    rangeDate: 0,
    dateIn: '',
    dateOut: '',
    nights: 0,
    hotel: '',
    room: null,
    adults: 1,
    numberChildren: 0,
    ninos: [],
    promoCode: null,
    promoCodeManual: '',
    userAuthorizeRate: { isInvalid: true, idUser: null, username: '', code: '' }
}

export const namesPrefix = [
    { value: 'Mr', text: 'Mr' },
    { value: 'Ms', text: 'Ms' },
    { value: 'Mrs', text: 'Mrs' },
    { value: 'Miss', text: 'Miss' },
]

export const cardsEmit = [
    { value: 'visa', text: 'VISA' },
    { value: 'mastercard', text: 'Master Card' },
    { value: 'amex', text: 'American Express' },
    { value: 'discover', text: 'Discover' },
    { value: 'other', text: 'Otro' },
]

export const verticalMenuItems = [{
    id: 1,
    title: 'Experiencias',
    icon: 'ShoppingCartIcon',
    // tag: '2',
    tagVariant: 'light-primary',
    children: [{
        title: 'Experiencias',
        route: 'sales'
    },
    {
        title: 'Estancias',
        route: 'booking'
    }
    ],
},
{
    id: 2,
    title: 'Mis ventas',
    route: 'my-sales',
    icon: 'BarChart2Icon',
},

{
    id: 5,
    title: 'Operación',
    route: 'operations',
    icon: 'CommandIcon',
},
{
    id: 6,
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
},
{
    id: 7,
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
},
]

export const status = [
    {
        id: 0,
        title: 'Pendiente'
    },
    {
        id: 1,
        title: 'Autorizado por'
    },
    {
        id: 2,
        title: 'Rechazado por'
    }

]

export const namesGender = [
    { id: '1', name: 'Femenino' },
    { id: '2', name: 'Masculino' }
]


export const namesPrefixId = [
    { id: '1', name: 'Mr' },
    { id: '2', name: 'Ms' },
    { id: '3', name: 'Mrs' },
    { id: '4', name: 'Miss' },
]

export const languageOptionsFives = [
    { id: '1', title: 'Español' },
    { id: '2', title: 'Ingles' },
]

export const defaultPermissions = [
    'marketplace_menu_show_marketplace', 'my_sales_menu_show_my_sales', 'faq_menu_show_faq'
    /*
    "marketplace_menu_show_marketplace","bookings_menu_show_booking", "my_sales_menu_show_my_sales", "operation_menu_show_operation", "groups_menu_show_group", "faq_menu_show_faq", "fivesclub_sub_menu_show_benefits", "fivesclub_sub_menu_show_blackouts", "fivesclub_sub_menu_show_housing", "fivesclub_sub_menu_show_customers", "fivesclub_sub_menu_show_membership", "fivesclub_sub_menu_show_contracts", "fivesclub_sub_menu_show_web_prop", "fivesclub_sub_menu_show_reports"
    */
]

export const aliasPermissions = new Map([
    ['marketplace_menu_show_marketplace', 'showMaketplace'],
    ['my_sales_menu_show_my_sales', 'showMySales'],
    ['bookings_menu_show_marketplace','showBookingsMkt'],
    ['operation_menu_show_operation','showOperations'],
    ['groups_menu_show_group','showGroups'],
    ['faq_menu_show_faq', 'showFaq'],
])

export const bookingLogsFields = [
    {key: 'huesped', label: 'Huésped'},
    {key: 'booking', label: 'Reserva'},
    {key: 'datastatus', label: 'Estatus'},
    {key: 'logs', label: 'Registros'}
]

export const guestsLogsFields = [
    {key: 'adults', label: 'Huésped'},
    {key: 'childs', label: 'Reserva'},
    {key: 'logs', label: 'Registros'}
]

export const productLogsFields = [
    {key: 'operacion', label: 'Operación'},
    {key: 'notas', label: 'Nota'},
    // {key: 'beneficiario', label: 'Beneficiario'},
    {key: 'totales', label: 'Totales'},
    {key: 'logs', label: 'Registros'}
]

export const itineraryLogsFields = [
    {key: 'beneficiary', label: 'Cliente'},
    {key: 'service', label: 'Servicio'},
    {key: 'flight', label: 'Vuelo'},
    {key: 'logs', label: 'Registros'}
]

export const canChangeEditBooking = [
    { id: '1', name: 'commetsBooking',canChageStatus:false },
    { id: '2', name: 'peopleBooking',canChageStatus:false },
    { id: '3', name: 'datesBooking',canChageStatus:true },

]

export const monthList = [
    { value: 1, text: 'Enero'},
    { value: 2, text: 'Febrero'},
    { value: 3, text: 'Marzo'},
    { value: 4, text: 'Abril'},
    { value: 5, text: 'Mayo'},
    { value: 6, text: 'Junio'},
    { value: 7, text: 'Julio'},
    { value: 8, text: 'Agosto'},
    { value: 9, text: 'Septiembre'},
    { value: 10, text: 'Octubre'},
    { value: 11, text: 'Noviembre'},
    { value: 12, text: 'Diciembre'},
]


export const valueTypeList = [
    { id: '1', name: 'Porcentaje' },
    { id: '2', name: 'Importe'},
]
export const estimateTypeList = [
    { id: '1', name: 'S/Ingreso Neto' },
    { id: '2', name: 'S/Beneficio Bruto'},
    { id: '3', name: 'S/Beneficio Neto'},
]


export const mealPlan = [
    { id: 'ASI', name: 'ASINET'},
    { id: 'EP', name: 'EPNET' },
    { id: 'BB', name: 'EP-BREAKFAST' },  
]

export const answers = [
    { id: '1', name: 'Si'},
    { id: '2', name: 'No' },
 
]