
export default [
    {
        path: '/reports',
        name: 'reports',
        component: () => import(/*webpackChunkName: "ReportsView"*/'@/modules/reports/views/Reports'),
        meta: {            
            requiresAuth: false,            
        },
    }    
]