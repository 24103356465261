// import { filter } from 'lodash'
import initialState from './state'

export function setLoadingBookings( state, bool) {
    state.isLoadingBookings = bool
}
export function setLoadingProcess( state, bool) {
    state.isLoadingProcess = bool
}

export function addTab(state, data) {    
    state.tabs.push(data)
}

export function addArrayNinios(state, data) {  
    const { key, ageChildrens } = data
    let item = state.tabs.find( item => item.key === key )
    item.dataSearch.ninos = ageChildrens  
}

export function deleteTab(state, valor) {     
    state.tabs = state.tabs.filter(item => item.key !== valor) 
}

export function setSelectedTab(state, data) {
    state.tabSelected = data
}

export function setDataSearch(state, data) {
    let item = state.tabs.find( item => item.key === data.key )
    delete data.key
    item.dataSearch = data    
}

export function showRatesRoom(state, data) {
    const { key, indice, boolean} = data
    let bookings = state.tabs.find( item => item.key === key ).bookings
    bookings[indice].showingRates = boolean    
}


export function setAgeChild(state, data) {
    const { indice, key, edad } = data
    let item = state.tabs.find( item => item.key === key )
    item.dataSearch.ninos[indice].edad = edad        
}

export function removeAgeChild(state, data) {
    const { indice, key } = data
    let item = state.tabs.find( item => item.key === key )
    item.dataSearch.ninos = item.dataSearch.ninos.splice(indice, 1) 
    item.dataSearch.numberChildren = item.dataSearch.numberChildren - 1
}

export function setListRooms( state, data) { 
    const { key, info } = data
    let item = state.tabs.find( item => item.key === key )
    item.rooms = info
}

export function setBookings(state, data) {
    const { key, payload } = data    
    let item = state.tabs.find( item => item.key === key )
    item.bookings = payload
}

export function setPayloadSearch(state, data) {
    const { key, payload } = data    
    let item = state.tabs.find( item => item.key === key )
    item.payloadSearch = payload
}

export function setCountBookings( state, status) {
    state.countDingusBookings = status
}

export function setDetailSelectedPlan(state, data) {
    const { key, indice, plan } = data    
    let item = state.tabs.find( item => item.key === key )  
    item.bookings[indice].detailSelected = plan
}

export function setManualPriceDetailSelected(state, data) {
    const { key, indice, newManualPrice, allowPriceMatch } = data    
    let item = state.tabs.find( item => item.key === key ) 
    const precioUsar = newManualPrice       
    // console.log(newManualPrice)
    item.bookings[indice].detailSelected.newManualPrice = newManualPrice    
    item.bookings[indice].detailSelected.newManualPriceInit = precioUsar    
    item.bookings[indice].detailSelected.allowPriceMatch = allowPriceMatch    
}

export function setIsLimitRateDetailSelected(state, data) {
    const { isRateLimit, indice, key } = data    
    let item = state.tabs.find( item => item.key === key )
    item.bookings[indice].detailSelected.isRateLimit = isRateLimit   
}

export function setPromotionCodeDetailSelected(state, data) {
    const { key, indice, promotionCode } = data       
    let item = state.tabs.find(item => item.key === key) 
    item.bookings[indice].detailSelected.PromotionCode = promotionCode  
}

export function setAllowPriceMatch(state, data) {
    const { key, indice, allowPriceMatch} = data       
    let item = state.tabs.find(item => item.key === key) 
    item.bookings[indice].detailSelected.allowPriceMatch = allowPriceMatch
}

export function resetToInitialState(state) {
    Object.assign(state, initialState)
}

export function resetToInitialStateTab(state, key) {
    let item = state.tabs.find( item => item.key === key ) 
    item.dataSearch = {
        vendor: '', language: null, name: '', rangeDate: 0, dateIn: '', dateOut: '', nights: 0, hotel: '', room: null, adults: 1, numberChildren: 0, ninos:[],  promoCode: null, promoCodeManual:'', userAuthorizeRate: {isInvalid: true, idUser: null, username: '', code:'' }
    }
    item.rooms = []
    item.bookings = []    
    item.payloadSearch = null
}

/**Nuevos mutaciones */

export function addNewTab(state, data) {
    const { tab, searchForm } = data
    //desactivo los tabs del estado previo a insertar el nuevo tab
    state.myTabs.forEach( tab => {
        tab.isActive = false
    })
    state.myTabs = [...state.myTabs, tab]// añado nuevo tab
    state.searchForms = [...state.searchForms, searchForm] // añado searchForm que corresponde al tab

}

export function removeTab(state, key ) {
    state.myTabs = state.myTabs.filter(item => item.keyTab !== key) // borro el tab mediante filter 
    state.searchForms = state.searchForms.filter(item => item.fromTab !== key) // borro el form del tab tambien
    state.rooms = state.rooms.filter( item => item.fromTab !== key) // borro los rooms tambien de ese tab
    //desactivo todo los tabs
    state.myTabs.forEach( tab => {
        tab.isActive = false
    })
    const idx = state.myTabs.findLastIndex( item => item.isActive === false ) //busco el ultimo tab isActive en false
    state.myTabs[idx].isActive = true //Activo ese 
}

export function activateTab(state, key) {
    //desactivo todo los tabs
    state.myTabs.forEach( tab => {
        tab.isActive = false
    })
    //activo el tab con el keyTab recibido
    const idx = state.myTabs.findIndex( item => item.keyTab === key ) //busco el index del tab
    state.myTabs[idx].isActive = true //Activo el tab 
}

export function setHotelSearch(state, data) {
    const { fromTab, idHotel, vendorcode } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //busco el searchForm
    state.searchForms[idx].idHotel = idHotel //muto con el nuevo valor
    state.searchForms[idx].vendorcode = vendorcode //muto con el nuevo valor

}

export function setRangeDateNightsSearch(state, data) {
    const { fromTab, dateIn, dateOut, nights, rangeDate } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //busco el searchForm
    state.searchForms[idx].dateIn = dateIn //muto con el nuevo valor
    state.searchForms[idx].dateOut = dateOut //muto con el nuevo valor
    state.searchForms[idx].nights = nights //muto con el nuevo valor
    state.searchForms[idx].rangeDate = rangeDate //muto con el nuevo valor 
}

export function setAdultsSearch(state, data) {
    const { fromTab, adults } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //busco el searchForm
    state.searchForms[idx].adults = adults //muto con el nuevo valor
}

export function setChildsSearch(state, data) {
    const { fromTab, numberChilds, ageChildrens} = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //busco el searchForm
    state.searchForms[idx].numberChildren = numberChilds //muto con el nuevo valor
    state.searchForms[idx].ageChildren = ageChildrens //muto con el nuevo valor
}

export function setAgeChildForm(state, data) {
    const { keyAge, fromTab, edad } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //busco el searchForm y su indice
    const idx2 = state.searchForms[idx].ageChildren.findIndex( edad => edad.key == keyAge )
    state.searchForms[idx].ageChildren[idx2].edad = edad
          
}

export function deleteAgeChildSearch(state, data) {
    const { fromTab, keyAge } = data
    let searchForm = state.searchForms.find( item => item.fromTab === fromTab )
    searchForm.ageChildren = searchForm.ageChildren.filter(age => age.key !== keyAge )
    searchForm.numberChildren = searchForm.numberChildren - 1
}

export function setPromocodeSearch(state, data) {
    const { fromTab, promoCode } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //busco el searchForm
    state.searchForms[idx].promoCode = promoCode //muto con el nuevo valor
}

export function resetTabActive(state, data) {
    const { fromTab } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //indice SearchForm
    state.searchForms[idx].adults = 1
    state.searchForms[idx].ageChildren = [] 
    state.searchForms[idx].dateIn = '' 
    state.searchForms[idx].dateOut = '' 
    state.searchForms[idx].idHotel = '' 
    state.searchForms[idx].nights = 0 
    state.searchForms[idx].numberChildren = 0 
    state.searchForms[idx].promoCode = '' 
    state.searchForms[idx].rangeDate = '' 
    state.searchForms[idx].vendorcode = ''
    delete state.searchForms[idx].tarifa 
    delete state.searchForms[idx].rateplanid 
    delete state.searchForms[idx].isManualRate 
    delete state.searchForms[idx].manualRate 
    delete state.searchForms[idx].rate 
    delete state.searchForms[idx].roomCode 
    delete state.searchForms[idx].hotel 
    delete state.searchForms[idx].children 
    delete state.searchForms[idx].ninos 
    delete state.searchForms[idx].promotionCode 
    state.rooms = state.rooms.filter(item => item.fromTab !== fromTab ) // borro los rooms con este key 
}
//me sirve para obtener payload y consultar la tarifa original de la habitación
export function replaceDataSearchFom(state, data) {
    const { fromTab } = data
    const idx = state.searchForms.findIndex( item => item.fromTab === fromTab ) //indice SearchForm
    state.searchForms[idx] = data
}

export function setRooms(state, rooms) {
    state.rooms = rooms 
}

export function setRateSelectedRoom(state, data) {
    const { detailSelected, keyRoom, divisa } = data
    const idx = state.rooms.findIndex( room => room.keyRoom === keyRoom ) //indice room
    state.rooms[idx].detailSelected = detailSelected
    state.rooms[idx].detailSelected.divisa = divisa
    state.rooms[idx].priceInit = detailSelected.AmountAfterTax
}

export function setManualPriceSelectedRate(state, data) {
    const { keyRoom, manualRate } = data   
    const idx = state.rooms.findIndex( room => room.keyRoom === keyRoom ) //indice room
    state.rooms[idx].detailSelected.manualPrice = manualRate
}

export function setDataUserAuthorization(state, data) {
    const { code, idUser, isInvalid, username } = data
    state.userAuthorizeRate.isInvalid = isInvalid
    state.userAuthorizeRate.idUser = idUser
    state.userAuthorizeRate.username = username
    state.userAuthorizeRate.code = code
}

export function unsetDataUserAuthorization(state) {
    state.userAuthorizeRate.isInvalid = true
    state.userAuthorizeRate.idUser = null
    state.userAuthorizeRate.username = ''
    state.userAuthorizeRate.code = ''
    state.userAuthorizeRate.url = ''
}

export function setUserAuthorizationUrl(state, data) {
    state.userAuthorizeRate.urlAuthorization = data
}

