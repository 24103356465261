export default {
    rooms: [],
    groups:[],
    typeGroups:[],
    roomsDetail:[],
    roomBooking:[],
    roomsTypes:[],
    bookingDetail:null,
    roomsTransactions:[],
    roomsExcel:[],
    nameUser:"",
    isloadingProcess: false,
    filterO: {
        query: '',
    },
    userAuthPage: {
        isValid: false,
        idUser: null,
        username: '',
        password: '',
    },
    errors: {
        rooms: { error: false, message: '' },
        typeGroups: { error: false, message: '' },
        roomsDetail: { error: false, message: '' },
        roomBooking : { error: false, message: '' },
        roomsTypes: { error: false, message: '' },
        bookingDetail : { error: false, message: '' },
        roomsTransactions : { error: false, message: '' }, 
        roomsExcel : { error: false, message: '' }, 

        
    },

}