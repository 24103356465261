export function setCategories(state, categories) {
    state.categories = categories
}

export function setQuestions(state, questions) {
    state.questionsAnswers = questions
}

export function setCategorySelected(state, category) {
    state.categorySelected = category    
}
export function setQueryFilter(state, string) {    
    state.query = string
}

export function setIsLoadingQuestions(state, bool) {    
    state.isLoadingQuestions = bool
}
