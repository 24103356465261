import store from '../../../store/index'

export function filteredOrders( state ) {
    let orders = state.orders
    //con esto filtro los resultado por el name del order, no es case sensitive
    if( state.filterO.query.length > 1 ){
        orders = orders.filter( order => order.name.toLowerCase().includes( state.filter.query.toLowerCase() ) )
    }
    return orders
}

export function filteredOrdersItems( state ) {    
    return state.ordersItems
}

export function orderDetailProducts( state ) {    
    return state.orderDetail.products
}

export function orderDetailTranfers( state ) {    
    state.orderDetail.transfers.forEach(detail => {
       detail.itinerario = {
            maxPax:detail.itinerary.maxPax,
            extraPax:detail.itinerary.extraPax
        }
    })
    return state.orderDetail.transfers
}

export function orderDetailBookings( state ) {  
    state.orderDetail.bookings.forEach(book => {
        if (!book?.booking?.guests?.adults)   book.booking.oldguests = structuredClone(book.booking.guests)  
     })
    return state.orderDetail.bookings
}
export function orderDetailEvents( state ) {    
    return state.orderDetail.events
}

export function orderDetailPackages( state ) {    
    return state.orderDetail.packages
}

export function orderDetailExperiences(state) {
    return state.orderDetail.experiences
}

export function bookingListUpdate( state ) {  
    const bookingToEdit = state.bookingToEdit
    if( !bookingToEdit ){
        return state.orderDetail?.bookings
    }
    if(bookingToEdit){
        return state.orderDetail?.bookings.filter(item => item.booking.id === bookingToEdit )
    }      
}
