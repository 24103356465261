export function setLoadingProcess(state, bool) {
    state.isloadingProcess = bool
}

export function setRooms(state, rooms) {
    state.rooms = rooms
}

export function setRoomBooking(state, roomBooking) {

    if(roomBooking.status!='delete'){
        const item  =  state.rooms.find( detail => detail.id === roomBooking[0].group  )
        const rooms  =  item.rooms.data.find( detail => detail.roomId === roomBooking[0].room    )
        rooms.book=[]
        rooms.book=roomBooking
        rooms.open=true
        rooms.roomsTypes=state.roomsTypes
    }else{
        const item  =  state.rooms.find( detail => detail.id == roomBooking.group  )
        const rooms  =  item.rooms.data.find( detail => detail.roomId == roomBooking.room )
        rooms.book=[]
    }
    
}
export function setRoomBookingGroup(state, roomBooking) {

        let item  =  state.rooms.find( group => group.id == roomBooking.id  )
        let rooms=item.rooms

        roomBooking.rooms .data.forEach((list) => {
            list.book = rooms.data.find( detail => detail.roomId === list.roomId).book 
           
             });

        item.rooms=roomBooking.rooms     
}     

export function setAgeChild(state, data) {
    const { indice, edad,room } = data
    room.booking.ninios[indice].edad = edad        
}
export function addArrayNinios(state, data) {  
    const {  ageChildrens,info } = data
    info.numberChildren = info.children
    info.booking.ninios = ageChildrens  
}

export function setTypeGroups(state, typeGroups) {
    state.typeGroups = typeGroups
}
export function setGroups(state, groups) {
    state.groups = groups
}

export function setUserLanding(state, data) {
    state.nameUser = data
}
export function setUserLandingTrue(state, data) {
    state.userAuthPage.isValid = data
}

export function setRoomsDetail(state, roomsDetail) {
    state.roomsDetail = roomsDetail
}

export function removeAgeChild(state, data) {
    const { info,indice } = data
    info.ninios= info.booking.ninios.splice(indice, 1)  
    info.children = info.children - 1
}

export function setTotalNights(state, data) {
 
    data.orderdetail.firstpay=   parseFloat(
        data.rateInit*data.orderdetail.nightspay
       ).toFixed(2) 
}

export function setRoomsTypes(state, roomsTypes) {
    state.roomsTypes = roomsTypes
}

export function setRoomsInfo(state, rooms) {
    state.bookingDetail= rooms
}

export function setRoomsTransactions(state, rooms) {
    state.roomsTransactions= rooms
}

export function setRoomsExcel(state, rooms) {
    state.roomsExcel = rooms
}

export function setValidTransactions(state, data) {
    const { room, book,id } = data
   
    const roomPay= room.book.find( detail => detail.id === id  )
    roomPay.listPayOrders=[]
    book.forEach((list) => {
   if (list.transactionstatus.name == "Approved") {
        state.sum += parseFloat(list.amount)
      }
      if (list.transactionstatus.name == "Pending") {
          
        roomPay.listPayOrders.push(list)
      }
    });
}

export function setRoomsError(state, errorMessage) {
    state.errors.rooms.error = true
    state.errors.rooms.message = errorMessage
}


export function setTypeGroupsError(state, errorMessage) {
    state.errors.typeGroups.error = true
    state.errors.typeGroups.message = errorMessage
}

export function setsetRoomsDetailError(state, errorMessage) {
    state.errors.roomsDetail.error = true
    state.errors.roomsDetail.message = errorMessage
}
export function setRoomBookingError(state, errorMessage) {
    state.errors.roomBooking.error = true
    state.errors.roomBooking.message = errorMessage
}
export function setsetRoomsInfoError(state, errorMessage) {
    state.errors.bookingDetail.error = true
    state.errors.bookingDetail.message = errorMessage
}

export function setRoomsTransactionsError(state, errorMessage) {
    state.errors.roomsTransactions.error = true
    state.errors.roomsTransactions.message = errorMessage
}

export function setRoomsExcelError(state, errorMessage) {
    state.errors.roomsExcel.error = true
    state.errors.roomsExcel.message = errorMessage
}



