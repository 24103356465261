export default {
    benefits: [],
    blackouts: [],
    condos:[],
    cutoffs: [],
    groupsTypesBenefits: [],
    housing:[],
    memberships: [],
    paymentTypes: [],
    retornos: [],
    seasons:[],
    subCondos:[],
    owners : [],
    benefitsProducts:[],
    getHousing:[],
    paymentMethods:[],
    typeMemberships: [],
    typesBenefits: [],
    nationalities: [],
    rentalPool:[],
    newsLetters:[],
    hotelImg:[],
    categoriesProducts:[],
    filter: {
        queryBenefits: '',
        queryCustomers: '',
        queryMemberships: '',
        queryOwnersBookins: '',
        queryPendingRsvs:'',
        queryCondominalFeesHousingByYear:'',

    },
    isMarkedAllHousing: false,
    isMarkedAllBudgetByPeriods: false,

    requestOwners: null,
    requestHousing: null,
    requestPendindAuthRsvs: null,

    categories:[],
    condominiumFees:[],
    selectedFeeHousing: null,
    selectedBenefit: null,
    selectedBlackout: null,
    selectedGroupTypeBenefit: null,
    selectedMembership: null,
    selectedTypeBenefit: null,
    selectedTypeMembership: null,
    selectedSeason:null,
    selectedCutoff:null,
    selectedOwner:null,
    selectedOwnerPassword:null,
    selectedNewsLetters:null,
    selectedHotelImg:null,
    authorization:false,
    cancellationReasons:[],
    reasonAuthorization: '',
    condominiumDataEdit: {
        mainData: null,
        selectedYear: null,
        selectedBudget: null,
    },
    listAnnualCondominiumBudgets: [], //  listado de presupuestos anuales condominales
    condominalBudgetByPeriods:[], //  listado de presupuestos anuales condominales por periodos
    condominalFeesHousingByYear:[],
    detailsFeeHousing: null,
    authorizationList:{
        authorization:false,
        blackoutValid:false,
        additional:false,
        nightsValid:false,
        cutoff:false,
        highSeason:false
    },
    benefitId: '',
    ownersBookings:[],
    ownerBookingFastFilter:null,
    pendingReservations:[],
    bookingInfo: {
        benefits: '',
        adults:1,
        dingusCode:'',
        hotel:'',
        nights: 0,
        infoAdults: [],
        ninos: [],
        numberChildren: 0,
        promoCodeManual: '',
        rangeDate:'',
        roomCode: '',
        idRsv: '',
        blackout:0,
        reservetype:1,
        occupanttype:1,
        adultsDetail:[],
        invitedAdults:[],

    },
    selectedOwnerBooking:null,
    roomsList:null,
    selectedOwnerBookingEdit: null,
    isLoadingPendingReservations: false,
    isLoadingOwnerBookings: false,
    socialNetworks: [],
    infoTransfer: [{
        id: '',
        arrivalairline: '',
        arrivalflight: '',
        arrivaldate: '',
        arrivaltime: '',
        departureairline: '',
        departureflight: '',
        departuredate: '',
        departuretime: '',
        baggage: '',
        babychair: '',
        order: '',
    }],
    infoMeal: {
        id: '',        
        mealplan: '',
        allergies: '',
        specialMeal: '',
    },
    infoPreferences: {
        id: '',        
        ownerbox: '',
        requirementsroom: '',
        additionalactivities: '',
        specialcelebrations: '',
        extrarooms: '',
    },
    infoRestaurants: [{
        id: '',        
        date: '',
        restaurant: '',
        schedule: '',
        confirmation: '',
    }],

    membershipsOptions: [],
    benefitOptions: [],
    transactionsAgreementsList: []
}