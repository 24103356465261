export function filteredProfits( state ) {
    let benefits = state.benefits        
    if( state.filter.queryBenefits.length > 1 ){
        benefits = state.benefits.filter( benefit => benefit.name.toLowerCase().includes( state.filter.query.toLowerCase() ))        
    } 
    return benefits
}

export function filteredOwners( state ) {
    let owners = state.owners  
    const query = state.filter.queryCustomers   
    
    if( query.length > 1 ){
        owners = owners.filter(
            owner => owner.name.toLowerCase().includes( query.toLowerCase() ) ||
                    owner.lastname.toLowerCase().includes( query.toLowerCase() ) ||
                    owner.phone.toString().toLowerCase().includes( query ) ||
                    owner.email.toLowerCase().includes( query.toLowerCase()
            )
        )        
    } 
    return owners
}

export function filteredMemberships( state ) {
    let memberships = state.memberships  
    const query = state.filter.queryMemberships   
    
    if( query.length > 1 ){
        memberships = memberships.filter(
                membership => membership.name.toLowerCase().includes( query.toLowerCase() ) ||
                membership.code.toLowerCase().includes( query.toLowerCase() ) ||
                membership.paymenttypename.toString().toLowerCase().includes( query.toLowerCase() ) ||
                membership.returntypename.toLowerCase().includes( query.toLowerCase()
            )
        )        
    } 
    return memberships
}

export function filteredOwnersBookings( state ) {
    let ownersBookings = state.ownersBookings  
    const query = state.filter.queryOwnersBookins   
    
    if( query.length > 1 ){
        ownersBookings = ownersBookings.filter(
                booking => booking.guestname.toLowerCase().includes(query.toLowerCase() ) ||
                booking.code.toLowerCase().includes( query.toLowerCase() ) ||
                booking.housingname.toLowerCase().includes( query.toLowerCase() ) ||
                booking.fullname.toString().toLowerCase().includes( query.toLowerCase() ) ||
                booking.internalnotes?.toLowerCase().includes( query.toLowerCase()
            )
        )        
    } 
    return ownersBookings
}

export function filteredPendingRsvs( state ) {
    let pendingRsvs = state.pendingReservations  
    const query = state.filter.queryPendingRsvs       
    if( query.length > 1 ){
        pendingRsvs = pendingRsvs.filter(
            pending => pending.guestname.toLowerCase().includes(query.toLowerCase() ) ||
            pending.code.toLowerCase().includes( query.toLowerCase() ) ||
            pending.housingname.toLowerCase().includes( query.toLowerCase() ) ||
            pending.fullname.toString().toLowerCase().includes( query.toLowerCase() )               
        )        
    } 
    return pendingRsvs
}


export function condominalHousingFeesByYear( state ) {
    let housingList = state.condominalFeesHousingByYear
    // ordeno de manera ascendente por número de vivienda
    housingList.sort((a, b) => a.housingnumber - b.housingnumber)
    
    const housingViewDetail =  housingList.find(item => item.isShowingDetails)  
    const query = state.filter.queryCondominalFeesHousingByYear       

    if( housingViewDetail ){
        return housingList.filter( item => item.isShowingDetails )  
    }

    if( query.length > 1 ){
        housingList = housingList.filter(
            housing => housing.housingnumber.toString().includes( query ) ||
            housing.owner.toLowerCase().includes( query ) ||
            housing.paymenttypename.toLowerCase().includes( query )
        )

        return housingList
    }

    return housingList
}



