import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import authRouter from '@/modules/auth/router'
import shopRouter from '@/modules/shop/router'
import faqRouter from '@/modules/faq/router'
import mySalesRouter from '@/modules/mySales/router'
import operationsRouter from '@/modules/operations/router'

import fivesClubCatalogsRouter from '@/modules/fivesClub/router'
import incidentsRouter from '@/modules/incidents/router'
import groupsRouter from '@/modules/groups/router'
import reportsRouter from '@/modules/reports/router'

import store from '@/store' //para acceder a state para isLogged
import { validUserLogin } from '../helpers/helpers'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'sales' } },                        
    ...authRouter,
    ...shopRouter,
    ...groupsRouter,
    ...faqRouter,
    ...mySalesRouter,
    ...fivesClubCatalogsRouter,
    ...incidentsRouter,
    ...operationsRouter,
    ...reportsRouter,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next)=> {
  const thereArePermissionsToCheck = to.meta.permissions && to.meta.permissions.length > 0
  const requiresAuth = to.matched.some( record => record.meta.requiresAuth)
  const userValidLogin = validUserLogin() // usuario conectado, con canal de venta y un punto de venta
  const userPermissions = store.state.auth.permissions  
  if( !requiresAuth && userValidLogin && to.path === '/login'){ //Sí ruta no requiere autenticación, con usuario válido y está en ruta login
    next({name: 'sales'})
  } 
  if(requiresAuth && userValidLogin){
    //valido los permisos
    if( thereArePermissionsToCheck ){
      const isAllowed = userPermissions.some(p => to.meta.permissions.includes(p))
      if(isAllowed){
        next()
      } else next({name: 'error-403'}) 
    } else next()  
  }

  if( requiresAuth && !userValidLogin ) {
    next({name: 'auth-login'})
  } else next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
