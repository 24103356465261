import { toJson, toDecimal, stringAleatorio, camposRequeridosPorLLenar, showAlertMessage } from './helpers'
import { typeDiscounts } from "@/data/data";
import store from '../store/index'

const processItemsCart = ( items, clientData ) => {
    const detail = []
    items.forEach((prod) => {
        const modelType = setTypeModel( prod.id, prod.modelType, prod.detailId )
        const typesRates = { 'Adults': 1, 'Children': 2, 'Pax': 3 }
        const discount = typeDiscounts.find(disc => disc.value === prod.typeDiscount)
        const payload = {
            extrapax_amount: prod.extraAmount,
            unitPrice: parseFloat(prod.unitPrice).toFixed(2),
            qty: prod.typeRate !== 'Pax' ? prod.qty :  1,
            discount: parseFloat(prod.discount || 0.00).toFixed(2),
            discounttype: discount.text || 'noDiscount',
            total: parseFloat(prod.total || 0.00).toFixed(2),
            firstpay: parseFloat(prod.firstPay || 0.00).toFixed(2),

            note: prod.note.substring(0, 254),
            clientnote: prod.clientnote.substring(0, 254),
            orderextraFields: prod.orderextraFields ? toJson(prod.orderextraFields) : [],
            modelType,
            product: modelType.product,
            package: modelType.package,
            event: modelType.event,
            experience:modelType.experience,
            isBenefit: prod.isBenefit ,
            experiencecost: prod.modelType ===  'rates' ? prod.cost : 0,
            experienceType : prod.modelType === 'rates' ? typesRates[ prod.typeRate ] : 1, //ipo int,
            isBenefitDescout: prod.isBenefitDescout ,
            needAuthorization:prod.needAuthorization ,
            reasonAuthorization: prod.reasonAuthorization,
            blackout: prod.blackout ? prod.blackout : 0,
            nightsUpgrade: prod.nightsUpgrade ? prod.nightsUpgrade : prod.nights,
            ownerBooking: prod.ownerBooking ? prod.ownerBooking : 0 ,
            benefitProduct: prod.benefitProduct ,
            contract: prod.contract,
            beneficiarios: toJson(setBeneficiary(prod, clientData)),
            booking: modelType.booking,
            productDetail: modelType.productDetail,
            packageDetail: modelType.packageDetail,
            eventDetail: modelType.eventDetail,
            experienceContract: modelType.experienceContract,
            detailitinerary: setItinerary(prod, clientData),
            orderUpgrade: setUpgrades(prod),
            orderdetailbeo: toJson(prod.orderdetailbeo),
            operationtime: prod.operationtime, //operationTime
            serviciosihot: prod.serviciosihot,
            bookingReference: prod.bookingReference,
            partial: prod.partial,
            supplier: prod?.supplier?.id || null,
            extrapax: prod?.itinerario?.extraPax || 0,
            promoSuplierLen: prod?.supplierPromo?.length || 0,
            promoSuplierId: prod?.promoId || null
        }

        if (prod.typeDiscount != 6) payload.promoSuplierLen = 0 // != "Promoción"

        //si fecha operacion viene nulo no se pone en el detalle
        let objetoDetalle = createObjectPayload(prod, payload)
        if (prod.categoryName == 'Reservas' || prod.categoryName == 'ReservasOwners') createObjectBooking(objetoDetalle, prod)
        //push para el detalle
        detail.push(objetoDetalle)
    })
    
    return detail
}

const setTypeModel = (id, type, detailId) => {
    return {
        product: type == "product" ? id : null,
        package: type == "package" ? id : null,
        event: type == "event" ? id : null,
        experience: type == "rates" ? id : null,

        booking: null,

        productDetail: type == "product" ? detailId : null,
        packageDetail: type == "package" ? detailId : null,
        eventDetail: type == "event" ? detailId : null,
        experienceContract: type == "rates" ? detailId : null
    }
}

const setItinerary = (item, clientData) => {
    if ( item.itinerario ) {
        let hourPickup = ''
        let flightTime = ''
        if( item.itinerario.pickup ){
            const {HH, mm} = item.itinerario?.pickup
            if (typeof item.itinerario?.pickup == 'object') hourPickup = ( HH != '' && mm != '') ? HH + ':' + mm : ''
            else if (typeof item.itinerario?.pickup == 'string') hourPickup = item.itinerario?.pickup
        }
        if( item.itinerario.flighttime ){
            const {HH, mm} = item.itinerario?.flighttime
            if (typeof item.itinerario?.flighttime == 'object') flightTime = ( HH != '' && mm != '') ? HH + ':' + mm : ''
            else if (typeof item.itinerario?.flighttime == 'string') flightTime = item.itinerario?.flighttime
        }
        const detailitinerary = {
            flight: item.itinerario.flight || '',
            flighttime: flightTime,
            pickup: hourPickup,
            ifrom: item.itinerario.ifrom || '',
            ito: item.itinerario.ito || '',
            pax: item.itinerario.pax || '',
            benefitedName: item.itinerario.nameBenefi || clientData.name,
            benefitedLastName: item.itinerario.lastNameBenefi || clientData.lastname,
            airline: item.itinerario.airline?.name || '',
            trip: item.itinerario.trip || '',
            typeflight: item.itinerario.typeflight || ''
        }
        return detailitinerary
    } else return null
}

const setUpgrades = (item) => {
    if ( item.upgradesDetail ) {
        let type=0
        if(item.upgradesDetail.mealPlanNameOriginName==null){
        type=1
        }else{
        type=2
        }
        const detaupgrades = {
  
            origincategory : item.upgradesDetail.mealPlanNameOriginName  || item.upgradesDetail.roomsTypesOriginName, 
            destinationcategory : item.upgradesDetail.mealPlanNameDestinationName || item.upgradesDetail.roomsTypesDestinationName  ,
            idhotel : item.upgradesDetail.idHotel ,
            datestart: item.upgradesDetail.dateIn,
            dateend: item.upgradesDetail.dateOut,
            typeUpgrade:type
        }
        return detaupgrades
        
    } else return null
}
const setBeneficiary = (item, clientData) => {
    if (item.categoryName == 'Transfer' || item.categoryName == 'Reservas'  || item.categoryName == 'ReservasOwners') return null

    else return {
        fullname: item.beneficiarios?.fullname || `${clientData.name} ${clientData.lastname}`,
        phone: item.beneficiarios?.phone || clientData.phone,
        email: isEmailValid(item.beneficiarios?.email) ? item.beneficiarios?.email : clientData.email,
        room: item.beneficiarios?.room || '',
    }
}

const isEmailValid = (correo) => {
    if (correo.includes('@')) return true
    else return false
}

const createObjectPayload = (item, payload) => {

    if (item.operationdate) payload.operationdate = item.operationdate

    if (payload.detailitinerary) {
        const itinerarioValido = isValidItinerary(payload.detailitinerary)
        if (!itinerarioValido) delete payload.detailitinerary
    }

    const beoValido = isValidOrderDetailBEO(payload.orderdetailbeo)
    if (!beoValido) delete payload.orderdetailbeo

    const extraFieldsValido = isValidaOrderExtraFields(payload.orderextraFields)
    if (!extraFieldsValido) delete payload.orderextraFields

    return payload
}

const createObjectBooking = (objetoDetalle, item) => {
    delete objetoDetalle.detailitinerary
    delete objetoDetalle.operationtime
    //Datos para el order booking
    objetoDetalle.orderbooking = {
        adults: item.adultos,
        authorizationUser: item.idUserAuthorizeRate || null ,
        children: item.ninosTotal,
        dateAuthorization: item.dateAuthorization || null,
        datein: item.dateIn,
        dateout: item.dateOut,
        email: item.Email,
        firtpay: item.firstPay,
        isBenefit : false,
        isBenefitDescout: false,
        needAuthorization:item.needAuthorization,
        reasonAuthorization:item.reasonAuthorization,
        ownerBooking:null,
        isAuthorization:false,
        blackout:0,
        givenname: item.GivenName,
        multibeneficiaries: setGuestsInPayload(item.guests),
        hotel: item.idHotel,
        inclusions: stringInclusionBooking(toJson(item.inclusions)),
        includes:toJson(item.inclusioneslist),
        inforates: formatInfoRates(item.infoRates),
        isAuthorization: item.idUserAuthorizeRate ? true : false,
        isRateLimit: item.isRateLimit || false,
        language: item.idioma,
        mealplancode: item.MealPlanCodes,
        multimedias: toJson(item.multimedias) || null,
        nameprefix: item.NamePrefix,
        nights: item.nights,
        nightsUpgrade: item.nightsUpgrade,
        ninos: toJson(item.ninos),
        partial: item.partial,
        phonenumber: item.PhoneNumber,
        promotioncode: item.promotionCode,
        rateInit: item.rateInit,
        rateManual: item.rateManual ? item.rateManual : 0,
        rateplancode: item.rateplancode,
        rateplanid: item.rateplanid,
        rateplanname: item.rateplanname,
        roomtypecode: item.roomCode,
        roomtypename: item.roomtypename,
        surname: item.Surname,
        termscondition: item.term ? item.term.id : null,
        total: item.saleprice,
        urlauthfiles: seturlauth(item.urlAuthorization),
        urlAuthorization: '',
        urlimage: item.img,
        vendorcode: item.vendorcode,
    }
    return objetoDetalle
}

const seturlauth = (code) => {
    let authcodes = []
    if (code?.length > 0){
        code.forEach((auth)=>{
            const { filename, filetype, url} = auth
            const item = {
                urlfile: url,
                filename: filename,
                filetype: filetype,
            }
            authcodes.push(item)
        })
        return toJson(authcodes)
    } else return null
}

const setGuestsInPayload = ( guests ) => {
    if (guests) {
        let multiguests = []
        guests?.adults?.forEach(guest => {
            multiguests.push({
                id: guest.id || null,
                ismain: guest.mainGuest || false,
                name: guest.GivenName || '',
                lastname: guest.Surname || '',
                email: guest.Email || '',
                phone: guest.PhoneNumber || '',
                adult: true,
                edad: guest.Age || null
            })
        });
        if (guests?.childs?.length > 0) guests?.childs?.forEach(guest => {
            multiguests.push({
                id: guest.id || null,
                ismain: false,
                name: guest.GivenName || '',
                lastname: guest.Surname || '',
                email: guest.Email || '',
                phone: guest.PhoneNumber || '',
                adult: false,
                edad: guest.Age || null
            })
        });
        return toJson(multiguests)
    } else return null
}

const formatInfoRates = ( rates ) => {
    const list = []
    rates.forEach((rate) => {
        const { DateIn, DateOut, Rate  } = rate
        const item = { checkin: DateIn, checkout: DateOut, rate: Rate }
        list.push(item)
    })
    return toJson(list)
}

const stringInclusionBooking = (inclusions) => {
    let stringInclusion = ``
    let extras=''
    for (let i = 0; i < inclusions.length; i++) {
        const saltoLinea = i == (inclusions.length - 1) ? '' : '\n'
         if (inclusions[i].name== "Inclusion extra"){
            extras += inclusions[i].detaildisplay+','
         }else{
            stringInclusion += `Inclusiones: ${inclusions[i].name}\nDetail: ${inclusions[i].detaildisplay}${saltoLinea}`
         }

    }
    stringInclusion += `\nInclusiones: ${'Inclusion extra'}\nDetail: ${extras}`
    return stringInclusion
}


const isValidItinerary = (itinerario) => {
    const { ifrom, ito, pax, trip } = itinerario
    if (ifrom == "" || ito == "" || pax == "" || trip == "") return false
    else return true
}

const isValidOrderDetailBEO = ( dataBEO ) => {
    const { backup, comentario, locacion, titulo } = dataBEO
    if (backup == null && comentario == "" && locacion == null && titulo == "") return false
    else if (backup == null || locacion == null || comentario != "" || titulo != "") return true
}


const isValidaOrderExtraFields = (extraFields) => {
    if (extraFields.length == 0) return false
    else return true
}

const createPayloadToSave = (client, state, getters, detail, clientDataBackend ) => {
    const validAuthorization=detail.find( d => d.needAuthorization === true )
    //datos para el cliente tomado desde el formulario + datos del state en el modulo shop
    const { paymentmethod, paymentamount, paymentamount2, paymentreference, paymentmethod2, paymentreference2, paymentList, paymentlink, idUser, hotel, customertype, customeragent, applyManualRoomCharge, hotelsihotcode, language } = client
    //datos del cliente retornados desde el backend
    const { id } = clientDataBackend
    const accountid=  state.cart.cliente?.accountid || ''
    //de los getters from module shop
    const discount = (Math.round((state.cart.totalDiscount ) * 100) / 100).toFixed(2)
    const grossSale = toDecimal( parseFloat(discount) + parseFloat( getters.totalBreakdown ) )

    const grossSaleOld = toDecimal( parseFloat( getters.totalDiscount ) + parseFloat( getters.totalBreakdown ) )
    const discountOld = getters.totalDiscount

    const totalSale = getters.totalBreakdown
    //del estado shop
    const currency = state.cart.cliente.currency.currencyid
    const numreserva = state.cart.numreserva
    const canalventa = state.canalventa // canal de venta
    const puntoventa = state.puntoventa // punto de venta
    const room = state.cart.room // habitacion
    const partial = state.cart.partial
    const firstpay = toDecimal(state.cart.firstPay)
    const idWelcom = state.quoted.idWelcom
    const idFired = state.quoted.idFired
    const idAmenities = state.quoted.idAmenities //aplica en booking
    const termsId = state.quoted.termsId
    const dataListcontent = state.quoted.dataListcontent
    const dataListcontentFired = state.quoted.dataListcontentFired
    const dataListcontentTerms = state.quoted.dataListcontentTerms
    const isQuotes = state.quoted.isQuotes // true si es cotizaciion
    const needAuthorization = validAuthorization ? true : false

    const isPackage = state.cart.isPackage // true si es cotizaciion
    const isBenefitCredit = state.cart.cliente.isBenefitCredit // true si es descuento de beneficio
    const contractId = state.cart.contractId // true si es descuento de beneficio
    const benefitId = state.cart.benefitId
    const files = state.cart.files ? state.cart.files : null
    const isEpayment  = state.cart.epayment
    const orderpayment=[]
    // del usuario loggeado
    const seccioncanal = store.state.auth.user.sectionCanal.id

    const idconfigurationRoom = state.cart.ownerbookingbenefits.idconfigurationRoom
    const nightsContract = state.cart.ownerbookingbenefits.nightsContract
    const nightsXtra = state.cart.ownerbookingbenefits.nightsXtra
    return {

        grossSale, paymentreference, paymentreference2, paymentmethod2, paymentamount, paymentamount2, paymentList, tax: "0.00", discount, totalSale, customer: id, paymentmethod, currency,  paymentlink, saleUser: idUser, hotel, orderStatus: 1, detail, customertype, customeragent, cargomanual: applyManualRoomCharge, numreserva, hotelsihotcode, canalventa, puntoventa, seccioncanal, room, language, partial, firstpay, idWelcom, idFired, idAmenities, termsId, dataListcontent, dataListcontentFired, dataListcontentTerms, isQuotes, isPackage,isBenefitCredit,contractId,benefitId,orderpayment,needAuthorization, files, isEpayment, idconfigurationRoom, nightsContract, nightsXtra, grossSaleOld, discountOld,accountid
    }
}


const formatBookings = ( rooms, isPromo, fromTab) => {
    rooms.forEach( room => {
        room.detailSelected = room.rates.length ? room.rates[0] : []
        room.priceInit = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'
        room.priceInitDefault = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'
        room.fromTab =  fromTab
        room.detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }
        room.keyRoom = stringAleatorio()
        room.showingRates = true
        room.rates.forEach( ( rate ) => {
            const esPromo = isPromo ? isPromo : false
            rate.allowPriceMatch = true
            rate.divisa = { currencyid: 1, code: "USD", value: "1.00" }
            rate.isPromo = esPromo
            rate.manualPrice = ''
            rate.priceInit = rate.AmountAfterTax
            rate.priceMatchTax = ''

        })
    })
    return rooms
}

const formatRoomPromoCode = ( room, datosRequest, message, isPromo ) => {
    const { priceInit, keyRoom, allowPriceMatch, manualRate } = datosRequest

    room.detailSelected = room.rates.length ? room.rates[0] : []
    room.detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }
    room.priceInit =  priceInit
    room.keyRoom = keyRoom || ''
    room.showingRates = true
    //agrego cosas al listado de rates
    room.rates.forEach( ( rate ) => {
        const priceMatchTax = !message ? rate.AmountAfterTax : "0.00"
        const esPromo = isPromo ? isPromo : false
        rate.divisa = { currencyid: 1, code: "USD", value: "1.00" }
        rate.priceMatchTax = priceMatchTax
        rate.priceInit = priceInit || ''
        rate.manualPrice = manualRate
        rate.allowPriceMatch = allowPriceMatch
        rate.isPromo = esPromo
    })
    return room
}

const makePayloadRoomRate = ( data ) => {
    const { previousRooms, newRoom, keyRoom } = data
    const room = previousRooms.find( room => room.keyRoom === keyRoom )
    room.rates = newRoom.rates
    room.priceInit = newRoom.priceInit
    room.detailSelected = newRoom.detailSelected
    return toJson(previousRooms)
}

const horaVuelo = ( payload ) => {
    const { typeService, timeflight, timepickup} = payload
    const sametime = timepickup

    if (timeflight.length > 0) {
        sametime = timeflight
    }

    return sametime
}

const itemsCartNotReady = ( products ) => {
    let items  = []
    products.forEach( prod => {
        const { detaildisplay } = prod
        //campos requeridos llenados
        if(!prod.requiredFieldsFilled){
            const item = {key: stringAleatorio(), name: detaildisplay,  fields : camposRequeridosPorLLenar(prod)}
            items.push( item )
        }
    })
    return items

}

const validateAvailabilityItem = ( date ) => {
    const datesNotAvailable = store.state.start.unavailabledates
    const result = datesNotAvailable.find( d => d.date === date )

    const isAvailable = result ? false: true
    if(!isAvailable ){
        showAlertMessage(`Fecha no disponible`, 'InfoIcon', `😰😰 Para esta fecha, este producto no está disponible, intente con otra fecha`,'danger', 3000, 'bottom-right')
    }
    return isAvailable
}

const cardTypeFinder = (number) => {
    let re = new RegExp('^4')
    if (number.match(re) != null) return {id: 1, name: 'visa'}

    re = new RegExp('^(34|37)')
    if (number.match(re) != null) return {id: 2, name: 'amex'}

    re = new RegExp('^5[1-5]')
    if (number.match(re) != null) return {id: 3, name: 'mastercard'}

    re = new RegExp('^6011')
    if (number.match(re) != null) return {id: 4, name: 'discover'}

    re = new RegExp('^62')
    if (number.match(re) != null) return {id: 5, name: 'unionpay'}

    re = new RegExp('^9792')
    if (number.match(re) != null) return {id: 6, name: 'troy'}

    re = new RegExp('^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}')
    if (number.match(re) != null) return 'dinersclub'

    re = new RegExp('^35(2[89]|[3-8])')
    if (number.match(re) != null) return {id: 7, name: 'jcb'}

    return {id: 0, name: ''} // default type
}

const validCard = (card, cardMonth, minCardMonth) => {
    // var sum = 0
    // for (var i = 0; i < card.length; i++) {
    //   var intVal = parseInt(card.substr(i, 1))
    //   if (i % 2 === 0) {
    //     intVal *= 2
    //     if (intVal > 9) intVal = 1 + (intVal % 10)
    //   }
    //   sum += intVal
    // }
    // if (sum % 10 !== 0) return showAlertMessage('Error', 'InfoIcon', `Invalid Card Number`, 'danger', 4000, 'bottom-right')
    // else if (cardMonth < minCardMonth) return showAlertMessage('Error', 'InfoIcon', `Invalid Card Date`, 'danger', 4000, 'bottom-right')
    // else return true
    return true
}
const findBenefit = ( cartItems ) => {
    let items  = []
    cartItems.forEach( prod => {
        const { detailId,folio } = prod
        //campos requeridos llenados

            const item = {detailId,folio}
            items.push( item )

    })
    return items

}

const formatGuestsInBooking = (item) => {
    let guests = []
    const adults = item.adults
    const childs = item.children
    const pax = adults + childs
    let i = 1
    let number = 1
    let e = 0

    do {
        e++
        guests.push({
            idForm: i++,
            id: null,
            NamePrefix: '',
            GivenName: '',
            Surname: '',
            PhoneNumber: '',
            Email: '',
            Address: '',
            guestType: `guest ${number++}`,
            Adult: true
        })
    } while (e < pax)

    // Main guest
    guests[0].guestType = 'Main Guest'
    guests[0].mainGuest = true


    if (childs > 0){
        let index = adults
        number = 1
        const childrens = item.ageChildren
        childrens.forEach(child => {
            guests[index].Adult = false
            guests[index].guestType = `child ${number++}`
            guests[index].Age = child?.edad || child
            delete guests[index].PhoneNumber
            delete guests[index].Email
            delete guests[index].Address
            index++
        });
    }
    item.guests = {
        adults: guests.filter(adults => adults.Adult == true),
        childs: guests.filter(childs => childs.Adult == false)
    }

    return item
}

const formatProductsWebsites = (data) => {
    const {payload, endPoint} = data

    if (endPoint == 'productswebsites') {
        if (payload[0]?.productinfo[0]?.terms) {
            payload[0].modelType = 'product'
            payload[0].terms = payload[0]?.productinfo[0]?.terms
            payload[0].descriptionTerms = payload[0]?.productinfo[0]?.description

            if (payload[0]?.gallery) {
                payload[0]?.gallery.forEach((item) => {
                    item.key = Math.random().toString(36).substring(2, 9)
                })
            }
            if (payload[0]?.products) {
                payload[0].products.forEach((item) => {
                    item.keyItem = Math.random().toString(36).substring(2, 9)
                })
            }
            payload[0].detailSelected = {
                products: payload[0]?.products,
                gallery: payload[0]?.gallery,
                cost: payload[0]?.cost || 0,
                descmax: 0,
                detailDesc: payload[0]?.name,
                detaildisplay: payload[0]?.name,
                extrapax: false,
                id: payload[0]?.id,
                saleprice: payload[0]?.saleprice,
                servicesihot: payload[0]?.servicesihot || null
            }

            if (payload[0]?.detail[0]) {
                delete payload[0].detail[0].currency
                payload[0].detailSelected = payload[0]?.detail[0]
            }
            if (payload[0]?.category) {
                payload[0].categoryName = payload[0]?.category?.name
            }
        } else return null
    }

    if (endPoint == 'experienceswebsites') {
        payload.categoryName = 'experienceswebsites'
        payload.coverImage = payload.coverimage
        payload.name = payload.name
        payload.descriptionTerms = payload.description
        payload.terms = payload.termsandconditions
        payload.categoryName = payload.category
    }

    if (endPoint == 'eventswebsites') {
        delete payload[0].currency
        payload[0].categoryName = 'Events'
        payload[0].modelType = 'event'
        payload[0].hotelName = payload[0]?.hotel?.name
        payload[0].locationName = payload[0]?.hotel?.name
        payload[0].description = payload[0]?.name
        const timeInitFormat = payload[0]?.startTime?.slice(0, -3)
        const timeEndFormat = payload[0]?.endTime?.slice(0, -3)
        const ampmInit = (timeInitFormat?.slice(0, -3) >= 12) ? "PM" : "AM"
        const ampmEnd = (timeEndFormat?.slice(0, -3) >= 12) ? "PM" : "AM"
        payload[0].startDateFormat = formatDate(payload[0]?.startDate, 'en')
        payload[0].endDateFormat = formatDate(payload[0]?.endDate, 'en')
        payload[0].startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
        payload[0].endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
        payload[0].detailSelected = {
            cost: payload[0]?.cost || 0,
            descmax: 0,
            detailDesc: payload[0]?.name,
            detaildisplay: payload[0]?.name,
            extrapax: false,
            id: payload[0]?.id,
            saleprice: payload[0]?.saleprice,
            servicesihot: payload[0]?.servicesihot || null
        }
        if (payload[0]?.eventdetail[0]?.terms) {
            payload[0].terms = payload[0]?.eventdetail[0]?.terms
            payload[0].descriptionTerms = payload[0]?.eventdetail[0]?.description
        }
        delete payload[0].hotel
        delete payload[0].endDate
        delete payload[0].endTime
        delete payload[0].startDate
        delete payload[0].startTime
        delete payload[0].location
    }

    if (endPoint == 'termsWebsites') {
        payload.categoryName = 'Booking'
        payload.modelType = 'Booking'
        payload.coverImage = payload?.urlimage
        payload.name = payload?.roomtypename
        payload.descriptionTerms = '<h4>' + payload?.title + '</h4>'
        payload.terms = payload?.termscondition
        delete payload.mealplancode
        delete payload.roomtypename
        delete payload.urlimage
    }

    return payload.length ? payload[0] : payload
}

const makeTotalAndDiscountPayload = () => {
    const cart = store.state.shop.cart
    const products = []

    cart.items.forEach(item => {
        // if (item.modelType != 'rates' || (item.modelType == 'rates' && item.show))
        if( item.show == false || item.typeRate === 'Pax' ){
            const isBooking = (item.categoryName == 'Reservas' || item.categoryName == 'ReservasOwners')
            const details = {
                uuid: item.uuid,
                idProduct: item.id,
                amount: parseFloat(item.originalSalePrice || 0),
                discount: parseInt(item.discountApplied) || 0,
                maxdiscount: item.typeDiscount == 1 ? parseInt(item.descmax) : 0,
                typediscount: item.typeDiscount || null,
                qty: item.typeRate === 'Pax' ? 1 : parseInt(item.qty),
                promoName: item.promoName || '',
                promoId: item.promoId || '',
                extraValues: [],
                isBooking,
            }
            if (!!item.itinerario?.extraPax) details.extraValues.push({
                qtyExtra: parseInt(item.itinerario?.extraPax || 0),
                amountExtra: parseFloat(item.itinerario?.paxCost || 0)
            })
            if (isBooking) {
                details.bookingValues = {
                    isPartial: Boolean(item.partial),
                    totalnights: parseInt(item.nights),
                    nightstopay: parseInt(item.nightsNum)
                }
            }

            products.push(details)
        }
    })
    return products
}

export {
    processItemsCart,
    setTypeModel,
    setItinerary,
    createObjectPayload,
    createObjectBooking,
    isValidItinerary,
    isValidOrderDetailBEO,
    isValidaOrderExtraFields,
    createPayloadToSave,
    formatBookings,
    formatRoomPromoCode,
    makePayloadRoomRate,
    horaVuelo,
    itemsCartNotReady,
    validateAvailabilityItem,
    setGuestsInPayload,
    cardTypeFinder,
    validCard,
    seturlauth,
    findBenefit,
    formatGuestsInBooking,
    formatProductsWebsites,
    makeTotalAndDiscountPayload
}