require('./feather')

require('./boostrap-vue')
require('./vue-ant-design')
require('./vue-axios')
require('./portal-vue')
require('./clipboard')
require('./toastification')
require('./sweet-alerts')
require('./vue-select')
require('./vee-validate')
require('./tour')
require('./vue-json-excel')
require('./vue-form-wizard')