
export default [
    {
        path: '/my-sales',
        name: 'my-sales',
        component: () => import('@/modules/mySales/views/MySales'),                
        meta: {
            requiresAuth: true,
            // permissions: ['my_sales_menu_show_my_sales']

        },
    },   
    {
        path: '/my-sales/:order',
        name: 'order-details',
        component: () => import('@/modules/mySales/views/OrderDetail'),                        
        meta: {
            requiresAuth: true,            
            // permissions: ['my_sales_menu_show_my_sales']
        },
        props: ( route ) => {
            return {
                order: route.params.order
            }
        },
    },    
]

