import {showAlertMessage} from '../../../../helpers/helpers'
import Vue from 'vue'
import * as moment from 'moment'

export async function fetchTransactions(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getTransactions/`,
            params: {
                ...payload,
                reports: true
            }
        })
        const respuesta = response && response.data
        const {status, data, message } = respuesta

        if( !status ) showAlertMessage('', 'BellIcon', `${message}`, 'warning', 4000, 'bottom-right')

        return status ? data : []
    } catch (error) {
        console.warn(error)
        return []
    } finally {
        console.log('reports/fetchTransactions end')
    }
}

export async function fetchTransactionsDetail(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getTransactionsDetail/`,
            params: {
                ...payload,
                reports: true
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []

    } catch (error) {
        console.warn(error)
        showAlertMessage('', 'BellIcon', `Hubo un error al solicitar los datos, por favor intente usar un rango de fecha más corto`, 'warning', 4000, 'bottom-right')
        return []
    } finally {
        console.log('reports/fetchTransactionsDetail end')
    }
}

export async function fetchExcelDetailTransactions(context, payload) {

    try {
        const response = await Vue.axios({
            url: `/fivesClub/getTransactionsDetail/`,
            responseType:'blob',
            params: { ...payload, reports: true}
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        try {
            fileLink.setAttribute("download", 'transactions-details-'+moment().format("YYYY-MM-DD_HH.mm")+'.xlsx')
        } catch {
            fileLink.setAttribute("download", 'detail-transactions.xlsx')
        }
        document.body.appendChild(fileLink)
        fileLink.click()

    } catch (error) {
        console.log(error)
    } finally {
        console.log('reports/fetchExcelDetailTransactions end')
    }
}